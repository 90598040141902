<!--
 * @Description: 单选/多选  ||  矩阵量表/矩阵单选
 * @version: 
 * @Author: PSG
 * @Date: 2021-04-21 14:46:36
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-05 14:52:36
-->
<template>
  <div class="multiple-choice">
    <div class="title">
      <span class="sort">{{index + 1}}.&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <a-select 
        label-in-value 
        style="width: 80%;border-bottom: 1px solid #d7d7d7" 
        :bordered="false"
        @change="handleSelectChange"
        v-model:value="question.questionId"
      >
        <!-- <a-select-option :value="-1" >请选择题目</a-select-option> -->
        <a-select-option 
          v-for="(item, index) in enableChooseQuestionList"
          :key="index"
          :value="item.questionId"
          :question="item"
        >
          <div class="item" style="display: flex">
            <RichTitle class="rich-title" :title="item.title" />
            <span class="title-tips">({{questionTypeList[item.questionType]}})</span>
          </div>
        </a-select-option>
        <!-- <a-select-option :value="2">1、标题题目（单选）</a-select-option>
        <a-select-option :value="3">5、标题题目（多选）</a-select-option>
        <a-select-option :value="4">7、标题题目（矩阵量表）</a-select-option>
        <a-select-option :value="5">8、标题题目（矩阵单选）</a-select-option> -->
      </a-select>
      <span class="iconfont close" @click="handleDeleteClick(index)">&#xe61c;</span>
    </div>
    <div class="row" v-show="isShowRowSelect">
      <span>行标题：</span>
      <a-select 
        label-in-value 
        style="width: 200px;border-bottom: 1px solid #d7d7d7;font-weight: bold" 
        :bordered="false"
        v-model:value="question.rowChosenId"
      >
        <a-select-option :value="-1" >请选择行标题</a-select-option>
        <a-select-option
          v-for="choice in question.rowItems"
          :key="choice.choiceId"
          :value="choice.choiceId"
        >
          {{choice.text}}
        </a-select-option>
        <!-- <a-select-option :value="2">苹果</a-select-option>
        <a-select-option :value="3">华为</a-select-option>
        <a-select-option :value="4">腾讯</a-select-option>
        <a-select-option :value="5">小米</a-select-option> -->
      </a-select>
    </div>
    <div class="options">
      <a-checkbox-group 
        v-model:value="question.chosenIds"
      >
        <a-checkbox 
          v-for="choice in question.choices"
          :key="choice.choiceId"
          :value="choice.choiceId"
        >
          {{choice.text}}
        </a-checkbox>
        <!-- <a-checkbox :value="1">选项1</a-checkbox>
        <a-checkbox :value="2">选项2</a-checkbox>
        <a-checkbox :value="3">选项e</a-checkbox> -->
      </a-checkbox-group>
    </div>
    <div class="desc">
      <span>符合</span>
     <a-select 
        label-in-value 
        class="desc-select"
        :bordered="false"
        v-model:value="question.condition"
        :disabled="isDisableCondSelect"
      >
        <a-select-option :value="1" >全部</a-select-option>
        <a-select-option :value="2">任一</a-select-option>
      </a-select>
      <!-- <span class="desc-single">任一</span> -->
      <span>条件（题目）时，显示当前问题。</span>
      
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import RichTitle from '../../../question/components/richTitle'

/**
 * 控制 行标题下拉框 是否出现
 */
const controlRowSelectEffect = (questionType) => {
  const isShowRowSelect = ref(false)
  if (/^(MATRIX_SCALE|MATRIX_SINGLE_CHOICE)$/.test(questionType)) {
    isShowRowSelect.value = true
  }
  return {
    isShowRowSelect,
  }
}

/**
 * 控制 条件（全部/任一） 是否可编辑
 */
const controlCondSelectEffect = (questionType) => {
  const isDisableCondSelect = ref(false)
  // 单选、矩阵量表、矩阵单选 的条件的可选范围只有”任一“
  if (/^(SINGLE_CHOICE|MATRIX_SCALE|MATRIX_SINGLE_CHOICE)$/.test(questionType)) {
    isDisableCondSelect.value = true
  }
  return {
    isDisableCondSelect,
  }
}

/**
 * 题目下拉 相关逻辑
 */
const selectEffect = (props, emit, question) => {
  // 获取可供选择的条件题目
  // （根据本题之前的可选条件题目（beforeQuestionList）与已选的条件题目（alreadySelectQuestSortList/alreadySelectQuestionLIst），分析出可供选择的条件题目）
  const enableChooseQuestionList = computed({
    get: () => props.beforeQuestList.filter(item => { 
      let curSort = question.sort.key
      let alreadySelectQuestSortList = props.alreadySelectQuestSortList
      if (curSort === item.sort) return true
      if (props.alreadySelectQuestionList) {
        // 选项显示
        alreadySelectQuestSortList = props.alreadySelectQuestionList.map(item => item.sort.key)
        // 加上选项id 的原因是，条件题目的可选范围是跟本题的选项绑定的，
        // 当使用handleSelectChange事件emit时，父组件才知道是本题哪个选项里面的条件题目发生变化
        item.choiceId = props.choiceId
      }
      return !alreadySelectQuestSortList.includes(item.sort)
    })
  })

  // 条件题目下拉切换事件
  const handleSelectChange = (selectData, selectedObj) => {
    const curQuestIndex = props.index
    const trageQuestId = selectData.value // 切换的目标题目id
    const choiceId = selectedObj.question.choiceId ? selectedObj.question.choiceId : null
    emit('condQuestSelectChange', curQuestIndex, trageQuestId, choiceId)
  }
  return {
    enableChooseQuestionList,
    handleSelectChange,
  }
}

/**
 * 处理发布相关事件
 */
const handleEmitEffect = (emit, props) => {
  // 删除关联
  const handleDeleteClick = (index) => {
    // props.choiceId 是选项显示才有，题目显示直接undefined即可
    emit('delete', index, props.choiceId, )
  }
  return {
    handleDeleteClick,
  }
}

const questionTypeList = {
  SINGLE_CHOICE: '单选题',
  MULTIPLE_CHOICE: '多选题',
  DROP_DOWN: '下拉题',
  SCALE: '量表题',
  SHORT: '简答题',
  DATE: '日期题',
  MATRIX_SINGLE_CHOICE: '矩阵单选题',
  MATRIX_MULTIPLE_CHOICE: '矩阵多选题',
  MATRIX_SCALE: '矩阵量表题',
}


export default {
  name: 'Condition',
  components: {
    RichTitle
  },
  props: [
    'item',
    'index',
    'questionId',
    'beforeQuestList',
    'alreadySelectQuestSortList', // 题目显示传过来的
    'alreadySelectQuestionList',  // 选项显示传过来的
    'choiceId', // 选项显示传过来的
  ],
  emits: [
    'delete',
    'condQuestSelectChange',
  ],
  setup (props, { emit }) {
    const question = props.item
    const { isShowRowSelect } = controlRowSelectEffect(question.questionType)
    const { isDisableCondSelect } = controlCondSelectEffect(question.questionType)
    const { enableChooseQuestionList, handleSelectChange } = selectEffect(props, emit, question)
    const { handleDeleteClick } = handleEmitEffect(emit, props)
    return {
      question,
      enableChooseQuestionList,
      handleSelectChange,
      isShowRowSelect,
      isDisableCondSelect,
      handleDeleteClick,
      questionTypeList
    }
  }
}
</script>

<style lang="less" scope>
.multiple-choice {
  margin-top: 16px;
  font-weight: 100;
  .rich-title {
    p {
      display: inline;
    }
  }
  .title-tips {
    white-space: nowrap;
  }
  .title {
    position: relative;;
    .sort {
      font-size: 16px;
      font-weight: bold;
      display: inline-block;
      // vertical-align:bottom;
    }
    .close {
      position: absolute;
      top: 6px;
      right: 16px;
      font-size: 16px;
      cursor: pointer;
    }

  }
  .row {
    margin-top: 16px;
  }
  .options {
    box-sizing: border-box;
    padding-left: 32px;
    margin-top: 17px;
  }
  .desc {
    margin-top: 10px;
    .desc-select {
      width: 100px;
      height: 41px;
      text-align: center;
      border: 1px solid #E1E1E8;
      margin: 0 8px;
      .ant-select-selector {
        height: 41px;
        border-radius: 0px;
        .ant-select-selection-item {
          line-height: 41px;
        }
      }
    }
    .desc-single {
      display: inline-block;
      width: 100px;
      text-align: center;
      border-bottom: 1px solid #d7d7d7;
      font-weight: bold;
    }
  }
}
</style>
<style lang="less">
.multiple-choice {
  .ant-checkbox-wrapper {
    display: block;
    font-size: 16px;
    margin: 20px 0;
    .ant-radio-inner {
      width: 20px!important;  
      height: 20px!important;
      border-color: #dbdbdb;
      &::after {
        top: 5px;
        left: 5px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        padding: 0;
        border: none;
      }
    }
    .ant-radio-checked {
      border-color: #f59a23;
    }
  }
  .ant-select-selector {
    padding-left: 0!important;
  }
}
</style>