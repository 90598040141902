<!--
 * @Description: 行标题引用设置
 * @version: 
 * @Author: PSG
 * @Date: 2021-04-21 14:04:19
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-29 14:41:11
-->
<template>
  <div class="row-title-tab">
    <div class="rich-text">
      <p>当前题目为：</p>
      <RichTitle class="rich-title" :title="question.title">
        <span class="title-tips">({{questionTypeList[question.questionType]}})</span>
      </RichTitle>
    </div>
    <div class="body">
      <span>选项来源题目：</span>
      <a-select 
        label-in-value 
        style="width: 541px;border-bottom: 1px solid #d7d7d7;font-weight: 100" 
        :bordered="false"
        v-model:value="rowRefVal"
      >
        <a-select-option :value="-1" >请选择行标题</a-select-option>
        <a-select-option
          v-for="mQuestion in beforeMultipleQuestList"
          :value="mQuestion.questionId"
          :key="mQuestion.questionId"
        >
         <div class="item" style="display: flex">
            {{mQuestion.sort + 1}}、<RichTitle :title="mQuestion.title"/>
            <!-- <p>({{questionTypeList[enableChooseQuestion.questionType]}})</p> -->
          </div>
        </a-select-option>
        <!-- <a-select-option :value="2">1、题目标题（多选）</a-select-option>
        <a-select-option :value="3">5、题目标题（多选）</a-select-option>
        <a-select-option :value="4">7、题目标题（多选）</a-select-option>
        <a-select-option :value="5">8、题目标题（多选）</a-select-option> -->
      </a-select>
    </div>
  </div>
</template>

<script>
import { inject } from '@vue/runtime-core'
import { useStore } from 'vuex'
import useParseExpEffect from '@/effects/parseExpEffect'
import globalQuestionnaire from '@/class/useQuestionnaire'
import RichTitle from '../../question/components/richTitle'

/**
 * 获取本题前面的条件题目（只获取多选）
 */
const getBeforeMultipleQuestList = (question) => {
  const store = useStore()
  const curSort = question.sort
  const questionnaire = globalQuestionnaire.getQuestionnaire()
  const questions = JSON.parse(JSON.stringify(questionnaire.questions))
  const beforeMultipleQuestList = questions
    .filter(item => /^MULTIPLE_CHOICE$/.test(item.questionType)
                    && item.sort < curSort)
  return {
    beforeMultipleQuestList
  }
}

const questionTypeList = {
  SINGLE_CHOICE: '单选题',
  MULTIPLE_CHOICE: '多选题',
  DROP_DOWN: '下拉题',
  SCALE: '量表题',
  SHORT: '简答题',
  DATE: '日期题',
  MATRIX_SINGLE_CHOICE: '矩阵单选题',
  MATRIX_MULTIPLE_CHOICE: '矩阵多选题',
  MATRIX_SCALE: '矩阵量表题',
}

export default {
  name: 'RowTitleTab',
  components: {
    RichTitle
  },
  setup () {
    const question = inject('question')
    const { parseRowTitleLogicExp } = useParseExpEffect()
    const { rowRefVal } = parseRowTitleLogicExp(question)
    const { beforeMultipleQuestList } = getBeforeMultipleQuestList(question)

    return {
      question,
      rowRefVal,
      beforeMultipleQuestList,
      questionTypeList
    }
  }
}
</script>

<style lang="less" scope>
.title-tips {
  word-break: keep-all;
}
.row-title-tab {
  box-sizing: border-box;
  padding-top: 20px !important;
  padding-left: 20px !important;
  .rich-text {
    display: flex;
    margin-bottom: 20px;
    font-weight: bold;
    >p {
      flex: 0 0 100px;
    }
    .rich-title {
      flex: auto;
      p {
        display: inline;
      }
    }
  }
  .body {
    font-weight: 100;
  }
}
</style>