<!--
 * @Description: 问卷底部栏
 * @version: 
 * @Author: HWL
 * @Date: 2021-06-10 10:24:43
 * @LastEditors: HWL
 * @LastEditTime: 2021-06-11 17:28:01
-->
<template>
  <div :class="[isPc ? 'bottom' : 'mobile-bottom']">
    <div class="button" @click="handleSubmitClick" v-if="isLastPage">提交</div>
    <div class="button" @click="handleNextClick" v-else>下一页</div>
    <div class="info">第{{curPage + 1}}页 / 共{{maxPage + 1}}页</div>
  </div>
</template>
<script>
export default {
  name: 'QuestionnaireBottom',
  emits: ['submit', 'next'],
  props: ['isPc', 'curPage', 'maxPage', 'isLastPage'],
  setup(props, { emit }) {
    const handleSubmitClick = () => {
      emit('submit')
    }
    const handleNextClick = () => {
      emit('next')
    }

    return {
      handleSubmitClick,
      handleNextClick
    }
  }
}
</script>
<style lang="less" scoped>
.bottom {
  position: relative;
  width: 1020px;
  margin: -20px auto 0 auto;
  background-color: #fff;
  .button {
    width: 108px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    color: #fff;
    background-color: #00a0e9;
    margin: 0 auto;
    cursor: pointer;
    &:nth-child(2) {
      margin-left: 10px;
    }
  }
  .info {
    position: absolute;
    top: 46px;
    right: 50px;
    text-align: center;
    color: #7F7F88;
    font-size: 14px;
    font-weight: 300;
  }
}
.mobile-bottom {
  padding-bottom: 60px;
  background: #fff;
  .button {
    width: 220px;
    height: 50px;
    line-height: 50px;
    font-size: 24px;
    text-align: center;
    color: #fff;
    background-color: #00a0e9;
    margin: 0 auto;
  }
  .info {
    margin-top: 20px;
    text-align: center;
    color: #7F7F88;
    font-weight: 300;
  }
}
</style>