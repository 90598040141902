<!--
 * @Description: 量表题
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-18 18:58:38
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-26 10:46:55
-->
<template>
  <!-- 电脑端 -->
  <div class="scale" v-if="isPc"
    :class="{noborder: !isSettingsActive}" 
  >
    <div class="sort-icon">
      <div class="preview-sort iconfont" v-if="!isOnlyRead">&#xe697;</div>
    </div>
    <div 
      class="preview-del iconfont" 
      @click.stop="handleDelete" 
      v-if="!isOnlyRead && !route.query.isKeepAnswerAndEdit"
    >&#xe609;</div>
    <div 
      class="preview-copy iconfont" 
      @click.stop="handleCopy" 
      v-if="!isOnlyRead && !route.query.isKeepAnswerAndEdit"
    >&#xe62f;</div>
    <div class="preview" @click="edit(question.questionId)">
      <PreviewTitle :isOnlyRead="isOnlyRead" :question="question" :isPc="isPc"/>
      <div class="preview-options">
        <span style="padding-right: 20px;">{{question.choices[0]?.text}}</span>
        <a-radio-group
          v-model:value="radioValue"
          :disabled="isDisabled"
          @change="fillAnswerIntime(question, radioValue)"
        >
          <a-radio
            class="preview-radio"
            v-for="item in question.choices"
            :value="item.choiceId" 
            :key="item.choiceId" 
          >
            {{item.value}}
          </a-radio>
        </a-radio-group>
        <span>{{question.choices[question.choices.length - 1]?.text}}</span>
      </div>
      <div class="preview-tips">
        <div class="tips" v-if="item.isNoCondJump">逻辑设置：{{item.jumpLogicTips}}</div>
        <div class="tips" v-if="item.showLogicTips">逻辑设置：本题设置了题目显示逻辑：{{item.showLogicTips}}</div>
      </div>
      <PreviewBottom v-if="isOnlyRead" />
    </div>
    <PreviewBottom v-if="!isOnlyRead" style="background: #fff"/>
    <setting
      v-if="isSettingsActive"
      type="SCALE"
      :item="item"
      :index="index"
      :isOnlyRead="isOnlyRead"
      :question="question"
      @finished="finished"
    />
  </div>
  <!-- 手机端 -->
  <div class="mobile-scale" v-else>
    <div class="preview">
      <PreviewTitle :isOnlyRead="isOnlyRead" :question="question" :isPc="isPc"/>
      <!-- <div class="preview-options">
        <div class="colunm">
          <template v-for="(item, index) in question.choices" :key="item.choiceId">  
          <span>{{(index === 0) || (index === question.choices.length -1) ? item.text : ''}}</span>
          </template>  
        </div>
        <div class="row">
          <a-radio-group 
            v-model:value="radioValue"
            @change="fillAnswerIntime(question, radioValue)"
          >
            <template v-for="(item, index) in question.choices" :key="item.choiceId">
              <a-tooltip placement="top" :title="item.value" arrow-point-at-center>
                <a-radio :value="item.choiceId" ></a-radio>
              </a-tooltip>
            </template>
          </a-radio-group>
        </div>
      </div> -->
      
      
      <div class="preview-options1">
        <div class="colunm">
          <template v-for="(item, index) in question.choices" :key="item.choiceId">  
            <span>{{(index === 0) || (index === question.choices.length -1) ? item.text : ''}}</span>
          </template>  
        </div>
        <div class="row">
          <a-radio-group
            v-model:value="radioValue"
            @change="fillAnswerIntime(question, radioValue)"
          >
            <template v-for="(item, index) in question.choices" :key="item.choiceId">
              <a-tooltip placement="top" :title="item.value" arrow-point-at-center>
                <a-radio :value="item.choiceId" ></a-radio>
              </a-tooltip>
            </template>
          </a-radio-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import useCommonQuestionnaireEffect from '@/effects/questionnaireEffect'
import logicWatcherEffect from '@/effects/logicWatcherEffect'
import Setting from './components/setting'
import PreviewTitle from './components/previewTitle'
import PreviewBottom from './components/previewBottom'

/**
 * 答案管理
 */
const answerEffect = () => {
  const route = useRoute()
  const { watchVisibilityEffect } = logicWatcherEffect()
  // 答案获取
  const getAnswer = (question, choiceId) => {
    const answer = {
      "questionType": question.questionType, 
      "questionId": question.questionId,
      "choices": [{
        "choiceId": choiceId,
        "fillText": question.choices.filter(item => item.choiceId === choiceId)[0].fillText
      }]
    }
    return answer
  }

  // 答案填写
  // isAreadyAnswerFill，只需要初始化组件时执行一次即可
  const fillAnswer = (question, radioValue, isAreadyAnswerFill) => {
    if (route.name === 'RecycleBin') isAreadyAnswerFill.value = false // 这种是特殊情况，当在回收情况、查看问卷、点击上一份、下一份时，是允许重新填写答案并展示出来的
    if (!isAreadyAnswerFill.value) {
      isAreadyAnswerFill.value = true
      const choiceId = question.answer.choices[0].choiceId
      const fillText = question.answer.choices[0]?.fillText || ''
      radioValue.value = choiceId
      question.choices.forEach(choice => {
        if (choice.choiceId === choiceId) {
          choice.fillText = fillText
        }
      })
    }
  }

  // 实时填写答案
  const fillAnswerIntime = (question, value) => {
    question.answer = getAnswer(question, value)
    watchVisibilityEffect(question)
    document.getElementById(question.questionId).classList.remove('error')
  }

  return {
    getAnswer,
    fillAnswer,
    fillAnswerIntime,
  }
}

export default {
  props: ['item', 'index', 'isOnlyRead', 'isDisabled', 'isPc'],
  components: { 
    Setting,
    PreviewTitle,
    PreviewBottom,
  },
  name: 'Scale',
  setup(props) {
    const store = useStore()
    const route = useRoute()
    const radioValue = ref('')
    const selectVal = ref({key: 'SCALE'});
    const isAreadyAnswerFill = ref(false)
    const { getAnswer, fillAnswer, fillAnswerIntime } = answerEffect()
    const question = computed({
      get: () => {
        props.item.choices.forEach(choice => {
          if (choice.fillText === undefined) {
            choice.fillText = ''
          }
        })
        if (!props.item.answer?.questionId) {
          props.item.answer = {}
        } else {
          fillAnswer(props.item, radioValue, isAreadyAnswerFill)
        }
        return props.item
      }
    })
    watch (() => store.state.curEditingQuestonId, (cur, pre) => {
      if (/^(Preview|Exercise)$/.test(route.name)) return
      if (cur === question.value.questionId) {
        isSettingsActive.value = props.isOnlyRead ? false : true
      } else {
        isSettingsActive.value = false
      }
    })
    
    const { handleEffect, questionEditStatusChangeEffect, jumpLogicEffect, showLogicEffect, } = useCommonQuestionnaireEffect()
    const { isSettingsActive, edit } = questionEditStatusChangeEffect(props)
    const { handleDelete, handleCopy, handleChange } = handleEffect(props)

    const finished = () => {
      isSettingsActive.value = false
    }

    edit(props.item.questionId)

    return {
      route,
      radioValue,
      selectVal,
      question,
      fillAnswerIntime,
      finished,
      edit,
      isSettingsActive,
      handleDelete,
      handleCopy,
      handleChange,
    }
  },
}
</script>

<style scoped lang="less">
// 电脑端
.scale {
  position: relative;
  background-color: @backgroundColor;
  border-bottom: 1px solid #E1E1E8;
  border-radius: 10px;
  
  .preview-del {
    position: absolute;
    top: 47px;
    right: 93px;
    color: #00A0E9;
    font-size: 24px;
    z-index: 1;
  }
  .preview-copy {
    position: absolute;
    top: 47px;
    right: 49px;
    color: #00A0E9;
    font-size: 24px;
    z-index: 1;
  }
  .sort-icon {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 24px;
    margin-top: 10px;
    .preview-sort {
      width: 24px;
      height: 24px;
      font-size: 24px;
      transform: rotate(90deg);
      color: #b7b7b7;
      z-index: 1;
    }
  }
  .iconfont:hover {
    background-color: @hoverBackground;
    cursor:pointer;
  }
  .preview {
    position: relative;
    padding: 40px 32px 0px 32px;
    background-color: #fff;

    .preview-title { 
      font-size: 18px;
      margin-top: 24px;
      span {
        color: red;
      }
    }
    .preview-descript{ 
      font-size: 14px;
      color: #aeaeae;
      margin-top: 16px;
      padding-left: 20px;
    }
    .preview-options{ 
      padding: 16px 0 32px 0;
      font-size: 20px;
      font-weight: 100;
      .preview-options-desc {
        margin: 10px 0 0 10px;
        color: #33333370;
      }
      .preview-radio {
        height: 24px;
        line-height: 24px;
        margin-right: 20px;
        ::v-deep(.ant-radio-inner) {
          height: 24px;
          width: 24px;
          &::after {
            height: 10px;
            width: 10px;
          }
        }
      }
      .radio {
        display: 'block';
        height: '30px';
        line-height: '30px';
      }
    }
    .preview-tips {
      .tips {
        margin-top: 20px;
        color: #a5a5a5;
        font-size: 14px;
      }
    }
  }
  .settings {
    position: relative;
    margin-top: 10px;
    padding: 16px 32px 16px 32px;
    background-color: #fff;
    border-top-right-radius: 10px;
    .settings-type {
      position: absolute;
      top: 10px;
      right: 20px;
      width: 210px;
    }
    .settings-title {
      width: 500px;
      font-size: 18px;
    }
    .settings-toptitle {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      color: #8b8b8b;
      font-size: 14px;
      .settings-toptitle-desc {
        > span {
          margin-left: 5.5rem;
        }
        // display: block;
        // margin-left: -5px;
        // text-align: center;
        // text-indent: 15px;
      }
      .settings-toptitle-isfill {
        text-align: center;
      }
    }
    .settings-options {
      display: flex;
      position: relative;
      margin-top: 20px;
      .settings-options-sort {
        position: absolute;
        top: 6px;
        left: -20px;
        z-index: 99;
        color: #b7b7b7;
      }
      .settings-options-input {
        width: 600px!important;
        font-size: 16px;
      }
      .settings-options-desc {
        text-align: center;
        font-size: 26px;
      }
      .settings-options-scorebox {
        display: flex;
        padding-left: 50px;
        input {
          flex: 0;
          display: inline-block;
          padding: 10px;
          border: 1px solid #d9d9d9;
          width: 32px;
          height: 32px;
        }
        p {
          flex: 0;
          font-size: 12px;
          margin-left: 4px;
          margin-right: 10px;
          white-space: nowrap;
          height: 30px;
          line-height: 30px;
          width: 36px;
        }
        span {
          flex: 0;
          height: 24px;
          border-right: 1px solid #000;
          margin-left: 10px;
          margin-top: 3px;
        }
        button {
          margin-top: 6px;
        }
      }
      .settings-options-del {
  vertical-align: top;
        margin-left: 16px;
      }
      .iconfont {
        cursor:pointer;
        &:hover {
          background-color: @hoverBackground;
        }
      } 
    }
    .settings-add {
      margin-top: 20px;
      div {
        width: 120px;
        height: 36px;
        border: 1px dashed #d9d9d9;
        border-radius: 4px;
        display: inline-block;
        font-size: 14px;
        color: #666;
        text-align: center;
        line-height: 36px;
        margin-right: 20px;
        .pointer()
      }
      .iconfont {
        font-size: 12px;
        margin-right: 6px;
      }
    }
  }
  .bottom {
    margin-top: 2px;
    padding: 22px 32px 16px 32px;
    background-color: #fff;
    border-top-right-radius: 10px;
    .base-setting {
      .bottom-desc {
        text-decoration: underline;
        margin-right: 16px;
        &:hover {
          background-color: @hoverBackground;
          cursor:pointer;
        }
      }
      .bottom-switch {
        margin: 0 12px;
      }
      .bottom-vertical {
        display: inline-block;
        border: 1px solid #d7d7d7;
        border-radius: 16px;
        font-size: 13px;
        width: 82px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        color: #727272;
        margin-left: 10px;
      }
    }
    .logic-setting {
      margin-top: 16px;
      .show-setting {
        margin-right: 10px;
        text-decoration: underline;
        .pointer()
      }
      .jump-setting {
        text-decoration: underline;
        .pointer()
      }
    }
    .bottom-finish {
      margin: 34px auto  10px;
      width: 100px;
      height: 32px;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      line-height: 32px;
      color: #FFFFFF;
      border-radius: 4px;
      background-color: #f59a23;
      cursor: pointer;
    }
  }
}
// 手机端
.mobile-scale {
  border-bottom: 1px solid #E1E1E8;
  .preview {
    padding: 25px 20px;
    background-color: #fff;
    .preview-title { 
      font-size: 18px;
      line-height: 32px;
      font-weight: bold;
      span {
        color: red;
      }
    }
    .preview-descript{ 
      font-size: 14px;
      line-height: 30px;
      margin-top: 4px;
      color: #aeaeae;
      padding-left: 20px;
    }
    .preview-options{ 
      overflow-y: scroll;
      margin-top: 16px;
      .colunm{
        display: flex;
        flex-wrap: nowrap;
        span {
          flex: 1 0 auto;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 80px;
          // background-color: #E1E1E8;
          padding: 10px;
          line-height: 24px; 
          white-space: nowrap;
        }
      }
      .row {
        .ant-radio-group {
          display: flex;
          .ant-radio-wrapper {
            flex: 1 0 auto;
            display: flex;
            justify-content: center;
            width: 80px;
            font-size: 16px;
            margin: 20px 0;
            background-color: rgba(225,225,232, 0.14);
            word-break: break-all; //只对英文起作用，以字母作为换行依据
            word-wrap: break-word; // 只对英文起作用，以单词作为换行依据
            white-space:pre-wrap; // 只对中文起作用，强制换行
            span:nth-child(2) {
              line-height: 26px;
              color: #2D2D33;
            }
          } 
        }
      }
    }
    .preview-options1 {
      white-space: nowrap;
      overflow-y: scroll;
      .colunm {
        width:fit-content;
        margin-top: 10px;
        margin-bottom: 16px;
        span {
          margin: 24px 16px;
          &:first-child {
            margin-left: 6px;
          }
          &:last-child {
            margin-right: 0;
          }
          
        }
      }
      .row {
        background-color: #fafafc;
        .ant-radio-group {
          background-color: #fafafc;
          .ant-radio-wrapper {
            margin: 24px 13px;
          }
        }
      }
    }
    .preview-tips {
      .tips {
        margin-top: 10px;
        color: #a5a5a5;
        font-size: 14px;
      }
    }
  }
} 
// 公用部分
.noborder {
  border: none;
}
.hide {
  display: none;
}
.isAlreadySet {
  color: #F59A23;
}
</style>

<style lang="less">
.scale, .mobile-scale {
  .ant-radio-checked {
    .ant-radio-inner {
      background-color: #00A0E9;
      &::after {
        width: 5px;
        height: 5px;
        left: 50%; 
        top: 50%;
        transform: translate(-50%, -50%); /* 50%为自身尺寸的一半 */
        -webkit-transform: translate(-50%, -50%);
        border-radius: 50%;
        padding: 0;
        margin: 0;
        border: none;
        background-color: #fff;
      }
    }
  }
  input {
    border: none;
    border-bottom: 1px solid #d7d7d7;
  }
}

.ant-tooltip {
  .ant-tooltip-content {
    border: 1px solid #00A0E9;
    border-radius: 6px;
    .ant-tooltip-arrow {
      .ant-tooltip-arrow-content {
        background-color: #fff;
        border: 1px solid #00A0E9;
      }
    }
    .ant-tooltip-inner {
      min-width: 20px;
      min-height: 10px;
      padding: 2px 6px;
      color: #00A0E9;
      background-color: #fff;
      border-radius: 6px;
    }
  }
}
</style>
