/*
 * @Description: 选项更新功能
 * @version: 
 * @Author: HWL
 * @Date: 2021-05-26 16:11:16
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-15 16:48:55
 */
import { createUniqueId } from '@/utils/random'
import { nextTick } from 'vue'

/**
 * 新增选项
 * @param {question} question 问题
 */
export function handleChoiceAdd(question) {
  const choiceId = createUniqueId(10)
  question.choices.push({
    'choiceId': choiceId, //选项id
    'text': '', //选项内容
    'description': '', //选项描述
    'ifAllowFillInBlanks': false, //是否能填空
    'ifRequired': false, //是否必填
    'pic': '', //图片
    'isNewAdd': true, // 是否是新添加的项
  })
  nextTick(() => {
    const targetArea = document.getElementById(`text-area_${choiceId}`)
    targetArea.focus()
  })
}

/**
 * textarea阻止输入回车符
 * @param {*} event 
 * @param {*} choiceData 
 */
export function handleStopInputEnter(event, choiceData) {
  if (event.keyCode === 13) {
    choiceData.text = choiceData.text.replace('\n', '')
  }
}

/**
 * 删除选项
 * @param {question} question 问题
 * @param {string} choiceId 选项id
 */
export function handleChoiceDelete(question, choiceId) {
  question.choices = question.choices.filter( item => item.choiceId != choiceId)
}