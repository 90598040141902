<!--
 * @Description: 矩阵量表选项
 * @version: 
 * @Author: HWL
 * @Date: 2021-05-31 14:46:49
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-20 17:25:42
-->
<template>
  <div class="matrix-scale-option">
    <div class="settings-content">
      <div class="settings-content-left" v-show="!question.ref">
        <a-row type="flex" class="settings-toptitle">
          <a-col :span="19">行标题</a-col>
          <a-col :span="4" class="settings-toptitle-desc">行标题说明</a-col>
        </a-row>
        <draggable
          :list="question.rowItems"
          handle=".settings-options-sort"
          item-key="choiceId"
        >
          <template #item="{ element, index }">
            <a-row 
              type="flex" 
              class="settings-options"
            >
              <span class="iconfont settings-options-sort">&#xe697;</span>
              <a-col :span="18">
                <a-textarea
                  :id="question.questionId+ '_row_' + index"
                  class="settings-options-input" 
                  v-model:value="element.text"
                  placeholder="行标题"
                  @keyup.enter.stop="handleStopInputEnter($event, element), handleRowAdd(item)"
                  :disabled="!!route.query.isKeepAnswerAndEdit && !element.isNewAdd"
                  auto-size
                />
                <span 
                  v-if="!route.query.isKeepAnswerAndEdit || element.isNewAdd"
                  class="settings-options-del iconfont" 
                  @click="handleRowDelete(question, index)"
                >&#xe6ce;</span>
              </a-col>
              <a-col :span="4" style="text-algin:center">
                <div 
                  class="settings-options-desc iconfont" 
                  :class="{isAlreadySet: element.description?.length !== 0}" 
                  @click.stop="showOptionDescModal(element)">
                  &#xe637;
                </div>
              </a-col>
            </a-row>
          </template>
        </draggable>
        <div class="settings-add">
          <div class="scale__settings__add__addone" @click="handleRowAdd(question)">
            <span class="iconfont">&#xe633;</span>
            <span class="text">添加</span>
          </div>
          <div 
            v-if="!route.query.isKeepAnswerAndEdit" 
            class="scale__settings__add__batch" 
            @click="openBatchAddModal('rowItems')"
          >
            <span class="iconfont">&#xe633;</span>
            <span class="text">批量添加</span>
          </div>
        </div>
      </div>
      <div :class="{'settings-content-center': true, 'no-border': question.ref}"></div>
      <div class="settings-content-right">
        <a-row type="flex" class="settings-toptitle">
          <a-col :span="14">列标题</a-col>
          <a-col :span="6" class="settings-toptitle-desc">分数</a-col>
        </a-row>
        <draggable
          :list="question.columnItems"
          handle=".settings-options-sort"
          item-key="choiceId" 
        >
          <template #item="{ element, index }">
            <a-row 
              type="flex" 
              class="settings-options"
            >
              <span class="iconfont settings-options-sort">&#xe697;</span>
              <a-col :span="14">
                <a-textarea
                  :id="question.questionId+ '_col_' + index"
                  class="settings-options-input" 
                  placeholder="列标题"
                  @keyup.enter.stop="handleStopInputEnter($event, element), handleColumnAdd(item)"
                  v-model:value="element.text"
                  :disabled="!!route.query.isKeepAnswerAndEdit && !element.isNewAdd"
                  auto-size
                />
                <span
                  v-if="!route.query.isKeepAnswerAndEdit || element.isNewAdd"
                  class="settings-options-del iconfont" 
                  @click="handleColumnDelete(question, index)"
                >&#xe6ce;</span>
              </a-col>
              <a-col :span="8" style="text-algin:center">
              <div class="settings-options-scorebox">
                <a-input 
                  v-model:value="element.value" 
                  :disabled="!!route.query.isKeepAnswerAndEdit && !element.isNewAdd"
                />
                <p>不计分</p>
                <a-switch 
                  size="small" 
                  v-model:checked="element.ifIgnore" 
                  :disabled="!!route.query.isKeepAnswerAndEdit && !element.isNewAdd"
                />
              </div>
            </a-col>
            </a-row>
          </template>
        </draggable>

        <div class="settings-add">
          <div class="scale__settings__add__addone" @click="handleColumnAdd(question)">
            <span class="iconfont">&#xe633;</span>
            <span class="text">添加</span>
          </div>
          <div
            v-if="!route.query.isKeepAnswerAndEdit"
            class="scale__settings__add__batch" 
            @click="openBatchAddModal('columnItems')"
          >
            <span class="iconfont">&#xe633;</span>
            <span class="text">批量添加</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <BatchAddModal
    title="批量新增"
    :visible="isShowBatchAddModal" 
    :text="allOptionsText"
    @finishEditText="handleBatchUpdate"
  />
</template>
<script>
import { useRoute } from 'vue-router'
import { handleRowAdd, handleColumnAdd, handleRowDelete, handleColumnDelete, handleStopInputEnter } from '../effects/matrixChoicesUpdateEffect'
import BatchAddModal from '@/components/modal/BatchAddModal'
import MatrixBatchUpdateEffect from '../effects/matrixBatchUpdateEffect'

import draggable from 'vuedraggable'

export default {
  name: 'MatrixScaleChoiceOption',
  components: {
    draggable,
    BatchAddModal
  },
  props: [
    'item',
    'question',
  ],
  emits: [
    'showOption',
    'showBatch',
  ],
  setup(props, { emit }) {
    const route = useRoute()
    const showOptionDescModal = (item) => {
      emit('showOption', item)
    }
    
    const showBatchAddModal = (item) => {
      emit('showBatch', item)
    }

    const { isShowBatchAddModal, allOptionsText, openBatchAddModal, handleBatchUpdate } = MatrixBatchUpdateEffect(props.question, 'multiple')

    return {
      route,

      handleRowAdd, handleColumnAdd, handleRowDelete, handleColumnDelete, handleStopInputEnter,
      isShowBatchAddModal, allOptionsText, openBatchAddModal, handleBatchUpdate,

      showOptionDescModal,
      showBatchAddModal,
    }
  }
}
</script>
<style lang="less" scoped>
.settings-content {
  display: flex;
  .no-border {
    border: none!important;
  }
  .settings-content-left {
    flex: 0 1 49%;
    .settings-toptitle {
      margin-top: 20px;
      color: #8b8b8b;
      font-size: 14px;
      .settings-toptitle-desc {
        text-align: center;
      }
      .settings-toptitle-isfill {
        text-align: center;
      }
    }
    .settings-options {
      display: flex;
      position: relative;
      height: 100%;
      line-height: 41px;
      margin-top: 20px;
      .settings-options-sort {
        font-size: 24px;
        z-index: 99;
        color: #b7b7b7;
      }
      .settings-options-input {
        width: 310px!important;
        height: 41px;
        font-size: 16px;
        margin-left: 6px;
      }
      .settings-options-desc {
        text-align: center;
        font-size: 26px;
      }
      .settings-options-scorebox {
        display: flex;
        input {
          flex: 0;
          display: inline-block;
          padding: 10px;
          border: 1px solid #d9d9d9;
          width: 32px;
          height: 32px;
        }
        p {
          flex: 0;
          font-size: 12px;
          margin-left: 4px;
          margin-right: 10px;
          white-space: nowrap;
          height: 30px;
          line-height: 30px;
        }
        span {
          flex: 0;
          height: 24px;
          border-right: 1px solid #000;
          margin-left: 10px;
          margin-top: 3px;
        }
        button {
          margin-top: 6px;
        }
      }
      .settings-options-del {
        vertical-align: top;
        margin-left: 16px;
      }
      .settings-options-desc {
        text-align: center;
        font-size: 26px;
      }
      .iconfont {
        cursor:pointer;
        &:hover {
          background-color: @hoverBackground;
        }
      } 
    }
    .settings-add {
      margin-top: 20px;
      margin-left: 30px;
      div {
        display: inline-block;
        font-size: 14px;
        color: #00A0E9;
        text-align: center;
        margin-right: 24px;
        .pointer()
      }
      .iconfont {
        font-size: 12px;
        margin-right: 6px;
        border: 1px solid #00A0E9;
      }
      .text {
        text-decoration: underline;
      }
    }
  }
  .settings-content-center {
    flex: 1 1 auto;
    border-left: 1px solid #000;
    margin-top: 20px;
  }
  .settings-content-right {
    flex: 0 1 49%;
    .settings-toptitle {
      margin-top: 20px;
      color: #8b8b8b;
      font-size: 14px;
      .settings-toptitle-desc {
        margin-left: 55px;
      }
      .settings-toptitle-isfill {
        text-align: center;
      }
    }
    .settings-options {
      display: flex;
      position: relative;
      height: 100%;
      line-height: 41px;
      margin-top: 20px;
      .settings-options-sort {
        font-size: 24px;
        z-index: 99;
        color: #b7b7b7;
      }
      .settings-options-input {
        width: 200px!important;
        height: 100%;
        font-size: 16px;
        margin-left: 6px;
      }
      .settings-options-desc {
        text-align: center;
        font-size: 26px;
      }
      .settings-options-scorebox {
        display: flex;
        justify-content: flex-end;
        input {
          flex: 0;
          display: inline-block;
          border: 1px solid #d9d9d9;
          width: 35px;
      height: 32px;
        }
        p {
          flex: 0;
          font-size: 16px;
          font-weight: 100;
          color: #7F7F88;
          margin-left: 4px;
          margin-right: 10px;
          white-space: nowrap;
          height: 30px;
          line-height: 30px;
        }
        span {
          flex: 0;
          height: 24px;
          border-right: 1px solid #000;
          margin-left: 10px;
          margin-top: 3px;
        }
        button {
          margin-top: 6px;
        }
      }
      .settings-options-del {
        vertical-align: top;
        margin-left: 16px;
      }
      .iconfont {
        cursor:pointer;
        &:hover {
          background-color: @hoverBackground;
        }
      } 
    }
    .settings-add {
      margin-top: 20px;
      margin-left: 30px;
      div {
        display: inline-block;
        font-size: 14px;
        color: #00A0E9;
        text-align: center;
        margin-right: 24px;
        .pointer()
      }
      .iconfont {
        font-size: 12px;
        margin-right: 6px;
        border: 1px solid #00A0E9;
      }
      .text {
        text-decoration: underline;
      }
    }
  }
}
.isAlreadySet {
  color: #00A0E9;
}
</style>