<!--
 * @Description: 问卷标题
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-30 18:04:28
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-09 17:18:47
-->
<template>
  <div :class="[isPc ? 'title' : 'mobile-title']" v-if="isOnlyRead">
    <div class="read">
      <div class="read-title" >{{atitle}}</div>
      <div class="read-description" v-if="!questionnaire.descriptionCSS">{{adescription}}</div>
      <RichTitle v-else style="width: 100%;" :title="questionnaire.description"/>
    </div>
  </div>
  <div class="edit" v-else @click="handleEditorModel(questionnaire)">
    <div class="title-input" @click="handleEditorModel(questionnaire)">
      <div class="edit-title" v-if="atitle">{{atitle}}</div>
      <div class="edit-placeholder" v-else>{{placeholder}}</div>
    </div>
    <div class="description" @click="handleEditorModel(questionnaire)">
      <RichTitle class="rich-title" v-if="questionnaire.description" :title="questionnaire.description"/>
      <div class="description-placeholder" v-else>添加问卷说明</div>
    </div>
    <div class="editor-icon iconfont" @click="handleEditorModel(questionnaire)">T</div>
  </div>
  <TitleModal
    ref="editorRef"
    modalTitle="标题"
    modalSubTitle="说明"
    :title="questionnaire.title"
    :visible="isShowEditorModal" 
    :text="text"
    @finishEditText="updateEditor"
  />
</template>

<script>
import { computed, nextTick, ref } from 'vue';
import globalQuestionnaire from '@/class/useQuestionnaire'
import EditorModal from '@/components/modal/EditorModal'
import TitleModal from '@/components/modal/TitleModal'
import RichTitle from '../components/richTitle'
import editorEffect from '../components/effects/editorEffect'

export default ({
  name: 'Title',
  props: ['title', 'description', 'isOnlyRead', 'placeholder', 'descPlaceholder', 'isPc'],
  components: {
    EditorModal,
    TitleModal,
    RichTitle,
  },
  setup(props) {
    const isShowTitle = ref(false)
    const questionnaire = globalQuestionnaire.getQuestionnaire()
    const atitle = computed({
      get: () => props.title,
      set: (newVal) => questionnaire.title = newVal
      
    })
    const adescription = computed({
      get: () => props.description,
      set: (newVal) => questionnaire.description = newVal
    })
    const editorRef = ref(null)
    const { isShowEditorModal, text, changeOption, handleEditorModel, changeEditor, changeTitle } = editorEffect('description')

    const updateEditor = (data) => {
      if (data) {
        changeEditor(data)
        questionnaire.title = data.title
      } else {
        isShowEditorModal.value = false
      }

    }

    changeOption(questionnaire)

    return {
      atitle,
      adescription,
      questionnaire,
      editorRef,
      isShowEditorModal, text, handleEditorModel, changeEditor,
      updateEditor
    }
  },
})
</script>

<style scoped lang="less">
// 电脑端
.title {
  padding: 40px 20px;
  margin-bottom: 25px;
  background-color: #fff;
  border-bottom: 1px solid #E1E1E8;
  border-radius: 3px;
  .read {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-top: 10px;
    div {
      display: block;
      border: none;
      text-align: center;
      font-size: 26px;
      font-weight: bold;
      line-height: 40px;
      padding: 0;
      &:nth-child(2) {
        text-align: left;
        font-size: 16px;
        font-weight: normal;
        line-height: 26px;
        margin-top: 32px;
        color: #7F7F88;
      }
    }
  }
}

.edit {
  position: relative;
  min-height: 172px;
  background-color: #fff;
  border-bottom: 1px solid #E1E1E8;
  border-radius: 3px;
  margin-bottom: 24px;
  .title-input {
    width: 1026px;
    padding-top: 50px;
    margin: 0 auto;
    .edit-title {
      text-align: center;
      font-size: 34px;
      padding: 0;
    }
    .edit-placeholder {
      text-align: center;
      font-size: 16px;
      color: #E1E1E8;
    }
  }
  .description {
    display: flex;
    margin-top: 25px;
    .rich-title {
      flex: 1;
      line-height: 20px;
      margin: 0 50px 23px 50px;
      color: #7F7F88;
      word-break: break-all;
    }
    .description-placeholder {
      font-size: 16px;
      color: #E1E1E8;
      margin-left: 50px;
    }
  }
  .editor-icon {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 64px;
    right: 49px;
    text-align: center;
    margin: 0 auto;
    font-size: 20px;
    color: #3FB8EF;
    font-weight: bold;
    cursor: pointer;
    border: 2px solid #3FB8EF;
    border-radius: 2px;
  }
}
// 手机端
.mobile-title {
  padding: 40px 20px;
  background-color: #fff;
  border-bottom: 1px solid #E1E1E8;
  .read {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-top: 10px;
    div {
      display: block;
      border: none;
      text-align: center;
      font-size: 26px;
      font-weight: bold;
      line-height: 40px;
      padding: 0;
      &:nth-child(2) {
        text-align: left;
        font-size: 16px;
        font-weight: normal;
        line-height: 26px;
        margin-top: 32px;
        color: #7F7F88;
      }
    }
  }
}
</style>
