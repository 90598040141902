/*
 * @Description: 矩阵批量更新
 * @version: 
 * @Author: HWL
 * @Date: 2021-05-31 10:03:39
 * @LastEditors: HWL
 * @LastEditTime: 2021-06-02 14:54:40
 */
import { ref } from 'vue'

/**
 * 
 * @param {question} question 问题
 * @param {string} type 类型
 * @param {boolean} isShowBatchAddModal 显示批量更新框
 * @param {string} allOptionsText 选项文本
 * @returns object
 */
function MatrixBatchUpdateEffect(question, type = 'single', isShowBatchAddModal = false, allOptionsText = '') {
  isShowBatchAddModal = ref(isShowBatchAddModal)
  allOptionsText = ref(allOptionsText)
  let curType
  /**
   * 打开批量新增弹出框
   * @param {string} type 类型
   */
  function openBatchAddModal(type) {
    curType = type
    allOptionsText.value = question[type].map(item => { return item.text}).join('\n')
    isShowBatchAddModal.value = true
  }

  /**
   * 处理批量更新
   * @param {array} textarea 选项数组
   * @returns null
   */
  function handleBatchUpdate(textarea) {
    const choices = question[curType]
    isShowBatchAddModal.value = false
    // 关闭按钮
    if (textarea === undefined) {
      return 
    // 有文本，更新或新增或删除选项
    } else if (textarea) {
      for (let i = 0; i < textarea.length; i++) {
        if (choices[i]) {
          choices[i].text = textarea[i].trim()
        } else {
          let newChoice = {}
          if (curType == 'rowItems') {
            newChoice = {
              'text': textarea[i].trim(),
              'description': ''
            }
          } else {
            if (type === 'single') {
              newChoice = {
                'text': textarea[i].trim(),
                'value': 1,
                'ifIgnore': false
              }
            } else {
              newChoice = {
                'text': textarea[i].trim(),
              }
            }
          }
          choices.push(newChoice)
        }
      }
      question[curType] = choices.slice(0, textarea.length) // 删除批量里没有的项
    // 无文本，清空选项
    } else {
      choices.length = 0
    }
  }

  return {
    isShowBatchAddModal,
    allOptionsText,
    openBatchAddModal,
    handleBatchUpdate
  }
}

export default MatrixBatchUpdateEffect
