<!--
 * @Description: 题目显示设置
 * @version: 
 * @Author: PSG
 * @Date: 2021-04-21 14:03:37
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-29 14:39:30
-->
<template>
  <div class="question-show-tab">
    <div class="rich-text">
      <p>当前题目为：</p>
      <RichTitle class="rich-title" :title="question.title">
        <span class="title-tips">({{questionType2Text[question.questionType]}})</span>
      </RichTitle>
    </div>
    <div class="desc">
      <span>符合以下</span>
      <a-select
        class="desc-select"
        label-in-value
        :bordered="false"
        v-model:value="condition"
      >
        <a-select-option :value="1" >全部</a-select-option>
        <a-select-option :value="2">任一</a-select-option>
      </a-select>
      <span>条件（题目）时，显示当前问题。</span>
      
    </div>
    <div class="body">
      <Condition  
        v-for="(item, cindex) in list"
        :key="item.sort.key"
        :index="cindex"
        :item="item"
        :beforeQuestList="beforeQuestList"
        :alreadySelectQuestSortList="alreadySelectQuestSortList"
        @delete="handleDelete"
        @condQuestSelectChange="handleCondQuestSelectChange"
      />
      <!-- <Condition/> -->
      <div class="add">
        <div class="select-box" v-if="isShowSelect">
          <span class="condition-sort">{{list.length + 1}}.&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <a-select
            label-in-value 
            style="width: 400px;border-bottom: 1px solid #d7d7d7;" 
            @change="handleChange"
            :bordered="false"
            v-model:value="selectRefVal"
          >
            <a-select-option :value="-1" >请选择题目</a-select-option>
            <a-select-option 
              v-for="item in enableChooseQuestionList"
              :key="item.questionId"
              :value="item.questionId"  
              :question="item"
            >
              <div class="item" style="display: flex">
                <p>{{item.sort + 1}}、</p>
                <RichTitle class="rich-title" :title="item.title" />
                <span class="title-tips">({{questionType2Text[item.questionType]}})</span>
              </div>
            </a-select-option>
          </a-select>
          <span class="iconfont close" @click="handleDeleteClick">&#xe61c;</span>
        </div>
        <div class="add-btn" @click="handleAddClick">+</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect, inject } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { message } from 'ant-design-vue'
import { questionType2Text } from '@/utils/enum.js'
import useParseExpEffect from '@/effects/parseExpEffect'
import globalQuestionnaire from '@/class/useQuestionnaire'
import RichTitle from '../../question/components/richTitle'

import Condition from './componnets/Condition'

/**
 * 根据原题数据结构 构建 条件题目数据结构
 */
const buildCondQuestFromOrigQuest = (OrigQuestion) => {
  const { buildSingleOrMultiple, buildMatrix } = useParseExpEffect()
  let condQuestion = null
  if (/^(SINGLE_CHOICE|MULTIPLE_CHOICE)$/.test(OrigQuestion.questionType)) {
    condQuestion = buildSingleOrMultiple(OrigQuestion, [], 2)
  } else {
    condQuestion = buildMatrix(OrigQuestion, -1, [])
  }
  return condQuestion
}

/**
 * 获取本题前面的条件题目 （只获取单选、多选】、矩阵量表、矩阵单选题）
 */
const getBeforeQuestList = (question) => {
  const store = useStore()
  const curSort = question.sort
  const questionnaire = globalQuestionnaire.getQuestionnaire()
  const questions = JSON.parse(JSON.stringify(questionnaire.questions))
  const beforeQuestList = questions
    .filter(item => /^(SINGLE_CHOICE|MULTIPLE_CHOICE|MATRIX_SCALE|MATRIX_SINGLE_CHOICE)$/.test(item.questionType)
                    && item.sort < curSort)
  return {
    beforeQuestList
  }
}

/**
 * 获取已经关联过的题目的序号
 */
const getAlreadySelectQuestSortList = (list) => {
  const alreadySelectQuestSortList = ref([])
  watchEffect(() => {
    alreadySelectQuestSortList.value = []
    list.forEach(question => {
      alreadySelectQuestSortList.value.push(question.sort.key)
    })
  })
  return {
    alreadySelectQuestSortList
  }
}

/**
 * 获取可供选择的条件题目
 */
const getEnableChooseQuestionList = (beforeQuestList, alreadySelectQuestSortList) => {
  const enableChooseQuestionList = ref([])
  watchEffect(() => {
    enableChooseQuestionList.value = []
    beforeQuestList.forEach(item => {
      if (!alreadySelectQuestSortList.value.includes(item.sort)) {
        enableChooseQuestionList.value.push(item)
      }
    })
  })
  return {
    enableChooseQuestionList
  }
}

/**
 * 添加事件
 */
const addEffect = (list, enableChooseQuestionList) => {
  const isShowSelect = ref(false)
  const selectRefVal = ref({key: -1})

  if (enableChooseQuestionList.value.length !== 0) {
    isShowSelect.value = true
  }

  const handleAddClick = () => {
    // 判断是否还存在条件题目可选范围
    if (enableChooseQuestionList.value.length === 0) {
      message.warning('本题目前置条件已选完。')
      return
    }
    // 重置
    selectRefVal.value.value = -1
    selectRefVal.value.key = -1
    if (selectRefVal.value.label) { // 这里是为了让下拉选项重置“到请选择题目”
      delete selectRefVal.value.label
    }
    // 显示
    isShowSelect.value = true
  }
  const handleChange = (value, selectedObj) => {
    const questionId = selectedObj.value 
    if (questionId === -1) return
    const logicQuestion = buildCondQuestFromOrigQuest(selectedObj.question)
    list.push(logicQuestion)
    isShowSelect.value = false
  }
  const handleDeleteClick = () => {
    isShowSelect.value = false
  }
  
  return {
    isShowSelect,
    selectRefVal,
    handleAddClick,
    handleChange,
    handleDeleteClick,
  }
}

/**
 * 处理订阅相关事件
 */
const handleSubscriptionEffect = (list, enableChooseQuestionList) => {
  const questionnaire = globalQuestionnaire.getQuestionnaire()
  // 删除题目关联
  const handleDelete = (index) => {
    list.splice(index, 1)
  }
  // 更换题目关联
  const handleCondQuestSelectChange = (curQuestIndex, targeQuestId) => {
    const targeQuestion = enableChooseQuestionList.value.filter(item => item.questionId === targeQuestId)[0]
    const logicQuestion = buildCondQuestFromOrigQuest(targeQuestion)
    list.splice(curQuestIndex, 1, logicQuestion)
  }
  return {
    handleDelete,
    handleCondQuestSelectChange,
  }
}

export default {
  name: 'QuestionShowTab',
  components: {
    Condition,
    RichTitle
  },
  setup () {
    const question = inject('question')
    const { parseQuestionLogicExp } = useParseExpEffect()
    const { condition, list } = parseQuestionLogicExp(question)
    const { alreadySelectQuestSortList } = getAlreadySelectQuestSortList(list)
    const { beforeQuestList } = getBeforeQuestList(question)
    const { enableChooseQuestionList } = getEnableChooseQuestionList(beforeQuestList, alreadySelectQuestSortList)
    const { isShowSelect, selectRefVal, handleAddClick, handleChange, handleDeleteClick } = addEffect(list, enableChooseQuestionList)
    const { handleDelete, handleCondQuestSelectChange } = handleSubscriptionEffect(list, enableChooseQuestionList)

    return {
      question,
      condition,
      beforeQuestList,
      list,
      alreadySelectQuestSortList,
      enableChooseQuestionList,
      isShowSelect,
      selectRefVal,
      handleAddClick,
      handleChange,
      handleDelete,
      handleDeleteClick,
      handleCondQuestSelectChange,
      questionType2Text,
    }
  }
}
</script>

<style lang="less" scope>
.title-tips {
  word-break: keep-all;
  margin-left: 8px;
  font-weight: normal;
}
.question-show-tab {
  box-sizing: border-box;
  padding: 20px 20px 0 20px!important;
  .rich-text {
    display: flex;
    margin-bottom: 20px;
    font-weight: bold;
    >p {
      flex: 0 0 100px;
    }
    .rich-title {
      flex: auto;
      >p {
        display: inline;
      }
    }
  }
  .desc {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 100;
    line-height: 41px;
    .desc-select {
      width: 100px;
      height: 41px;
      text-align: center;
      border: 1px solid #E1E1E8;
      margin: 0 8px;
      .ant-select-selector {
        height: 41px;
        border-radius: 0px;
        .ant-select-selection-item {
          line-height: 41px;
        }
      }
    }
  }
  .body {
    max-height: 480px;
    overflow: auto;
    .add {
      margin-top: 16px;
      .select-box {
        position: relative;;
        .condition-sort {
          font-size: 16px;
          display: inline-block;
          // vertical-align:bottom;
        }
        .close {
          position: absolute;
          top: 6px;
          right: 16px;
          font-size: 16px;
          cursor: pointer;
        }

      }
      .add-btn {
        margin: 10px auto;
        margin-top: 40px;
        width: 48px;
        height: 48px;
        font-size: 36px;
        line-height: 48px;
        text-align: center;
        border: 1px dashed transparent;
        color: #E1E1E8;
        background: linear-gradient(white,white) padding-box,
        repeating-linear-gradient(-45deg,#ccc 0, #ccc 0.25em,white 0,white 0.55em);
        .pointer();
      }
    }
  }
}
</style>
<style lang="less">
.question-show-tab {
  .ant-select-selector {
    padding-left: 0!important;
  }
}
</style>