/*
 * @Description: 题库、选项库api
 * @version: 
 * @Author: PSG
 * @Date: 2021-07-06 06:56:58
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-12 09:54:21
 */
import service from '@/utils/request.js'

/**
 * 获取题库类型
 */
 export function getQLibraryType() {
  return service.request({
    url: `config/questions/relations`,
    method: 'get'
  })
}

/**
 * 获取题目列表
 * @param {string} tagId 题目类型标签id
 * @returns 
 */
export function getQuestionListByTypeId(tagId) {
  return service.request({
    url: `/config/questions/detail?tagId=${tagId}`,
    method: 'get'
  })
}

/**
 * 获取题库所有信息（包括分类以及分类下的题目信息）
 */
export function getAllQuestionsWithCategory() {
  return service.request({
    url: `/config/questions/all-data`,
    method: 'get'
  })
}

/**
 * 获取选项库类型
 */
 export function getCLibraryCategory() {
  return service.request({
    url: `/config/options/relations`,
    method: 'get'
  })
}

/**
 * 根据id获取选项列表
 * @param {string} 选项类型id
 * @param {Array} 选项列表
 */
export function getChoiceListById(id) {
  return service.request({
    url: `/config/options/detail?optionsLibId=${id}` 
  })
}