<!--
 * @Description: 问卷填写
 * @version: 
 * @Author: PSG
 * @Date: 2021-04-06 18:54:04
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-26 10:47:33
-->
<template>
<div class="exercise">
  <div :class="[isPc ? 'content' : 'mobile-content']">
    <Title 
      v-if="curPage===0"
      :title="questionnaire.title" 
      :description="questionnaire.description"
      :isOnlyRead="true"
      :isPc="isPc"
    />
    <template v-for="(item, index) in questionnaire.questions" v-if="refresh">
      <!-- 题目主体 -->
      <div :id="item.questionId" :class="[isPc ? 'question-wrapper' : '']" v-show="item.visible && item.page === curPage">
        <component
          :is="item.componentName"
          :item="item"
          :isOnlyRead="true"
          :key="item.questionId"
          :isPc="isPc"
        />
      </div>
    </template>
  </div>
  <QuestionnaireBottom
    :isPc="isPc"
    :curPage="curPage"
    :maxPage="maxPage"
    :isLastPage="isLastPage"
    @submit="handleSubmitClick"
    @next="handleNextClick"
  />
  <ConfirmModal
    title="您之前已经回答了部分题目，您可以选择"
    okText="继续作答"
    noText="重新作答"
    :visible="modalIsVisible"
    @modal-on-close="modalIsVisible = false"
    @modal-on-confirm="continueExercise"
  />
</div>
</template>

<script>
import Header from '@/components/common/Header'
import Title from '@/components/question/others/Title'
import Page from '@/components/question/others/Page'
import Part from '@/components/question/others/Part'
import SingleChoice from '@/components/question/SingleChoice'
import MultipleChoice from '@/components/question/MultipleChoice'
import DropDown from '@/components/question/DropDown'
import Scale from '@/components/question/Scale'
import MatrixScale from '@/components/question/MatrixScale'
import MatrixSingleChoice from '@/components/question/MatrixSingleChoice'
import MatrixMultipleChoice from '@/components/question/MatrixMultipleChoice'
import Short from '@/components/question/Short'
import Date from '@/components/question/Date'
import ConfirmModal from '@/views/exercise/components/ConfirmModal'


import globalQuestionnaire from '@/class/useQuestionnaire'
import useCommonConstructEffect from '@/effects/constructEffect'
import logicWatcherEffect from '@/effects/logicWatcherEffect'
import { useRoute, useRouter } from 'vue-router'
import { ref, onUnmounted, watch, nextTick } from 'vue'
import { message } from 'ant-design-vue'

import { getNowTime } from '@/utils/date.js'
import { getTerminalType } from '@/utils/userAgent.js'
import { deepCopy } from '@/utils/deepCopy.js'
import { submitAnswer } from '@/api/questionnaire'
import { getGeneralQuestionnaireById, getQuestionnaireInterrupt } from '@/api/questionnaire'
import QuestionnaireBottom from '@/components/question/QuestionnaireBottom'

/**
 * 获取问卷信息
 */
const getQuestionnaireEffect = () => {
  const route = useRoute()
  const router = useRouter()
  const id = route.params.id
  const questionnaire = globalQuestionnaire.getQuestionnaire()
  const isLastPage = globalQuestionnaire.getIsLastPage()
  const curPage = ref(0) // 当前页码
  const maxPage = ref(0) // 最大页码
  const lastDuration = ref(0) // 上一次作答时间
  const modalIsVisible = ref(false)
  const { constructExerciseQuestion } = useCommonConstructEffect()
  // 获取问卷信息
  const getQuestionnaireByAsync = async () => {
    const result = await getGeneralQuestionnaireById(id)
    if (result.status === 200 && result.data?.code === '00000' && result?.data?.data) {
      // 问卷失效
      if (!result.data.data.questionnaireProtoInfo.ifRelease) {
        router.push({
          path: '/Invalid',
          replace: true // 不允许后退
        })
      }
      console.log('请求回来的问卷数据[访客]：', result.data.data)
      let obj = result.data.data.questionnaireProtoInfo
      document.title = obj.title
      obj.questions = result.data.data.questions
      obj.questionnaireProtoSetting = result.data.data.questionnaireProtoSetting
      maxPage.value = Math.max(...Object.keys(obj.pageMap))
      globalQuestionnaire.setQuestionnaire(constructExerciseQuestion(obj))
      if (obj.questionnaireProtoSetting.allowResumeOnBreak) {
        const result2 = await getQuestionnaireInterrupt(id)
        console.log('请求回来的断点续答数据：', result2)
        if (result2.status === 200 && result2.data.code === '00000' && result2.data.data.answers){
          questionnaire.answers = result2.data.data.answers
          lastDuration.value = result2.data.data.duration
          modalIsVisible.value = true
          // Modal.confirm({
          //   title: '是否继续上一次作答?',
          //   icon: createVNode(ExclamationCircleOutlined),
          //   // content: 'When clicked the OK button, this dialog will be closed after 1 second',
          //   onOk() {
          //     obj.answers = result2.data.data.answers
          //     continueExercise(obj)
          //   }
          // })
        }
      }
    } else {
      message.error(' 请求答卷失败，失败原因：' + result.data.msg)
    }
  }
  getQuestionnaireByAsync()
  return {
    questionnaire,
    isLastPage,
    curPage,
    maxPage,
    lastDuration,
    modalIsVisible,
  }
}

/**
 * 按钮点击事件
 */
const handleBtnClickEffect = (questionnaire, curPage, startTime, lastDuration) => {
  const router = useRouter()
  
  watch(() => curPage.value, (cur, pre) => {
    // 查看本页后还是否存在可显示的数据
    const list = questionnaire.questions.filter(question => question.page > cur).filter(question => question.visible)
    const bool = list.length === 0 ? true : false
    globalQuestionnaire.setIsLastPage(bool)

  })

  // 答案校验
  const checkData = () => {
    const warnTips = []
    const warnQuestionIds = []
    const questions = questionnaire.questions.filter(question => question.page === curPage.value && question.visible && question.ifRequired)
    // 1、校验答案的完整性
    questions.forEach(question => {
      // 校验 单选题、多选题、下拉题、量表题
      if (/^(SINGLE_CHOICE|MULTIPLE_CHOICE|DROP_DOWN|SCALE)$/.test(question.questionType)) {
        if (!question.answer || !question.answer.questionId || (question.answer.choices && question.answer.choices.length === 0)) {
          warnTips.push(`请填写第${question.sort + 1}题`)
          warnQuestionIds.push(question.questionId) 
        } 
        // 校验单选、多选必须填的必填填空是否填写
        else if (/^(SINGLE_CHOICE|MULTIPLE_CHOICE)$/.test(question.questionType)) {
          // 是否存在勾选的选项至少有一个是必填的但是没有填空的情况
          let flag = question.answer.choices.some(answerChoiceData => {
            let originChoiceData = question.choices.find(item => item.choiceId === answerChoiceData.choiceId) 
            if (originChoiceData.ifRequired && !answerChoiceData.fillText) return true
            return false
          })
          if (flag) {
            warnTips.push(`请填写第${question.sort + 1}题`)
            warnQuestionIds.push(question.questionId) 
          } 
        }

      }
      // 校验 矩阵量表、矩阵单选、矩阵多选
      else if (/^(MATRIX_SCALE|MATRIX_SINGLE_CHOICE|MATRIX_MULTIPLE_CHOICE)$/.test(question.questionType)) {
        if (!question.answer || !question.answer.tableCheck) {
          warnTips.push(`请填写第${question.sort + 1}题`)
          warnQuestionIds.push(question.questionId)
        }
        else {
          // 是否存在有一行全为false
          const visibileRowsIndexs = []
          question.rowItems.forEach((rowItem, index) => {
            rowItem.visible ? visibileRowsIndexs.push(index) : null
          })
          if (Object.values(question.answer.tableCheck)
                .filter((rowAnswerObj,index) => visibileRowsIndexs.indexOf(index) >= 0)
                .some((rowAnswerObj, index) => Object.values(rowAnswerObj).every(colAnswer => colAnswer === false)
          )) {
            warnTips.push(`请填写第${question.sort + 1}题`)
            warnQuestionIds.push(question.questionId)
          }
        }
      }
      // 校验 简答题、日期题
      else if (/^(SHORT|DATE)$/.test(question.questionType)) {
        if (!question.answer || !question.answer.fillText) {
          warnTips.push(`请填写第${question.sort + 1}题`)
          warnQuestionIds.push(question.questionId)
        }
      }
    })
    // 2、校验多选、矩阵多选题至少选择的选项
    questions.filter(question => /^(MULTIPLE_CHOICE|MATRIX_MULTIPLE_CHOICE)$/.test(question.questionType)).forEach(question => {
      if (question.questionType === 'MULTIPLE_CHOICE' 
      && question.minSelect 
      && question.answer 
      && question.answer.questionId
      && question.answer.choices.length < question.minSelect) {
        warnTips.push(`第${question.sort + 1}题至少选${question.minSelect}项`)
        warnQuestionIds.push(question.questionId)
      } else if (question.questionType === 'MATRIX_MULTIPLE_CHOICE' 
      && question.minSelect 
      && question.answer 
      && question.answer.tableCheck) {
        // 是否存在有一行全为false
        if (!Object.values(question.answer.tableCheck).some(rowAnswerObj => 
          Object.values(rowAnswerObj).every(colAnswer => colAnswer === false))) {
            // 进到这里，说明用户每一行都已勾选 
            Object.values(question.answer.tableCheck).forEach((rowAnswerObj, rowIndex) => {
              const trueNum = Object.values(rowAnswerObj).reduce((trueNum, colAnswer)=> {
                colAnswer ? ++trueNum : null
                return trueNum
              }, 0)
              if (trueNum < question.minSelect ) {
                warnTips.push(`第${question.sort + 1}题第${rowIndex + 1}行至少选${question.minSelect}项`)
                warnQuestionIds.push(question.questionId)
              }
            })
          }
      }
    })
      
    return {
      warnTips: warnTips.join('\n'),
      warnQuestionIds: warnQuestionIds
    }
  }

  // 答案完整性校验
  const checkAnswer = (questionType, answer) => {
    let bool = true
    if (/^(MATRIX_SCALE|MATRIX_SINGLE_CHOICE|MATRIX_MULTIPLE_CHOICE)$/.test(questionType)) {
      // 是否存在有一行全为false
      bool = Object.values(answer.tableCheck).some(rowAnswerObj => 
        Object.values(rowAnswerObj).every(colAnswer => colAnswer === false)
      ) ? false : true
    }
    return bool
  }

  // 校验不通过处理
  const handleWarnTips = (warnTips, warnQuestionIds) => {
    message.error(warnTips)
    warnQuestionIds.forEach(questionId => {
      document.getElementById(questionId).classList.add('error')
    })
    document.getElementById(warnQuestionIds[0]).scrollIntoView()
  }

  // 下一页
  const handleNextClick = () => {
    const { warnTips, warnQuestionIds } = checkData()
    if (warnTips !== '') {
      handleWarnTips(warnTips, warnQuestionIds)
      return
    }
    let nextPageQuestion = getNextPageQuestion(curPage)
    let topClassName = getTerminalType() === 'PC' ? 'content' : 'mobile-content'
    document.getElementsByClassName(topClassName)[0].scrollIntoView()
    // 递归查找下一页的数据，且更新curPage
    function getNextPageQuestion (curPage) {
      curPage.value += 1
      let nextPage = curPage.value
      let maxPage = Math.max(...Object.keys(questionnaire.pageMap))
      let nextPageQuestions = questionnaire.questions.filter(question => (!(/^PART$/.test(question.questionType)) && question.page === nextPage && question.visible))
      console.log('questionnaire', questionnaire)
      console.log('nextPage：', nextPage)
      console.log('nextPageQuestions', nextPageQuestions)
      if (nextPageQuestions.length === 0) {
        if (maxPage === curPage.value) { 
          return [] 
        }
        return getNextPageQuestion(curPage)
      } else {
        return nextPageQuestions
      }
    }
  }

  // 提交
  const handleSubmitClick = () => {
    const endTime = getNowTime()
    const data = {
      'questionnaireProtoId': questionnaire.id,
      'collectSource': getTerminalType(),
      'duration': Math.floor(lastDuration.value + (endTime - startTime.value) / 1000),
      'answers': []
    }
    for (let i = 0; i < questionnaire.questions.length; i++) {
      let curQuestion = questionnaire.questions[i]
      if (!/PAGE|PART/.test(curQuestion.questionType) && curQuestion.answer && curQuestion.answer.questionId && curQuestion.visible) {
        // 非必答题，校验答案的完整性，答案完整，则保存答案，否则不保存答案
        if (!curQuestion.ifRequired) {
          checkAnswer(curQuestion.questionType, curQuestion.answer) ? data.answers.push(curQuestion.answer) : null
          continue
        }
        data.answers.push(curQuestion.answer)
      }
    }
    const { warnTips, warnQuestionIds } = checkData()
    if (warnTips !== '') {
      handleWarnTips(warnTips, warnQuestionIds)
      return
    }
    submitAnswerEvent(data)

    async function submitAnswerEvent (data) {
      const res = await submitAnswer(data)
      if (res.status === 200 && res.data?.code === '00000') {
        router.push({
          path: '/success',
          replace: true, // 不允许后退
          query: {
            concludingRemarks: questionnaire.questionnaireProtoSetting?.concludingRemarks || '感谢您的参与！'
          }
        })
        // message.success('上传答案成功')
        
      } else {
        message.error('上传答案失败，失败原因：' + res.data.msg)
      }
    }
  }
  return {
    curPage,
    handleNextClick,
    handleSubmitClick,
  }
}

/**
 * 断点续答
 */
const continueExerciseEffect = (curPage, startTime, modalIsVisible) => {
  const refresh = ref(true)
  const questionnaire = globalQuestionnaire.getQuestionnaire()
  const { constructExerciseQuestionWithAnswer } = useCommonConstructEffect()
  const { watchVisibilityEffect } = logicWatcherEffect()
  const continueExercise = () => {
    // 1、重设开始时间
    startTime.value = getNowTime()
    // 2、隐藏模态窗
    modalIsVisible.value = false
    // 3、构建答卷内容
    const newQuestionnaire = deepCopy(questionnaire)
    globalQuestionnaire.setQuestionnaire(constructExerciseQuestionWithAnswer(newQuestionnaire))
    // 4、获取最后一道带答案的题目
    let lastQuestion = deepCopy(questionnaire.questions).reverse().find(question => question.answer && question.answer.questionId)
    // 5、设置当前页
    curPage.value = lastQuestion.page
    // 6、走一遍题目逻辑
    watchVisibilityEffect(lastQuestion)
    // 7、子组件刷新数据
    refresh.value = false
    nextTick(() => { // 这里使用nextTick的原因是，父组件传子给子组件，子组件能及时刷新数据
      refresh.value = true
    })
  }
  return {
    refresh,
    continueExercise,
  }
}

/**
 * 页面退出保留答案
 */
const afterQuitEffect = (startTime) => {
  const questionnaire = globalQuestionnaire.getQuestionnaire()
  const quitEvent = () => {
    const url = process.env.VUE_APP_BASE_URL + '/client/questionnaire-proto/interrupt'
    const endTime = getNowTime()
    const data = {
      'questionnaireProtoId': questionnaire.id,
      'duration': Math.floor((endTime - startTime.value) / 1000),
      'answers': []
    }
    questionnaire.questions
      .filter(question => 
        !/(PAGE|PART)$/.test(question.questionType) 
        && question.answer 
        && question.answer.questionId).forEach(question => {
          data.answers.push(question.answer)
        })
    if (data.answers.length > 0) {
      let headers = {
        type: 'application/json'
      }
      let blob = new Blob([JSON.stringify(data)], headers);
      const res = navigator.sendBeacon(url, blob)
      console.log('使用sendBeacon发送请求', res)
    }
  }
  return {quitEvent}
}

export default {
  name: 'Exercise',
  components: { 
    Header,
    
    Title,
    Page,
    Part,
    SingleChoice,
    MultipleChoice,
    DropDown,
    Scale,
    MatrixScale,
    MatrixSingleChoice,
    MatrixMultipleChoice,
    Short,
    Date,
    ConfirmModal,
    QuestionnaireBottom
  },
  setup () {
    const startTime = ref(getNowTime())
    const isPc = getTerminalType() === 'PC' ? true : false
    const { questionnaire, isLastPage, curPage, maxPage, lastDuration, modalIsVisible, } = getQuestionnaireEffect()
    const { handleNextClick, handleSubmitClick, } = handleBtnClickEffect(questionnaire, curPage, startTime, lastDuration)
    const { refresh, continueExercise } = continueExerciseEffect(curPage, startTime, modalIsVisible)
    const { quitEvent } = afterQuitEffect(startTime)
    onUnmounted(() => {
      globalQuestionnaire.clearQuestionnaire()
      globalQuestionnaire.setIsLastPage(false)
    })
    window.onbeforeunload = quitEvent
    window.unload = quitEvent
    return {
      isPc,
      curPage,
      maxPage,
      isLastPage,
      modalIsVisible,
      refresh,
      continueExercise,
      questionnaire,
      handleNextClick,
      handleSubmitClick,
    }
  }
}
</script>

<style scoped lang='less'>
.exercise {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: @backgroundColor;
}
.error {
  border: 1px solid red;
  margin-bottom: 10px;
}
// 电脑端
.content {
  width: 1100px;
  margin: 20px auto;
  background: @backgroundColor;
  border-radius: 10px;
  padding: 0 40px;
}
.bottom {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items:center;    
  .button {
    width: 76px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    color: #fff;
    background: #f59a23;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      background-color: #f59a23d7;
    }
    &:nth-child(2) {
      margin-left: 10px;
    }
  }
}

// 手机端
.mobile-content {
  border: 1px solid #fff;
  padding-bottom: 40px;
  width: 100%;
  background: #fff;
}
.mobile-bottom {
  padding-bottom: 60px;
  background: #fff;
  .button {
    width: 220px;
    height: 50px;
    line-height: 50px;
    font-size: 24px;
    text-align: center;
    color: #fff;
    background-color: #00a0e9;
    margin: 0 auto;
  }
  .info {
    margin-top: 20px;
    text-align: center;
    color: #7F7F88;
    font-weight: 300;
  }
}
</style>