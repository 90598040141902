<!--
 * @Description: 富文本编辑器
 * @version: 
 * @Author: HWL
 * @Date: 2021-06-03 11:52:50
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-09 17:28:43
-->
<template>
  <div class="editor">
    <a-modal 
      v-model:visible="visible" 
      :title="title" 
      @ok="handleOk"
      class="editorModal"
      :closable="false"
      @cancel="handleClose"
    >
      <div ref="toolbarRef" class="toolbar" />
      <div ref="editorRef" class="text" />
      <span class="iconfont close" @click="handleClose">&#xe61f;</span>
    </a-modal>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import E from 'wangeditor'
import { nextTick, watch } from '@vue/runtime-core'
import watchPropsEffect from './effect/watchPropsEffect'

export default {
  name: 'EditorModal',
  props: [
    'visible',  // 控制显示隐藏
    'title', // 模态窗标题
    'text', // 输入框内容
  ],
  setup(props, { emit }) {
    const { visible, title, text } = watchPropsEffect(props)
    const toolbarRef = ref(null)
    const editorRef = ref(null)
    let editor
    let txt

    const handleOk = () => {
      emit('finishEditText', {
        title: text.value,
        txt,
      })
    }
    const handleClose = () => {
      emit('finishEditText', {
        title: text.value,
        txt,
      })
    }

    // 过滤br标签
    const filterChildren = (children) => {
      for (let index = 0; index < children.length; index++) {
        const child = children[index]
        if (typeof child === 'string') {
          if (child.indexOf('<br/>') > -1) {
            children[index] = {
              tag: 'br',
              attrs: [],
              children: []
            }
          }
        } else {
          filterChildren(child.children)
        }
      }
    }

    const updateText = (text) => {
      if (editor) {
        if (typeof text === 'string') {
          editor.txt.html(text)
        } else {
          editor.txt.setJSON(text)
        }
      }
    }

    watch(visible, (newValue, oldValue) => {
      if (!editor && newValue) {
        nextTick(() => {
          editor = new E(toolbarRef.value, editorRef.value)
          // 菜单配置
          editor.config.menus = [
            'bold', // 粗体
            'fontSize', // 字号
            'fontName', // 字体
            'italic', // 斜体
            'foreColor', // 文字颜色
            'justify', // 对齐方式
          ]

          // 配置字体
          editor.config.fontNames = [
            '黑体',
            '仿宋',
            '楷体',
            '标楷体',
            '宋体',
            'Arial',
            'Tahoma',
            'Verdana',
            'Times New Roman',
            'Courier New',
          ]

          editor.config.onchange = function (newHtml) {
            const json = editor.txt.getJSON()
            txt = editor.txt.text()
            filterChildren(json)
            text.value = json
          }

          editor.config.showFullScreen = false

          editor.create()

          updateText(props.text)
        })
      }
    })

    return {
      toolbarRef,
      editorRef,
      visible,
      title,
      text,
      handleOk,
      handleClose,
      updateText
    }
  }
}
</script>
<style lang="less" scoped>
.editorModal {
  ::selection {
    background: #fff!important;
  }
  .toolbar {
    border: 1px solid #ccc;
  }
  .text {
    border: 1px solid #ccc;
    min-height: 500px;
    :deep(i) {
      font-style: italic;
    }
  }
  .ant-modal-content {
    position: relative;
    border: 16px solid #eff2f5;
    border-radius: 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    .ant-modal-header {
      border: none;
      .ant-modal-title {
        font-size: 20px;
      }
    }
    .ant-modal-body {
      .close {
        position: absolute;
        top: 8px;
        right: 24px;
        font-size: 20px;
        .pointer()
      }
      .ant-input-affix-wrapper {
        border: none;
        svg {
          font-size: 18px;
        }
      }
      input {
        border: none;
        border-bottom: 1px solid #d7d7d7;
      }
    }
    .ant-modal-footer {
      border: none;
      div {
        text-align: center;
        button {
          background-color: #f59a23;
          border: none;
          color: #fff;
          &:nth-child(1) {
            display: none;
          }
        }
      }
    }
  }
  .ant-modal-footer {
    border: none;
    div {
      text-align: center;
      button {
        background-color: #f59a23;
        border: none;
        color: #fff;
        &:nth-child(1) {
          display: none;
        }
    }
    }
  }
}
</style>