<!--
 * @Description: 矩阵量表题
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-18 18:59:07
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-27 11:08:33
-->
<template>
  <!-- 电脑端 -->
  <div class="matrix_scale" v-if="isPc"
    :class="{noborder: !isSettingsActive}" 
  >
    <div class="sort-icon">
      <div class="preview-sort iconfont" v-if="!isOnlyRead">&#xe697;</div>
    </div>
    <div 
      class="preview-del iconfont" 
      @click.stop="handleDelete" 
      v-if="!isOnlyRead && !route.query.isKeepAnswerAndEdit"
    >&#xe609;</div>
    <div 
      class="preview-copy iconfont" 
      @click.stop="handleCopy" 
      v-if="!isOnlyRead && !route.query.isKeepAnswerAndEdit"
    >&#xe62f;</div>
    <div class="preview" @click="edit(question.questionId)">
      <PreviewTitle :isOnlyRead="isOnlyRead" :question="question" :isPc="isPc"/>
      <div class="preview-options">
        <div class="colunm">
          <span :style="`width:${rowTitleWidth}rem;`"></span>
          <span
            v-for="(item, index) in question.columnItems"
            :key="index"
            :style="`width:${rowWidthList[index]}rem`"
          >{{item.text}}</span>
        </div>
        <div class="colunm" v-if="question.ifShowValue">
          <span :style="`width:${rowTitleWidth}rem`">分值</span>
          <span
            v-for="(item, index) in question.columnItems"
            :key="index"
            :style="`width:${rowWidthList[index]}rem`"
          >{{item.value}}</span>
        </div>
        <!-- 编辑时的行标题引用效果 -->
        <div class="row-wrapper" v-if="route.name==='QuestionEdit' && question.ref">
          <div class="row">
            <div class="row-content">
              <span class="row-title" :style="`width:${rowTitleWidth}rem;text-align:center`">......</span>
              <a-radio-group
                v-model:value="radioValue" 
              >
                <a-radio
                  v-for="(columnItem, columnItemIndex) in question.columnItems"
                  :value="columnItemIndex"
                  :key="columnItemIndex"
                  :style="`width:${rowWidthList[columnItemIndex]}rem`"
                ></a-radio>
              </a-radio-group>
            </div>
          </div>
        </div>
        <!-- 非编辑的行标题效果 -->
        <div class="row-wrapper" v-else>
          <template
            v-for="(rowItem, rowItemIndex) in question.rowItems"
            :key="rowItemIndex"  
          >
            <div class="row" v-show="!question.ref || rowItem.visible">
              <div class="row-content">
                <span class="row-title" :style="`width:${rowTitleWidth}rem`">{{rowItem.text}}</span>
                <a-radio-group
                  v-model:value="radioValue[rowItemIndex]" 
                  @change="fillAnswerIntime(question, radioValue)"
                  :disabled="isDisabled"
                >
                  <a-radio
                    v-for="(columnItem, columnItemIndex) in question.columnItems"
                    :value="columnItemIndex"
                    :key="columnItemIndex"
                    :style="`width:${rowWidthList[columnItemIndex]}rem`"
                  ></a-radio>
                </a-radio-group>
              </div>
              <div class="row-desc">{{rowItem.description}}</div>
            </div>
          </template>
        </div>
      </div>
      <div class="preview-tips">
        <div class="tips" v-if="item.isNoCondJump">逻辑设置：{{item.jumpLogicTips}}</div>
        <div class="tips" v-if="item.showLogicTips">逻辑设置：本题设置了题目显示逻辑：{{item.showLogicTips}}</div>
        <div class="tips" v-if="item.refTips && visibleRefTips">{{item.refTips}}</div>
      </div>
      <PreviewBottom v-if="isOnlyRead" />
    </div>
    <PreviewBottom v-if="!isOnlyRead" style="background: #fff"/>
    <setting
      v-if="isSettingsActive" 
      type="MATRIX_SCALE"
      :item="item"
      :index="index"
      :isOnlyRead="isOnlyRead"
      :question="question"
      @finished="finished"
    />
  </div>
  <!-- 手机端 -->
  <div class="mobile-matrix_scale" v-else>
    <div class="preview">
      <PreviewTitle :isOnlyRead="isOnlyRead" :question="question" :isPc="isPc"/>
      <div class="preview-options">
        <div class="colunm">
          <span
            v-for="(item, index) in question.columnItems"
            :key="index"
            :title="item.text"
          >{{item.text}}</span>
        </div>
        <template
          v-for="(rowItem, rowItemIndex) in question.rowItems"
          :key="rowItemIndex"
        >
          <div class="row" v-show="!question.ref || rowItem.visible">
            <div class="row-title">{{rowItem.text}}</div>
            <a-radio-group 
              v-model:value="radioValue[rowItemIndex]"
              @change="fillAnswerIntime(question, radioValue)"
              :disabled="isDisabled"
            >
              <a-radio 
                v-for="(columnItem, columnItemIndex) in question.columnItems"
                :key="columnItemIndex"
                :value="columnItemIndex"></a-radio>
            </a-radio-group>
          </div>
        </template>
      </div>
      <div class="preview-tips">
        <div class="tips" v-if="item.refTips && visibleRefTips">{{item.refTips}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, unref } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import useCommonQuestionnaireEffect from '@/effects/questionnaireEffect'
import logicWatcherEffect from '@/effects/logicWatcherEffect'
import Setting from './components/setting'
import PreviewTitle from './components/previewTitle'
import PreviewBottom from './components/previewBottom'
import rowWidthEffect from './components/effects/rowWidthEffect'

/**
 * 答案管理
 */
const answerEffect = () => {
  const route = useRoute()
  const { watchVisibilityEffect } = logicWatcherEffect()
  // 答案获取
  const getAnswer = (question, selectArr) => {
    const answer = {
      "questionType": question.questionType, 
      "questionId": question.questionId,
      "tableCheck": {}
    }
    const rowItems = question.refRowItems ? question.refRowItems : question.rowItems
    // 根据每一行选中的列答案，设为true，其他设为false
    // for (let i = 0; i < selectArr.length; i++) { // 有可能会出现selectArr.length < question.rowItems.length
    for (let i = 0; i < rowItems.length; i++) {
      answer.tableCheck[i]={}
      for (let j = 0; j < question.columnItems.length; j++) {
        answer.tableCheck[i][j] = selectArr[i] === j ? true : false
      }
    }
    return answer
  }

  // 答案填写
  // isAreadyAnswerFill，只需要初始化组件时执行一次即可
  const fillAnswer = (question, radioValue, isAreadyAnswerFill) => {
    if (route.name === 'RecycleBin') isAreadyAnswerFill.value = false // 这种是特殊情况，当在回收情况、查看问卷、点击上一份、下一份时，是允许重新填写答案并展示出来的
    if (!isAreadyAnswerFill.value) {
      isAreadyAnswerFill.value = true
      // 1、获取答案中每一行true的下标（即列下标）
      // 2、根据上面得出的行、列下标，赋值给radioValue即可（radioValue结构是[0,1,2]）
      //                                            (tableCheck的结构是{0:{0: true, 1: false}, {0: false, 1: true}.....})
      for (let rowIndex in question.answer.tableCheck) {
        const curRowAnswerObj = question.answer.tableCheck[rowIndex]
        for (let colIndex in curRowAnswerObj) {
          const curColAnswerObject = curRowAnswerObj[colIndex]
          if (curColAnswerObject === true) {
            radioValue.value[rowIndex] = Number(colIndex)
            break
          }
        }
      }
    }
  }

  // 实时填写答案
  const fillAnswerIntime = (question, value) => {  
    question.answer = getAnswer(question, value)
    watchVisibilityEffect(question)
    document.getElementById(question.questionId).classList.remove('error')
  }

  return {
    getAnswer,
    fillAnswer,
    fillAnswerIntime,
  }
}

export default {
  props: ['item', 'index', 'isOnlyRead', 'isDisabled', 'isPc'],
  components: {
    Setting,
    PreviewTitle,
    PreviewBottom,
  },
  name: 'MatrixScale',
  setup(props) {
    const store = useStore()
    const route = useRoute()
    const radioValue = ref([])
    const selectVal = ref({key: 'MATRIX_SCALE'})
    const isAreadyAnswerFill = ref(false)
    const { getAnswer, fillAnswer, fillAnswerIntime } = answerEffect()
    
    const question = computed({
      get: () => {
        if (!props.item.answer?.questionId) {
          props.item.answer = {}
          props.item.rowItems.forEach(item => item.visible = false)
        } else {
          fillAnswer(props.item, radioValue, isAreadyAnswerFill)
        }
        return props.item
      }
    })
    watch (() => store.state.curEditingQuestonId, (cur, pre) => {
      if (/^(Preview|Exercise)$/.test(route.name)) return
      if (cur === question.value.questionId) {
        isSettingsActive.value = props.isOnlyRead ? false : true
      } else {
        isSettingsActive.value = false
      }
    })

    const { handleEffect, questionEditStatusChangeEffect, visibileRowAndColEffect, visibileRefTipsEffect } = useCommonQuestionnaireEffect()
    const { isSettingsActive, edit } = questionEditStatusChangeEffect(props)
    const { handleDelete, handleCopy, handleChange } = handleEffect(props)
    const { visibileRowRefArea, visibileColTitle } = visibileRowAndColEffect(props.isOnlyRead, question.value)
    const { visibleRefTips } = visibileRefTipsEffect(question)
    const { rowWidthList, rowTitleWidth } = rowWidthEffect(props.item, unref(visibileRowRefArea))

    const finished = () => {
      isSettingsActive.value = false
    }


    edit(props.item.questionId)

    return {
      visibleRefTips,
      route,
      radioValue,
      selectVal,
      question,
      fillAnswerIntime,
      finished,
      edit,
      isSettingsActive,
      
      handleDelete,
      handleCopy,
      handleChange,

      visibileRowRefArea,
      visibileColTitle,
      rowWidthList,
      rowTitleWidth,
    }
  },
}
</script>

<style scoped lang="less">
// 电脑端
.matrix_scale {
  position: relative;
  background-color: @backgroundColor;
  border-bottom: 1px solid #E1E1E8;
  .iconfont:hover {
    background-color: @hoverBackground;
    cursor:pointer;
  }
  .preview-del {
    position: absolute;
    top: 47px;
    right: 93px;
    color: #00A0E9;
    font-size: 24px;
    z-index: 2;
  }
  .preview-copy {
    position: absolute;
    top: 47px;
    right: 49px;
    color: #00A0E9;
    font-size: 24px;
    z-index: 2;
  }
  .sort-icon {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 24px;
    margin-top: 10px;
    z-index: 2;
    .preview-sort {
      width: 24px;
      height: 24px;
      font-size: 24px;
      transform: rotate(90deg);
      color: #b7b7b7;
    }
  }
  .preview {
    position: relative;
    padding: 40px 32px 0px 32px;
    background-color: #fff;
    .preview-copy {
      position: absolute;
      top: 20px;
      right: 50px;
      color: #000;
      font-size: 26px;
    }
    .preview-title { 
      font-size: 18px;
      margin-top: 24px;
      span {
        color: red;
      }
    }
    .preview-descript{ 
      font-size: 14px;
      color: #aeaeae;
      margin-top: 16px;
      padding-left: 20px;
      margin-bottom: 10px;
    }
    .preview-scorename {
      span {
        display: inline-block; 
        height:22px;
        margin-left:16px;
        margin-right:8px;
        padding-left:4px;
      }
    }
    .preview-score {
      padding-top: 16px;
      span {
        display: inline-block; 
        width:32px;
        height:22px;
        margin-right:8px;
        padding-left:2px;
        width:32px
      }
    }
    .preview-options {
      padding-bottom: 1px;
      font-size: 16px;
      font-weight: 100;
      ::v-deep(.ant-radio-inner) {
        height: 16px;
        width: 16px;
        &::after {
          height: 6px;
          width: 6px;
        }
      }
      overflow-y: hidden;
      overflow-x: auto;
      .colunm {
        display: flex;
        margin-bottom: 20px;
        span {
          flex: 0 0 auto;
          align-self: center;
          text-align: center;
          line-height: 22px;
          padding: 0 24px;
        }
      }
      .row {
        margin-bottom: 20px;
        .row-content {
          display: flex;
          
          .row-title {
            text-align: left;
          }
          span {
            flex: 0 0 auto;
            display: inline-block;
            text-align: center;
            line-height: 22px;
            padding: 0 16px;
          }
          .ant-radio-group {
            flex: 1 1 auto;
            display: flex;
            .ant-radio-wrapper {
              flex: 0 0 auto;
              text-align: center;
              align-self: center;
            }
          }
        }
        &:last-child {
          margin-bottom: 0px;
        }
        .row-desc {
          margin-top: 10px;
          font-size: 14px;
          padding-left: 16px;
          line-height: 24px;
          color: #33333370;
        }
      }
    }
    .preview-tips {
      .tips{
        margin-top: 20px;
        color: #a5a5a5;
        font-size: 14px;
        &:not(:first-child) {
          margin-top: 10px;
        }
      }
    }

  }
  .settings {
    position: relative;
    margin-top: 10px;
    padding: 16px 32px 16px 32px;
    background-color: #fff;
    border-top-right-radius: 10px;
    .settings-type {
      position: absolute;
      top: 10px;
      right: 20px;
      width: 210px;
    }
    .settings-title {
      width: 500px;
      font-size: 18px;
    }
    .settings-content {
      display: flex;
      .settings-content-left {
        flex: 1 1 auto;
        .settings-toptitle {
          margin-top: 20px;
          color: #8b8b8b;
          font-size: 14px;
          .settings-toptitle-desc {
            text-align: center;
          }
          .settings-toptitle-isfill {
            text-align: center;
          }
        }
        .settings-options {
          display: flex;
          position: relative;
          margin-top: 20px;
          .settings-options-sort {
            position: absolute;
            top: 6px;
            left: -20px;
            z-index: 99;
            color: #b7b7b7;
          }
          .settings-options-input {
            width: 200px!important;
            font-size: 16px;
          }
          .settings-options-desc {
            text-align: center;
            font-size: 26px;
          }
          .settings-options-scorebox {
            display: flex;
            input {
              flex: 0;
              display: inline-block;
              padding: 10px;
              border: 1px solid #d9d9d9;
              width: 32px;
              height: 32px;
            }
            p {
              flex: 0;
              font-size: 12px;
              margin-left: 4px;
              margin-right: 10px;
              white-space: nowrap;
              height: 30px;
              line-height: 30px;
              width: 36px;
            }
            span {
              flex: 0;
              height: 24px;
              border-right: 1px solid #000;
              margin-left: 10px;
              margin-top: 3px;
            }
            button {
              margin-top: 6px;
            }
          }
          .settings-options-del {
  vertical-align: top;
            margin-left: 16px;
          }
          .settings-options-desc {
            text-align: center;
            font-size: 26px;
          }
          .iconfont {
            cursor:pointer;
            &:hover {
              background-color: @hoverBackground;
            }
          } 
        }
        .settings-add {
          margin-top: 20px;
          div {
            width: 120px;
            height: 36px;
            border: 1px dashed #d9d9d9;
            border-radius: 4px;
            display: inline-block;
            font-size: 14px;
            color: #666;
            text-align: center;
            line-height: 36px;
            margin-right: 20px;
            .pointer()
          }
          .iconfont {
            font-size: 12px;
            margin-right: 6px;
          }
        }
      }
      .settings-content-center {
        border-left: 1px solid #000;
        width: 20px;
        margin-right: 20px;
        margin-top: 20px;
      }
      .settings-content-right {
        flex: 1 1 auto;
        .settings-toptitle {
          margin-top: 20px;
          color: #8b8b8b;
          font-size: 14px;
          .settings-toptitle-desc {
            text-align: center;
          }
          .settings-toptitle-isfill {
            text-align: center;
          }
        }
        .settings-options {
          display: flex;
          position: relative;
          margin-top: 20px;
          .settings-options-sort {
            position: absolute;
            top: 6px;
            left: -20px;
            z-index: 99;
            color: #b7b7b7;
          }
          .settings-options-input {
            width: 200px!important;
            font-size: 16px;
          }
          .settings-options-desc {
            text-align: center;
            font-size: 26px;
          }
          .settings-options-scorebox {
            display: flex;
            justify-content: space-between;
            input {
              flex: 0;
              display: inline-block;
              padding: 10px;
              border: 1px solid #d9d9d9;
              width: 32px;
              height: 32px;
            }
            p {
              flex: 0;
              font-size: 12px;
              margin-left: 4px;
              margin-right: 10px;
              white-space: nowrap;
              height: 30px;
              line-height: 30px;
              width: 36px;
            }
            span {
              flex: 0;
              height: 24px;
              border-right: 1px solid #000;
              margin-left: 10px;
              margin-top: 3px;
            }
            button {
              margin-top: 6px;
            }
          }
          .settings-options-del {
  vertical-align: top;
            margin-left: 16px;
          }
          .iconfont {
            cursor:pointer;
            &:hover {
              background-color: @hoverBackground;
            }
          } 
        }
        .settings-add {
          margin-top: 20px;
          div {
            width: 120px;
            height: 36px;
            border: 1px dashed #d9d9d9;
            border-radius: 4px;
            display: inline-block;
            font-size: 14px;
            color: #666;
            text-align: center;
            line-height: 36px;
            margin-right: 20px;
            .pointer()
          }
          .iconfont {
            font-size: 12px;
            margin-right: 6px;
          }
        }
      }
    }
  }
  .bottom {
    margin-top: 2px;
    padding: 22px 32px 16px 32px;
    background-color: #fff;
    border-top-right-radius: 10px;
    .base-setting {
      .bottom-desc {
        text-decoration: underline;
        margin-right: 16px;
        &:hover {
          background-color: @hoverBackground;
          cursor:pointer;
        }
      }
      .bottom-switch {
        margin: 0 12px;
      }
      .bottom-vertical {
        display: inline-block;
        border: 1px solid #d7d7d7;
        border-radius: 16px;
        font-size: 13px;
        width: 82px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        color: #727272;
        margin-left: 10px;
      }
    }
    .logic-setting {
      margin-top: 16px;
      .show-setting {
        margin-right: 10px;
        text-decoration: underline;
        .pointer()
      }
      .jump-setting {
        text-decoration: underline;
        .pointer()
      }
    }
    .bottom-finish {
      margin: 34px auto  10px;
      width: 100px;
      height: 32px;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      line-height: 32px;
      color: #FFFFFF;
      border-radius: 4px;
      background-color: #f59a23;
      cursor: pointer;
    }
  }
}
// 手机端
.mobile-matrix_scale {
  border-bottom: 1px solid #E1E1E8;
  .preview {
    padding: 25px 20px;
    background-color: #fff;
    .preview-title { 
      font-size: 18px;
      line-height: 32px;
      font-weight: bold;
      span {
        color: red;
      }
    }
    .preview-descript{ 
      font-size: 14px;
      line-height: 30px;
      margin-top: 4px;
      color: #aeaeae;
      padding-left: 20px;
    }
    .preview-options{ 
      overflow: auto;
      margin-top: 16px;
      background-color: #fafafc;
      .colunm{
        display: flex;
        flex-wrap: nowrap;
        span {
          flex: 1 0 40px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          background-color: #E1E1E8;
          line-height: 24px;
          font-size: 14px;
        }
      }
      .row {
        padding-bottom: 10px;
        .row-title {
          padding-top: 20px;
          padding-left: 14px;
          line-height: 30px;
          color: #2D2D33;
        }
      }
    }
    .preview-tips {
      margin-top: 30px;
      .tips {
        color: #a5a5a5;
        font-size: 14px;
      }
    }
  }
} 
// 公用部分
.noborder {
  border: none;
}
.hide {
  display: none;
}
.isAlreadySet {
  color: #F59A23;
}
</style>

<style lang="less">
// 电脑端
.matrix_scale {
  // 无法在scoped里面设置radio的样式
  .ant-radio-checked {
    &::after {
      background-color: #00a0e9;
    }
    .ant-radio-inner {
      width: 16px;
      height: 16px;
      background-color: #00a0e9;
      &::after {
        width: 5px;
        height: 5px;
        left: 50%; 
        top: 50%;
        transform: translate(-50%, -50%); /* 50%为自身尺寸的一半 */
        -webkit-transform: translate(-50%, -50%);
        background-color: #fff;
      }
    }
  }
  input {
    border: none;
    border-bottom: 1px solid #d7d7d7;
  }
  
}
// 手机端
.mobile-matrix_scale {
  .ant-radio-group {
    display: flex;
    .ant-radio-wrapper {
      flex: 1 0 40px;
      display: flex;
      justify-content: center;
      font-size: 16px;
      margin-top: 14px;
      word-break: break-all; //只对英文起作用，以字母作为换行依据
      word-wrap: break-word; // 只对英文起作用，以单词作为换行依据
      white-space:pre-wrap; // 只对中文起作用，强制换行
      .ant-radio-inner {
        // width: 20px!important;  
        // height: 20px!important;
        border-color: #dbdbdb;
        background-color: #fff;
      }
      .ant-radio-checked {
        .ant-radio-inner {
          background-color: #00A0E9;
          &::after {
            width: 5px;
            height: 5px;
            left: 50%; 
            top: 50%;
            transform: translate(-50%, -50%); /* 50%为自身尺寸的一半 */
            -webkit-transform: translate(-50%, -50%);
            border-radius: 50%;
            padding: 0;
            margin: 0;
            border: none;
            background-color: #fff;
          }
        }
      }
      span:nth-child(2) {
        line-height: 30px;
        color: #2D2D33;
      }
      input {
        border: none;
        border-bottom: 1px solid #d7d7d7;
      }
    } 
  }
}
</style>