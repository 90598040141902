<!--
 * @Description: 富文本输入框
 * @version: 
 * @Author: HWL
 * @Date: 2021-06-22 14:14:49
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-13 16:25:43
-->
<template>
  <div class="rich-input">
    <div ref="toolbarRef" class="toolbar"/>
    <div ref="editorRef" class="text" />
  </div>
</template>
<script>
import { ref, toRefs } from '@vue/reactivity'
import E from 'wangeditor'
import { nextTick, onBeforeUnmount, onMounted, watch } from '@vue/runtime-core'

// 过滤br标签
const filterChildren = (children) => {
  for (let index = 0; index < children.length; index++) {
    const child = children[index]
    if (typeof child === 'string') {
      if (child.indexOf('<br/>') > -1) {
        children[index] = {
          tag: 'br',
          attrs: [],
          children: []
        }
      }
    } else {
      filterChildren(child.children)
    }
  }
  filterEndBr(children)
}

// 过滤最后一个br标签
const filterEndBr = (children) => {
  if (!children) return
  const length = children.length
  if (length > 0) {
    const lastChildren = children[length - 1]
    if (lastChildren.tag === 'br') {
      children.pop()
    }
  }
}
const emptyText = '[{"tag":"p","attrs":[{"name":"data-we-empty-p","value":""}],"children":[]}]'
export default {
  name: 'RichInput',
  props: [
    'text',
    'placeholder'
  ],
  setup(props, { emit }) {
    const toolbarRef = ref(null)
    const editorRef = ref(null)

    let editor

    const updateText = (text) => {
      if (editor && text !== undefined && text && JSON.stringify(text) !== emptyText) {
        if (typeof text === 'string') {
          editor.txt.html(text)
        } else {
          editor.txt.setJSON(text)
        }
      }
    }
    onMounted(() => {
      if (!editor) {
        editor = new E(toolbarRef.value, editorRef.value)
        // 菜单配置
        editor.config.menus = [
          'bold', // 粗体
        ]

        // 配置字体
        editor.config.fontNames = [
          '黑体',
        ]

        const { text, placeholder } = toRefs(props)

        if (!text.value) {
          editor.config.placeholder = placeholder.value
        }

        editor.config.focus = false
        editor.config.onchange = function (newHtml) {
          if (editor) {
            const title = editor.txt.getJSON()
            const txt = editor.txt.text()
            filterChildren(title)
            emit('finishEditText',  { title, txt })
          }
        }
        // editor.config.pasteTextHandle = function (pasteStr) {
        //   if (pasteStr[pasteStr.length - 1] === '\n') {
        //     pasteStr = pasteStr.subString(0, pasteStr.length - 1)
        //   }
        //   editor.txt.html('')
        //   return pasteStr
        // }
          
        editor.create()
        updateText(text.value)
      }

    })

    onBeforeUnmount(() => {
      if (editor) {
        editor.destroy()
        editor = null
      }
    })

    return {
      toolbarRef,
      editorRef,
      updateText
    }
  }
}
</script>
<style lang="less" scoped>
.rich-input {
  .toolbar {
    position: absolute;
    visibility: hidden;
    z-index: -9999;
  }
  .text {
    min-height: 30px;
    overflow: hidden;
    ::v-deep(.w-e-text-container) {
      z-index: 1 !important;
      .w-e-text {
        width: 450px !important;
      }
    }
    :deep(i) {
      font-style: italic;
    }
  }
}
</style>