/*
 * @Description: 矩阵选项更新功能
 * @version: 
 * @Author: HWL
 * @Date: 2021-05-31 09:43:46
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-15 17:11:44
 */

import { nextTick } from "@vue/runtime-core"

/**
 * 新增行
 * @param {question} question 问题
 */
export function handleRowAdd(question) {
  question.rowItems.push({
    'text': '',
    'description': '',
    'isNewAdd': true, // 是否是新添加的项
  })
  nextTick(() => {
    const lastRowIndex = question.rowItems.length - 1
    const targetArea = document.getElementById(`${question.questionId}_row_${lastRowIndex}`)
    targetArea.focus()
  })
}

/**
 * 新增列
 * @param {question} question 问题
 */
export function handleColumnAdd(question) {
  question.columnItems.push({
    'text': '',
    'isNewAdd': true, // 是否是新添加的项
  })
  nextTick(() => {
    // 预览效果列要向右滑动到尽头
    const oDiv = document.querySelectorAll(`[id="${question.questionId}"]`)[0]
    const targetDiv = oDiv.getElementsByClassName('preview-options')[0]
    targetDiv.scrollTo(targetDiv.scrollWidth, 0)
    // 编辑列，定位把焦点聚焦到最后一个列textarea
    const lastColIndex = question.columnItems.length - 1
    const targetArea = document.getElementById(`${question.questionId}_col_${lastColIndex}`)
    targetArea.focus()
  })
}

/**
 * 删除行
 * @param {questioin} question 问题
 * @param {integer} index 索引
 */
export function handleRowDelete(question, index) {
  question.rowItems.splice(index, 1)
}

/**
 * 删除列
 * @param {questioin} question 问题
 * @param {integer} index 索引
 */
export function handleColumnDelete(question, index) {
  question.columnItems.splice(index, 1)
}

/**
 * textarea阻止输入回车符
 * @param {*} event 
 * @param {*} choiceData 
 */
 export function handleStopInputEnter(event, choiceData) {
  if (event.keyCode === 13) {
    choiceData.text = choiceData.text.replace('\n', '')
  }
}