<!--
 * @Description: 题型设置
 * @version: 
 * @Author: HWL
 * @Date: 2021-05-26 15:49:51
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-12 10:24:01
-->
<template>
  <div class="setting-wrap">
    <div class="triangle-out"></div>
    <div class="triangle-in"></div>
    <div class="settings">
      <div class="settings-title">
        <div class="title">
          <RichInput
            class="rich-title"
            ref="inputRef"
            :text="question.title"
            @finishEditText="updateEditor"
            placeholder="添加标题"
          />
          <div class="editor iconfont" @click="handleEditorModel(item)">T</div>
        </div>
        <div class="description">
          <a-input 
            v-model:value="question.description"
            placeholder="请输入标题说明"
          />
        </div>
        <a-select 
          v-if="!route.query.isKeepAnswerAndEdit"
          label-in-value 
          v-model:value="selectVal" 
          class="settings-type"
          @change="handleChange"
          :getPopupContainer="
            triggerNode => {
              return triggerNode.parentNode || document.body;
            }
          "
        >
          <a-select-option v-for="(item, index) in selectList" :key="index" :value="item.value">{{ item.text }}</a-select-option>
        </a-select>
      </div>
      <div class="settings-option">
        <component
          :is="optionList[selectVal.key]"
          :item="item"
          :question="question"
          @showOption="showOption"
          @showBatch="showBatch"
          @showChoiceLibrary="showChoiceLibrary"
        />
      </div>
    </div>
    <setting-bottom
      :question="question"
      :type="type"
      @finished="finished"
    />
    <DescModal
      title="选项说明"
      :visible="isShowOptionDescModal" 
      :text="optionDesc"
      @finishEditText="changeOptionDesc"
    />
    <BatchAddModal
      title="批量新增"
      :visible="isShowBatchAddModal" 
      :text="allOptionsText"
      @finishEditText="handleBatchUpdate"
    />
    <EditorModal
      ref="editorRef"
      title="标题编辑"
      :visible="isShowEditorModal" 
      :text="text"
      @finishEditText="updateInput"
    />
    <ChoiceLibraryModal
      v-if="isShowChoiceLibraryModal"
      @modal-on-confirm="handleChoiceLibraryChoose"
      @modal-on-close="closeChoiceLibraryModal"
    />
  </div>
</template>
<script>
import { useRoute } from 'vue-router'
import { computed, ref } from 'vue'

import useCommonQuestionnaireEffect from '@/effects/questionnaireEffect'
import batchUpdateEffect from './effects/batchUpdateEffect'
import optionDescModalEffect from './effects/optionDescModalEffect'
import editorEffect from './effects/editorEffect'
import choiceLibraryEffect from './effects/choiceLibraryEffect'


import DescModal from '@/components/modal/DescModal'
import BatchAddModal from '@/components/modal/BatchAddModal'
import EditorModal from '@/components/modal/EditorModal'
import ChoiceLibraryModal from '@/views/edit/questionEdit/modal/ChoiceLibrary/ChoiceLibrary.vue'

import SettingBottom from './settingBottom'
import RichInput from './richInput'

import SingleChoiceOption from './settingOption/SingleChoiceOption'
import MultipleChoiceOption from './settingOption/MultipleChoiceOption'
import DropDownOption from './settingOption/DropDownOption'
import ScaleOption from './settingOption/ScaleOption'
import MatrixSingleChoiceOption from './settingOption/MatrixSingleChoiceOptioin'
import MatrixMultipleChoiceOption from './settingOption/MatrixMultipleChoiceOption'
import MatrixScaleOption from './settingOption/MatrixScaleOption'
import ShortOption from './settingOption/ShortOption'
import DateOption from './settingOption/DateOption.vue'

const selectList = [
  {
    value: 'SINGLE_CHOICE',
    text: '单选题',
  },
  {
    value: 'MULTIPLE_CHOICE',
    text: '多选题'
  },
  {
    value: 'DROP_DOWN',
    text: '下拉题'
  },
  {
    value: 'SCALE',
    text: '量表'
  },
  {
    value: 'MATRIX_SCALE',
    text: '矩阵量表'
  },
  {
    value: 'MATRIX_SINGLE_CHOICE',
    text: '矩阵单选'
  },
  {
    value: 'MATRIX_MULTIPLE_CHOICE',
    text: '矩阵多选'
  },
  {
    value: 'SHORT',
    text: '简答题'
  },
  {
    value: 'DATE',
    text: '日期题'
  },
]

const optionList = {
  SINGLE_CHOICE: 'SingleChoiceOption',
  MULTIPLE_CHOICE: 'MultipleChoiceOption',
  DROP_DOWN: 'DropDownOption',
  SCALE: 'ScaleOption',
  MATRIX_SINGLE_CHOICE: 'MatrixSingleChoiceOption',
  MATRIX_MULTIPLE_CHOICE: 'MatrixMultipleChoiceOption',
  MATRIX_SCALE: 'MatrixScaleOption',
  SHORT: 'SortOption',
  DATE: 'DateOption',
}

export default {
  name: 'Setting',
  props: [
    'item',
    'index',
    'type', // 选项类型
    'isOnlyRead',
    'question',
  ],
  emits: [
    'finished'
  ],
  components: { 
    SettingBottom,
    RichInput,
    SingleChoiceOption,
    MultipleChoiceOption,
    DropDownOption,
    MatrixSingleChoiceOption,
    MatrixMultipleChoiceOption,
    MatrixScaleOption,
    ScaleOption,
    ShortOption,
    DateOption,
    DescModal, 
    BatchAddModal,
    EditorModal,
    ChoiceLibraryModal,
  },
  setup(props, { emit }) {
    const route = useRoute()

    const selectVal = ref({key: props.type})
    const editorRef = ref(null)
    const inputRef = ref(null)
    const { handleEffect, questionEditStatusChangeEffect } = useCommonQuestionnaireEffect()
    const { handleChange } = handleEffect(props)
    const { isShowBatchAddModal, allOptionsText, handleBatchUpdate, openBatchAddModal } = batchUpdateEffect(props.question)
    const { isShowChoiceLibraryModal, openChoiceLibraryModal, closeChoiceLibraryModal, handleChoiceLibraryChoose } = choiceLibraryEffect(props.question)
    const { isShowOptionDescModal, optionDesc, changeOptionDesc, handleOpenOptionDescModal } = optionDescModalEffect(props.question)
    const { isShowEditorModal, text, handleEditorModel, changeEditor, changeOption, changeTitle } = editorEffect()

    const { edit } = questionEditStatusChangeEffect(props)
    edit(props.question.questionId)

     const updateEditor = (data) => {
      changeTitle(data)
      if (editorRef.value) {
        editorRef.value.updateText(data.title)
      }
    }

    const updateInput = (data) => {
      changeEditor(data)
      if (inputRef.value) {
        inputRef.value.updateText(data.title)
      }
    }

    changeOption(props.item)

    const finished = () => {
      emit('finished')
    }

    const showOption = (item) => {
      handleOpenOptionDescModal(item)
    }

    const showBatch = (item) => {
      openBatchAddModal(item)
    }

    const showChoiceLibrary = (item) => {
      openChoiceLibraryModal(item)
    }

    return {
      route,
      editorRef, inputRef,

      selectList,
      optionList,
      selectVal,
      finished,
      showOption,
      showBatch,
      showChoiceLibrary,

      handleChange,

      isShowBatchAddModal,
      handleBatchUpdate,
      allOptionsText,

      isShowChoiceLibraryModal, 
      closeChoiceLibraryModal,
      handleChoiceLibraryChoose,

      optionDesc,
      isShowOptionDescModal,
      changeOptionDesc,

      isShowEditorModal, text, handleEditorModel, changeEditor,
      updateEditor, updateInput
    }
  }
}
</script>
<style lang="less">
.setting-wrap {
  position: relative;
  background-color: #F9F9FA;
  .triangle-in {
    position: absolute;
    top: -20px;
    left: 34px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid #F9F9FA;
  }
  .triangle-out {
    position: absolute;
    top: -22px;
    left: 33px;
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 22px solid #E1E1E8;
  }
}
.settings {
  position: relative;
  padding: 16px 32px 16px 32px;
  .settings-title {
    display: flex;
    font-size: 16px;
    .title {
      position: relative;
      width: 500px;
      min-height: 44px;
      border: 1px  solid #E1E1E8;
      .editor {
        position: absolute;
        top: 14px;
        right: 11px;
        width: 16px;
        margin: 0 auto;
        font-size: 16px;
        color: #E1E1E8;
        font-weight: bold;
        cursor: pointer;
        z-index: 100;
      }
    }
    .ant-input {
      width: 315px;
      height: 47px;
      margin-left: 10px;
      border: 1px solid #E1E1E8;
      &:focus {
        box-shadow: none!important;
      }
    }
    .settings-type {
      width: 129px;
      height: 47px;
      margin-left: 10px;
      .ant-select-selector {
        height: 100%;
        border: 1px solid #E1E1E8;
        border-color: #E1E1E8 !important;
        box-shadow: none !important;
        border-radius: 0px;
        .ant-select-selection-item {
          line-height: 47px;
        }
      }
    }
  }
  .settings-option {
    input {
      border: 1px solid #E1E1E8;
      &:focus {
        border: 1px solid #00A0E9;
        box-shadow: none;
      }
    }
  }
  .settings-add {
    .iconfont {
      color: #00A0E9;
    }
  }
  .iconfont {
    color: #D3D3DD;
  }
  .settings-options-del {
  vertical-align: top;
    font-size: 20px;
  }
  .settings-options-input {
    min-height: 41px;
    line-height: 31px;
    resize: none;
  }
}
.min-selcet .ant-select-selector {
  height: 41px !important;
  border-radius: 0px !important;
  .ant-select-selection-placeholder {
    line-height: 41px !important;
  }
  .ant-select-selection-item {
    line-height: 41px !important;
  }
}
.isAlreadySet {
  color: #00A0E9 !important;
}
</style>