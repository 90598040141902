<!--
 * @Description: 分页
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-19 09:59:25
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-02 18:37:07
-->
<template>
  <div class="page" :class="{'longer': question.page !== 0 && !isOnlyRead}">
    <div class="preview" @click="edit">
      <div class="preview-sort iconfont" v-if="question.page !== 0 && !isOnlyRead && !route.query.isKeepAnswerAndEdit">&#xe697;</div>
      <div 
        class="preview-del iconfont" 
        @click="handleDelete" 
        v-if="question.page !== 0 && !isOnlyRead && !route.query.isKeepAnswerAndEdit"
      >&#xe609;</div>
      <div class="preview-title">
        <span v-if="!isOnlyRead">[ 第{{question.page + 1}}页 ]</span>
        <span v-else>第{{question.page + 1}}页</span>
        <span v-if="question.maxPage">&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;共{{question.maxPage + 1}}页</span>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, computed, watchEffect } from 'vue';
import globalQuestionnaire from '@/class/useQuestionnaire'
import { useRoute, useRouter } from 'vue-router'


/**
 * 删除分页组件
 */
const deleteEffect = (props) => {
  const handleDelete = () => {
    // 首先根据分页组件的索引找到所在位置
    // 往下遍历组件，将索引index之后的组件 --page
    // 删除当前分页组件
    const questionnaire = globalQuestionnaire.getQuestionnaire()
    const startIndex = props.index
    for (let i = startIndex; i < questionnaire.questions.length; i++) {
      --questionnaire.questions[i].page
    }
    questionnaire.questions.splice(startIndex, 1)
    const maxPageIndex = Object.keys(questionnaire.pageMap).pop()
    delete questionnaire.pageMap[maxPageIndex]
  }
  return { handleDelete }
}


export default {
  name: 'Page',
  props: ['item', 'index', 'isOnlyRead'],
  setup (props) {
    const route = useRoute()
    const question = computed({
      get: () => props.item
    })
    const { handleDelete } = deleteEffect(props)
    return {
      route,
      question,
      handleDelete,
    }
  },
}
</script>

<style scoped lang="less">
.page {
  height: 92px;
  background-color: @backgroundColor;
  margin-top: 24px;
  margin-bottom: 16px;
  outline:none;
  &.longer {
    height: 104px;
    .preview {
      display: block;
      .preview-title {
        margin-top: 14px;
      }
    }
  }
  .preview {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 14px 0;
    border-radius: 6px;
    background-color: #fff;
    .iconfont:hover {
      background-color: @hoverBackground;
      cursor:pointer;
    }
    .preview-sort{ 
      margin: 0 auto;
      font-size: 24px;
      width: 24px;
      transform: rotate(90deg);
      color: #b7b7b7;
    }
    .preview-del {
      position: absolute;
      top: 47px;
      right: 50px;
      color: #1AAAEC;
      font-size: 26px;
    }
    .preview-title { 
      font-size: 16px;
      font-weight: 100;
      color: #88837F;
      text-align: center;
    }
  }
} 
.noborder {
  border: none;
}
.hide {
  display: none;
}

.isAlreadySet {
  color: #F59A23;
}
</style>
