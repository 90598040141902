<!--
 * @Description: 下拉题
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-18 18:54:22
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-26 10:45:11
-->
<template>
  <!-- 电脑端 -->
  <div class="dropdown" v-if="isPc"
    :class="{noborder: !isSettingsActive}" 
  >
    <div class="sort-icon">
      <div class="preview-sort iconfont" v-if="!isOnlyRead">&#xe697;</div>
    </div>
    <div 
      class="preview-del iconfont" 
      @click.stop="handleDelete" 
      v-if="!isOnlyRead && !route.query.isKeepAnswerAndEdit"
    >&#xe609;</div>
    <div 
      class="preview-copy iconfont" 
      @click.stop="handleCopy" 
      v-if="!isOnlyRead && !route.query.isKeepAnswerAndEdit"
    >&#xe62f;</div>
    <div class="preview" @click="edit(question.questionId)">
      <PreviewTitle :isOnlyRead="isOnlyRead" :question="question" :isPc="isPc"/>
      <div class="preview-options">
        <div v-if="question.ifMessUp">
          <a-select 
            label-in-value 
            class="preview-select"
            v-model:value="radioValue"
            :disabled="isDisabled"
            :getPopupContainer="
              triggerNode => {
                return triggerNode.parentNode || document.body;
              }
            "
            @change="fillAnswerIntime(question, radioValue)"
          >
            <a-select-option 
              v-for="(item, index) in randomChoices"
              :value="item.choiceId"
              :index="index"
              :key="item.choiceId"
            >
              {{item.text ? item.text : '选项'}}
            </a-select-option>
          </a-select>
        </div>
        <div v-else>
          <a-select 
            label-in-value 
            class="preview-select"
            v-model:value="radioValue"
            :disabled="isDisabled"
            :getPopupContainer="
              triggerNode => {
                return triggerNode.parentNode || document.body;
              }
            "
            @change="fillAnswerIntime(question, radioValue)"
          >
            <a-select-option 
              v-for="(item, index) in question.choices"
              :value="item.choiceId"
              :index="index"
              :key="index"
            >
              {{item.text ? item.text : '选项'}}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="preview-tips">
        <div class="tips" v-if="item.isCondJump">逻辑设置：本题设置了跳题逻辑：按选项跳题</div>
        <div class="tips" v-if="item.isNoCondJump">逻辑设置：{{item.jumpLogicTips}}</div>
        <div class="tips" v-if="item.showLogicTips">逻辑设置：本题设置了题目显示逻辑：{{item.showLogicTips}}</div>
      </div>
      <PreviewBottom v-if="isOnlyRead" />
    </div>
    <PreviewBottom v-if="!isOnlyRead" style="background: #fff"/>
    <setting
      v-if="isSettingsActive"
      type="DROP_DOWN"
      :item="item"
      :index="index"
      :isOnlyRead="isOnlyRead"
      :question="question"
      @finished="finished"
    />
  </div>
  <!-- 手机端 -->
  <div class="mobile-dropdown" v-else>
    <div class="preview">
      <PreviewTitle :isOnlyRead="isOnlyRead" :question="question" :isPc="isPc"/>
      <div class="preview-options">
        <!-- 乱序 -->
        <div v-if="question.ifMessUp">
          <a-select
            label-in-value 
            placeholder="请选择..."
            class="preview-select"
            v-model:value="radioValue"
            @change="fillAnswerIntime(question, radioValue)"
          >
            <a-select-option 
              v-for="(item, index) in randomChoices"
              :value="item.choiceId"
              :index="index"
              :key="item.choiceId">{{item.text}}
            </a-select-option>
          </a-select>
        </div>
        <!-- 顺序 -->
        <div v-else>
          <a-select
            label-in-value 
            placeholder="请选择..."
            class="preview-select"
            v-model:value="radioValue"
            @change="fillAnswerIntime(question, radioValue)"
          >
            <a-select-option 
              v-for="(item, index) in question.choices"
              :value="item.choiceId"
              :index="index"
              :key="index">{{item.text}}
            </a-select-option>
          </a-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import useCommonQuestionnaireEffect from '@/effects/questionnaireEffect'
import logicWatcherEffect from '@/effects/logicWatcherEffect'
import choiceRandomEffect from './components/effects/choiceRandomEffect'
import Setting from './components/setting'
import PreviewTitle from './components/previewTitle'
import PreviewBottom from './components/previewBottom'

/**
 * 答案管理
 */
const answerEffect = () => {
  const route = useRoute()
  // 答案获取
  const getAnswer = (question, choiceId) => {
    if (!choiceId.key) return
    const answer = {
      "questionType": question.questionType, 
      "questionId": question.questionId,
      "choices": [{
        "choiceId": choiceId.value,
        "fillText": question.choices.filter(item => item.choiceId === choiceId.value)[0].fillText || ''
      }]
    }
    return answer
  }

  // 答案填写
  // isAreadyAnswerFill，只需要初始化组件时执行一次即可
  const fillAnswer = (question, radioValue, isAreadyAnswerFill) => {
    if (route.name === 'RecycleBin') isAreadyAnswerFill.value = false // 这种是特殊情况，当在回收情况、查看问卷、点击上一份、下一份时，是允许重新填写答案并展示出来的
    if (!isAreadyAnswerFill.value) {
      isAreadyAnswerFill.value = true
      const choiceId = question.answer.choices[0].choiceId
      const fillText = question.answer.choices[0]?.fillText || ''
      radioValue.value.key = choiceId
      question.choices.forEach(choice => {
        if (choice.choiceId === choiceId) {
          choice.fillText = fillText
        }
      })
    }
  }

  // 实时填写答案
  const fillAnswerIntime = (question, value) => {
    watch(() => question.answer, (cur, pre) => {
      watchVisibilityEffect(question)
    })     
    const { watchVisibilityEffect } = logicWatcherEffect()
    question.answer = getAnswer(question, value)
    document.getElementById(question.questionId).classList.remove('error')
  }

  return {
    getAnswer,
    fillAnswer,
    fillAnswerIntime,
  }
}

export default {
  props: ['item', 'index', 'isOnlyRead', 'isDisabled', 'isPc'],
  components: { 
    Setting,
    PreviewTitle,
    PreviewBottom,
  },
  name: 'DropDown',
  setup(props) {
    const store = useStore()
    const route = useRoute()
    const radioValue = ref({key: ''})
    const selectVal = ref({key: 'DROP_DOWN'})
    const isAreadyAnswerFill = ref(false)
    const { getAnswer, fillAnswer, fillAnswerIntime } = answerEffect()
    const question = computed({
      get: () => {
        if (!props.item.answer?.questionId) {
          props.item.answer = {}
        } else {
          fillAnswer(props.item, radioValue, isAreadyAnswerFill)
        }
        return props.item
      }
    })
    watch (() => store.state.curEditingQuestonId, (cur, pre) => {
      if (/^(Preview|Exercise)$/.test(route.name)) return
      if (cur === question.value.questionId) {
        isSettingsActive.value = props.isOnlyRead ? false : true
      } else {
        isSettingsActive.value = false
      }
    })

    const finished = () => {
      isSettingsActive.value = false
    }

    const { randomChoices } = choiceRandomEffect(question.value)
    const { handleEffect, questionEditStatusChangeEffect, jumpLogicEffect, showLogicEffect,  } = useCommonQuestionnaireEffect()
    const { isSettingsActive, edit } = questionEditStatusChangeEffect(props)
    const { handleDelete, handleCopy, handleChange } = handleEffect(props)

    edit(props.item.questionId)

    return {
      route,
      radioValue,
      selectVal,
      fillAnswerIntime,
      question,
      randomChoices,
      finished,
      edit,
      isSettingsActive,
      handleDelete,
      handleCopy,
      handleChange,
    }
  },
}
</script>

<style scoped lang="less">
// 电脑端
.dropdown {
  position: relative;
  background-color: @backgroundColor;
  border-bottom: 1px solid #E1E1E8;
  .iconfont:hover {
    background-color: @hoverBackground;
    cursor:pointer;
  }
  .preview-del {
    position: absolute;
    top: 47px;
    right: 93px;
    color: #00A0E9;
    font-size: 24px;
    z-index: 1;
  }
  .preview-copy {
    position: absolute;
    top: 47px;
    right: 49px;
    color: #00A0E9;
    font-size: 24px;
    z-index: 1;
  }
  .sort-icon {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 24px;
    margin-top: 10px;
    .preview-sort {
      width: 24px;
      height: 24px;
      font-size: 24px;
      transform: rotate(90deg);
      color: #b7b7b7;
      z-index: 1;
    }
  }
  .preview {
    position: relative;
    padding: 40px 32px 0px 32px;
    background-color: #fff;
    .preview-title { 
      font-size: 18px;
      margin-top: 24px;
      span {
        color: red;
      }
    }
    .preview-descript{ 
      font-size: 14px;
      color: #aeaeae;
      margin-top: 16px;
      padding-left: 20px;
    }
    .preview-options{ 
      .preview-select {
        width: 320px;
        height: 41px;
      }
      .preview-options-desc {
        margin: 10px 0 0 10px;
        font-size: 14px;
        color: #33333370;
      }
    }
    .preview-tips {
      .tips {
        margin-top: 10px;
        color: #a5a5a5;
        font-size: 14px;
        }
    }
  }
  .settings {
    position: relative;
    margin-top: 10px;
    padding: 16px 32px 16px 32px;
    background-color: #fff;
    border-top-right-radius: 10px;
    .settings-type {
      position: absolute;
      top: 10px;
      right: 20px;
      width: 210px;
    }
    .settings-title {
      width: 500px;
      font-size: 18px;
    }
    .settings-toptitle {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      color: #8b8b8b;
      font-size: 14px;
      .settings-toptitle-desc {
        text-align: center;
      }
      .settings-toptitle-isfill {
        text-align: center;
      }
    }
    .settings-options {
      display: flex;
      position: relative;
      margin-top: 20px;
      .settings-options-sort {
        position: absolute;
        top: 6px;
        left: -20px;
        z-index: 99;
        color: #b7b7b7;
      }
      .settings-options-input {
        width: 92%!important;
        font-size: 16px;
      }
      .settings-options-desc {
        text-align: center;
        font-size: 26px;
      }
      .settings-options-notfill {
        text-align: center;
      }
      .settings-options-isfill {
        display: flex;
        padding-left: 30px;
        p {
          font-size: 12px;
          margin-left: 4px;
          margin-right: 10px;;
        }
        span {
          height: 26px;
          border-right: 1px solid #000;
          margin-top: -6px;
          margin-left: 10px;
        }
      }
      .settings-options-del {
  vertical-align: top;
        margin-left: 40px;
      }
      .iconfont {
        cursor:pointer;
        &:hover {
          background-color: @hoverBackground;
        }
      } 
    }
    .settings-add {
      margin-top: 20px;
      div {
        width: 120px;
        height: 36px;
        border: 1px dashed #d9d9d9;
        border-radius: 4px;
        display: inline-block;
        font-size: 14px;
        color: #666;
        text-align: center;
        line-height: 36px;
        margin-right: 20px;
        .pointer()
      }
      .iconfont {
        font-size: 12px;
        margin-right: 6px;
      }
    }
  }
  .bottom {
    margin-top: 2px;
    padding: 22px 32px 16px 32px;
    background-color: #fff;
    border-top-right-radius: 10px;
    .base-setting {
      .bottom-desc {
        text-decoration: underline;
        margin-right: 16px;
        &:hover {
          background-color: @hoverBackground;
          cursor:pointer;
        }
      }
      .bottom-switch {
        margin: 0 12px;
      }
    }
    .logic-setting {
      margin-top: 16px;
      .show-setting {
        margin-right: 10px;
        text-decoration: underline;
        .pointer()
      }
      .jump-setting {
        text-decoration: underline;
        .pointer()
      }
    }
    .bottom-finish {
      margin: 34px auto  10px;
      width: 100px;
      height: 32px;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      line-height: 32px;
      color: #FFFFFF;
      border-radius: 4px;
      background-color: #f59a23;
      cursor: pointer;
    }
  }
}
// 手机端
.mobile-dropdown {
  border-bottom: 1px solid #E1E1E8;
  .preview {
    padding: 25px 20px;
    background-color: #fff;
    .preview-title { 
      font-size: 18px;
      line-height: 32px;
      font-weight: bold;
      span {
        color: red;
      }
    }
    .preview-descript{ 
      font-size: 14px;
      line-height: 30px;
      margin-top: 4px;
      color: #aeaeae;
      padding-left: 20px;
    }
    .preview-options{ 
      .preview-select {
        padding-left: 20px;
        margin-top: 20px;
        width: 94%;
      }
      .preview-options-desc {
        margin: 10px 0 0 10px;
        font-size: 14px;
        color: #33333370;
      }
    }
    .preview-tips {
      .tips {
        margin-top: 10px;
        color: #a5a5a5;
        font-size: 14px;
        }
    }
  }
} 
// 公用部分
.noborder {
  border: none;
}
.hide {
  display: none;
}
.isAlreadySet {
  color: #F59A23;
}
input {
  border: none;
  border-bottom: 1px solid #d7d7d7;
}
</style>

<style lang="less">
// 电脑端
.dropdown {
  .ant-radio-wrapper {
    display: block;
    font-size: 16px;
    margin: 20px 0;
    .ant-radio-inner {
      width: 20px!important;  
      height: 20px!important;
      border-color: #dbdbdb;
      &::after {
        top: 5px;
        left: 5px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        padding: 0;
        border: none;
      }
    }
    .ant-radio-checked {
      border-color: #f59a23;
    }
  }
  .preview-options {
    .ant-select-selector {
      height: 41px;
      border-radius: 0px;
      .ant-select-selection-item {
        font-weight: 100;
        line-height: 41px;
      }
    }
  }
}
// 手机端
.mobile-dropdown {
  .ant-radio-wrapper {
    display: block;
    font-size: 16px;
    margin: 20px 0;
    .ant-radio-inner {
      width: 20px!important;  
      height: 20px!important;
      border-color: #dbdbdb;
      &::after {
        top: 5px;
        left: 5px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        padding: 0;
        border: none;
      }
    }
    .ant-radio-checked {
      border-color: #f59a23;
    }
  }
  .preview-options {
    .ant-select-selector {
      height: 40px;
    }
  }
}
</style>
