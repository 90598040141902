/*
 * @Description: 量表模板相关
 * @version: 
 * @Author: HWL
 * @Date: 2021-05-28 16:12:44
 * @LastEditors: HWL
 * @LastEditTime: 2021-06-01 11:59:55
 */
import { ref } from 'vue'
import { createUniqueId } from '@/utils/random'

/**
 * 量表模板相关
 */
function scaleTemplateEffect() {
  const scaleTemplateList = [2, 3, 4, 5, 6, 7, 8, 9, 10] // 用于下拉列表
  const scaleTemplate = ref()
  const leftArr = ['非常不满意']
  const rightArr = ['非常满意']

  /**
   * 处理量表模板修改
   * @param {question} question 问题
   */
  function handleScaleTemplateChange(question) {
    let centerArr = []
    if (scaleTemplate.value > 2) {
      for (let i = 2; i < scaleTemplate.value; i++) {
        centerArr.push(i)
      }
    }
    const allArr = leftArr.concat(centerArr).concat(rightArr) // 按顺序组合所有text
    let choicesArr = []
    for (let i = 0; i < allArr.length; i++) { // 生成新的项
      choicesArr.push({
        "value" : i + 1,
        "choiceId" : createUniqueId(8),
        "ifIgnore" : false,
        "text" : allArr[i],
      })
    }
    if (question.choices) {
      question.choices = choicesArr
    } else {
      question.columnItems = choicesArr
    }
  }

  return {
    scaleTemplateList,
    scaleTemplate,
    handleScaleTemplateChange,
  }
}

export default scaleTemplateEffect
