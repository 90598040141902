/*
 * @Description: 解析逻辑表达式
 * @version: 
 * @Author: PSG
 * @Date: 2021-04-25 11:12:11
 * @LastEditors: PSG
 * @LastEditTime: 2021-05-18 15:09:51
 */
import { ref, reactive } from "@vue/reactivity"
import globalQuestionnaire from '@/class/useQuestionnaire'

const useParseExpEffect = () => {
  
  // 构建可供选择的条件题目（只针对选项显示）
  const buildEnableChooseQuestionList = (curQuestionSort, alreadySelectQuestionList) => {
    // 只需要单选题、多选题
    const alreadySelectQuestionSortList = alreadySelectQuestionList.map(item => item.sort.key)
    const questionnaire = globalQuestionnaire.getQuestionnaire()
    const questions = questionnaire.questions.filter(item => 
      /^(SINGLE_CHOICE|MULTIPLE_CHOICE)$/.test(item.questionType)  // 单选
      && item.sort < curQuestionSort // 本题之前的题目
      && !alreadySelectQuestionSortList.includes(item.sort))  // 不在已选列表里面
    return questions
  }
  
  // 构建条件 单选题、多选题
  const buildSingleOrMultiple = (question, expChoiceIdsArr, condition) => {
    const logicQuestion = reactive({
      'questionId': { key: question.questionId },
      'title': question.title,
      'sort': { key: question.sort }, // 题目序号
      'questionType': question.questionType, // 题目类型
      'condition': { key: condition}, // 条件  1：全部；2：任一
      'choices': [],
      'chosenIds': expChoiceIdsArr,  // 勾选的选项
    })
    question.choices.forEach((item, index) => {
      let choice = {
        'choiceId': item.choiceId,
        'text': item.text
      }
      logicQuestion.choices.push(choice)
    })
    return logicQuestion
  }

  // 构建条件 矩阵单选、矩阵量表题
  const buildMatrix = (question, rowIndex, colIndexArr) => {
    const logicQuestion = {
      'questionId': { key: question.questionId },
      'title': question.title,
      'sort': { key: question.sort }, // 题目序号
      'questionType': question.questionType, // 题目类型
      'condition': { key: 2}, // 矩阵单选、矩阵量表只有任一，所有写死2
      'choices': [], // 列标题
      'rowItems': [], // 行标题
      'chosenIds': [], // 列标题 勾选的选项id
      'rowChosenId': '', // 行标题 勾选的选项id
    }
    colIndexArr.forEach(item => {
      logicQuestion.chosenIds.push(Number(item))
    })
    logicQuestion.rowChosenId = (rowIndex === -1 ? { 'key': -1} : { 'key': Number(rowIndex) })
    question.columnItems.forEach((item, index) => {
      let choice = {
        'choiceId': index, // 把index当成id，因为后端中，列没有存id字段
        'text': item.text
      }
      logicQuestion.choices.push(choice)
    })
    question.rowItems.forEach((item, index) => {
      let choice = {
        'choiceId': index, // 把index当成id，因为后端中，行没有存id字段
        'text': item.text
      }
      logicQuestion.rowItems.push(choice)
    })
    return logicQuestion
  }

  // 根据表达式 构建 条件问题 （矩阵单选、矩阵量表）
  const buildQuestionFromMatrixExp = (expression) => {
    let questionSort = 0 // 题目序号
    let rowIndex = 0  // 已选择的行标题序号
    let colIndexArr = [] // 已勾选的列标题序号
    let question = null
    const questionnaire = globalQuestionnaire.getQuestionnaire()
    // 1、根据表达式，获取关联的题目序号、已选择的行标题序号、已勾选的列标题序号
    expression.replace(/[^\w]/g, ',').split(',').forEach((expId, Index) => {
      if (Index === 0) {
        // 获取expression题目序号
        // questionSort = numItem
        questionSort = questionnaire.questions.filter(question => question.questionId === expId)[0].sort
      } else if (Index === 1) {
        // 获取expression行标题序号
        rowIndex = expId
      } else {
        // 获取expression矩阵列标题序号
        // colIndexArr.push(numItem)
        colIndexArr.push(expId)
      }
    })
    // 2、根据题目序号、已选择的行标题序号、已勾选的列标题序号,构建条件题目
    for (let i = 0; i < questionnaire.questions.length; i++) {
      if (questionSort === questionnaire.questions[i].sort) {
        question = JSON.parse(JSON.stringify(questionnaire.questions[i]))
        question = buildMatrix(question, rowIndex, colIndexArr)
        break
      }
    }
    return question
  }

  // 根据表达式 构建 条件问题 （单选、多选）
  const buildQuestionFromSingleOrMultExp = (expression) => {
    let questionSort = 0 // 题目序号
    let expChoiceIdsArr = [] // 已勾选的选项ID
    let question = null
    const questionnaire = globalQuestionnaire.getQuestionnaire()
    const isAllReg = /^\w+;\w+\./ // 判断是全部条件
    const condition = isAllReg.test(expression) ? 1 : 2 // 1: 全部；2：任一
    // 1、根据表达式，获取关联的题目序号、已勾选的选项序号
    expression.replace(/[^\w]/g, ',').split(',').forEach((expId, numIndex) => {
      if (numIndex === 0) {
        questionSort = questionnaire.questions.filter(question => question.questionId === expId)[0].sort
        // questionSort = Number(numItem)
      } else {
        // choicesIndexArr.push(Number(numItem))
        expChoiceIdsArr.push(expId)
      }
    })
    // 2、根据题目序号、已勾选的选项序号，构建条件题目
    for (let i = 0; i < questionnaire.questions.length; i++) {
      if (questionnaire.questions[i].sort !== undefined &&  questionSort === questionnaire.questions[i].sort) {
        question = JSON.parse(JSON.stringify(questionnaire.questions[i]))
        question = buildSingleOrMultiple(question, expChoiceIdsArr, condition)
        break
      }
    }
    return question
  }

  // 表达式解析器
  // 题目显示、选项显示的逻辑表达式解析规则一致
  const expressionParser = (expression) => {
    const list = reactive([])
    // 1、分组
    const expressionArr = expression.split(/&|\|/)
    // 2、解析每一组表达式
    expressionArr.forEach((express, index) => {
      const singleOrMultipleReg = /\w+;(\w+(,|.)*)+/
      const matrxiReg = /\w+;\w+\^(\w+,*)+/
      // 2.1、矩阵量表、矩阵单选
      if (matrxiReg.test(express)) {
        list.push(buildQuestionFromMatrixExp(express))
      } else {
      // 2.2、单选、多选
        list.push(buildQuestionFromSingleOrMultExp(express))
      }
    })
    
    return list
  }

  // 题目显示逻辑表达式解析
  const parseQuestionLogicExp = (question) => {
    const list = reactive([])
    const condition = ref({key: 1}) // 1: 全部；2：任一
    if (question.showLogic) {
      list.push(...expressionParser(question.showLogic))
      condition.value.key = /&/.test(question.showLogic) ? 1 : 2
    }
    return { condition, list }
  }
  
  // 选项显示逻辑表达式解析
  const parseChoiceLogicExp = (question) => {
    // 选项显示
    const list = reactive([])
    const choices = question.choices
    for (let i = 0; i < choices.length; i++) {
      let item = {
        'choiceText': choices[i].text, 
        'choiceId': choices[i].choiceId,
        'condition': { key: 1 }, // 1：全部；2：任一
        'condQuestList': [], // 条件题目
        // 因为需求中，添加条件题目是与选项绑定的，因此加上下面两个字段
        // 用来控制添加选项中的条件组件
        'isShowSelect': false,
        'selectRefVal': { key: -1},
        'enableChooseQuestionList': buildEnableChooseQuestionList(question.sort, [])
      }
      if (choices[i].showLogic) {
        item.condQuestList = expressionParser(choices[i].showLogic) // 每一个选项的显示条件 
        item.condition = /&/.test(choices[i].showLogic) ? { key: 1} : { key: 2 } // 1：全部；2：任一
        item.enableChooseQuestionList = buildEnableChooseQuestionList(question.sort, item.condQuestList) // 可选的条件题目
      }
      list.push(item)
    }
    // 选项引用
    const choiceRefVal = reactive({ key: -1 })
    if (question.ref && /^(SINGLE_CHOICE|MULTIPLE_CHOICE)$/.test(question.questionType)) {
      // choiceRefVal.key = Number(question.ref)
      choiceRefVal.key = question.ref
    }
    return { choiceRefVal, list }
  }

  // 行标题引用逻辑表达式解析
  const parseRowTitleLogicExp = (question) => {
    const rowRefVal = reactive({ key: -1 })
    if (question.ref && /^(MATRIX_SINGLE_CHOICE|MATRIX_MULTIPLE_CHOICE|MATRIX_SCALE)$/.test(question.questionType)) {
      rowRefVal.key = question.ref
    }
    return { rowRefVal }
  }
  
  return {
    parseQuestionLogicExp,
    parseChoiceLogicExp,
    parseRowTitleLogicExp,
    buildSingleOrMultiple,
    buildMatrix,
  }
}

export default useParseExpEffect