<!--
 * @Description: 跳题设置模态窗
 * @version: 
 * @Author: PSG
 * @Date: 2021-04-20 16:35:45
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-29 14:34:35
-->
<template>
  <div class="jump-logic-modal">
    <div class="modal-mask"></div>
    <div class="modal-wrap">
      <div class="modal">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-header-title">跳转设置</div>
            <span class="modal-header-desc">（如果更换当前题目顺序，会导致跳题逻辑失效）</span>
            <span 
              class="iconfont close"
              @click="handleCloseClick"
            >
              &#xe61c;
            </span>
          </div>
          <div class="modal-body">
            <!-- 选项跳题，只针对单选、下拉题、量表 -->
            <div class="modal-body-condition" v-if="isSingleOrDropDown">
              <div class="condition-top">
                <a-switch 
                  class="switch" 
                  v-model:checked="conditionJumpChecked" 
                  size="small"
                  @change="conditionCheckedChange"
                />
                <span class="text">按选项跳题</span>
              </div>
              <div 
                class="condition-body"
                v-show="conditionJumpChecked"
              >
                <div class="rich-text">
                  <p>当前题目为：</p>
                  <RichTitle class="rich-title" :title="question.title">
                    <span class="title-tips">({{questionTypeList[question.questionType]}})</span>
                  </RichTitle>
                </div>
                <a-row class="form-title">
                  <a-col :span="2">操作</a-col>
                  <a-col :span="8" class="center">选项</a-col>
                  <a-col :span="1" />
                  <!-- <a-col :span="2" class="center"></a-col> -->
                  <a-col :span="12" class="center">跳转到</a-col>
                </a-row>
                <div class="form-body">
                  <a-row class="form-row" v-for="(choice, index) in questionCopy.choices" :key="index">
                    <a-col :span="2">选择</a-col>
                    <a-col :span="8" :title="choice.text" class="ellipsis">{{choice.text}}</a-col>
                    <a-col :span="1" />
                    <!-- <a-col :span="2" class="center"></a-col> -->
                    <a-col :span="12">
                      <a-select 
                        label-in-value
                        class="jump-to-select"
                        :bordered="false"
                        v-model:value="choice.jump"
                      >
                        <a-select-option :value="-1">按顺序填写下一题</a-select-option>
                        <a-select-option :value="-2">跳转到末尾结束作答</a-select-option>
                        <a-select-option 
                          v-for="item in afterQuestNameList"
                          :key="item.questionId"
                          :value="item.questionId"
                        >
                        <div class="item" style="display: flex">
                          {{item.sort + 1}}、<RichTitle :title="item.title"/>
                        </div>
                        </a-select-option>
                      </a-select>
                    </a-col>
                  </a-row>
                </div>
              </div>
            </div>
            <!-- 无条件跳题，针对所有题型 -->
            <div class="modal-body-noconditio">
              <div class="condition-top">
                 <a-switch 
                  class="switch" 
                  v-model:checked="noConditionJumpChecked" 
                  size="small"
                  @change="noConditionCheckedChange"
                />
                <span class="text">无条件跳题</span>
              </div>
              <div 
                class="condition-body"
                v-show="noConditionJumpChecked"
              >
                <span>填写此题后，跳转到：</span>
                <a-select 
                  label-in-value 
                  class="focus-jump"
                  :bordered="false"
                  v-model:value="noCondJumpVal"
                >
                 <a-select-option :value="-1">按顺序填写下一题</a-select-option>
                  <a-select-option :value="-2">跳转到末尾结束作答</a-select-option>
                  <a-select-option 
                    v-for="item in afterQuestNameList"
                    :key="item.questionId"
                    :value="item.questionId"
                  >
                    <div class="item" style="display: flex">
                      {{item.sort + 1}}、<RichTitle :title="item.title"/>
                    </div>
                  </a-select-option>
                </a-select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="bottom-finish" @click="handleFinishClick">完成编辑</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from 'vue'
import useCommonConstructEffect from '@/effects/constructEffect'
import globalQuestionnaire from '@/class/useQuestionnaire'
import RichTitle from '../question/components/richTitle'


/**
 * 将单选、下拉的题目数据结构构建成跳题逻辑需要的数据结构
 * 针对的是选项跳题，给每个选项添加jump字段，html遍历choice时就可以根据此字段进行显示跳到哪题
 */
const contructEffect = (question) => {
  const questionCopy = reactive(JSON.parse(JSON.stringify(question)))
  // 条件跳题只针对单选、下拉、量表
  if (!/^(SINGLE_CHOICE|DROP_DOWN|SCALE)$/.test(questionCopy.questionType)) {
    return questionCopy
  }
  // 1、为每一个选项添加jump字段，默认是-1
  questionCopy.choices.forEach(choice => {
    choice.jump = { key: -1 }
  })
  // 2、检查question.jumpLogic是否存在
  if (questionCopy.jumpLogic) {
    // 2.1、存在
    const logicMap = JSON.parse(questionCopy.jumpLogic)
    if (logicMap instanceof Object) {
      // 2.1.1、条件跳题 解析 赋值choice
      for (let key in logicMap) {
        questionCopy.choices.forEach(choice => {
          if (choice.choiceId === key) choice.jump = {key: logicMap[key]}
        })
      }
    } else {
      // 2.1.2、无条件跳题 解析 赋值jumpVal
    // jumpVal.value = logicMap
    }
  } else {
    // 2.2、不存在，不用处理
  }
  return questionCopy
}

/**
 * 设置无条件跳题
 */
const noConditionJumpEffect = (question) => {
  const noCondJumpVal = ref({ key: -1 })
  // 检查question.jumpLogic是否存在
  if (question.jumpLogic) {
    const logicMap = JSON.parse(question.jumpLogic)
    // 无条件跳题 解析 赋值jumpVal
    if (!(logicMap instanceof Object)) {
      noCondJumpVal.value = { key: logicMap}
    }
  }
  return {
    noCondJumpVal
  }
}

/**
 * 获取本题后面题目标题
 */
const getAfterQuestNameList = (question) => {
  const curSort = question.sort
  const questionnaire = globalQuestionnaire.getQuestionnaire()
  const afterQuestNameList = questionnaire.questions
    .filter(item => !/PAGE|PART/.test(item.questionType) && item.sort > curSort)
    .map(item => ({ 'title': item.title, 'questionId': item.questionId, 'sort': item.sort}))
  return {
    afterQuestNameList
  }
}

/**
 * Switch按钮逻辑控制
 */
const switchEffect = (question) => {
  let isSingleOrDropDown = false // 判断是否单选、下拉题、量表题
  const conditionJumpChecked= ref(false)
  const noConditionJumpChecked= ref(false)
  
  // 条件跳题只针对单选、下拉、量表题
  if (/^(SINGLE_CHOICE|DROP_DOWN|SCALE)$/.test(question.questionType)) {
    isSingleOrDropDown = true
  }
  
  // 1、判断是否存在逻辑跳题
  if (question.jumpLogic) {
    const logicMap = JSON.parse(question.jumpLogic)
    // 1.1、选线跳题
    if (logicMap instanceof Object) {
      conditionJumpChecked.value = true
    } else {
    // 1.2、无条件跳题
      noConditionJumpChecked.value = true
    }
  }

  // 选项跳题switch事件
  const conditionCheckedChange = (checked) => {
    if (checked) {
      if (noConditionJumpChecked.value) {
        noConditionJumpChecked.value = false
      }
    }
  }

  // 无条件跳题switch事件
  const noConditionCheckedChange = (checked) => {
    if (checked) {
      if (conditionJumpChecked.value) {
        conditionJumpChecked.value = false
      }
    }
  }
  return {
    isSingleOrDropDown,
    conditionJumpChecked,
    noConditionJumpChecked,
    conditionCheckedChange,
    noConditionCheckedChange,
  }
}

/**
 * 完成编辑事件
 */
const handleFinishEditEffect = (question, conditionJumpChecked, noConditionJumpChecked, noCondJumpVal, props, emit) => {
  const { insertLogicTips } = useCommonConstructEffect()
  const questionnaire = globalQuestionnaire.getQuestionnaire()
  const clearCondJumpData = () => {
    question.isCondJump = false
    question.choices.forEach(choice => choice.jumpLogicTips = null)
    question.isCondJump = null
  }
  const clearNoCondJumpData = () => {
    question.isNoCondJump = false
    question.isNoCondJump = null
  }
  const handleFinishClick = () => {
    // 检查switch
    //    条件跳题
    //        遍历每一个选项，构建jumpLogic字段
    //        如果每一个选项选的都是-1（跳到下一题），则将jumpLogic设为null
    //    无条件跳题
    //        获取noCondjumpVal，构建jumpLogic字段
    //        如果选择的是-1，则jumpLogic设为null
    //    两个switch都关闭
    //        清空question.jumpLogic字段
    let jumpLogic = null
    if (conditionJumpChecked.value) {
      clearNoCondJumpData()
      let isAllMinusOne = question.choices.every(choice => choice.jump.key === -1) // true 代表所有选项都是选 -1（按顺序填写下一题）
      if (!isAllMinusOne) {
        jumpLogic = {}
        question.choices.forEach((choice) => {
          if (choice.jump.key !== -1 ) {
            jumpLogic[choice.choiceId] = choice.jump.key
          }
        })
      }
    } else if (noConditionJumpChecked.value && noCondJumpVal.value.key !== -1) {
      clearCondJumpData()
      jumpLogic = noCondJumpVal.value.key
    } else {
      jumpLogic = null
    }
    props.question.jumpLogic = (jumpLogic !== null ? JSON.stringify(jumpLogic) : null)
    insertLogicTips(questionnaire)
    emit('finishEdit')
  }
  return {
    handleFinishClick,
  }
}

const questionTypeList = {
  SINGLE_CHOICE: '单选题',
  MULTIPLE_CHOICE: '多选题',
  DROP_DOWN: '下拉题',
  SCALE: '量表题',
  SHORT: '简答题',
  DATE: '日期题',
  MATRIX_SINGLE_CHOICE: '矩阵单选题',
  MATRIX_MULTIPLE_CHOICE: '矩阵多选题',
  MATRIX_SCALE: '矩阵量表题',
}

/**
 * 处理发布相关事件
 */
const handleEmitEffect = (emit) => {
  const handleCloseClick = () => {
    emit('finishEdit')
  }
  return {
    handleCloseClick,
  }
}

export default {
  name: 'JumpLogicModal',
  props: [
    'question'
  ],
  emits: [
    'finishEdit'
  ],
  components: {
    RichTitle,
  },
  setup (props, { emit }) {
    const questionCopy = contructEffect(props.question)
    const { noCondJumpVal } = noConditionJumpEffect(questionCopy)
    const { afterQuestNameList } = getAfterQuestNameList(questionCopy)
    const { isSingleOrDropDown, conditionJumpChecked, noConditionJumpChecked, conditionCheckedChange, noConditionCheckedChange } = switchEffect(questionCopy)
    const { handleFinishClick } = handleFinishEditEffect(questionCopy, conditionJumpChecked, noConditionJumpChecked, noCondJumpVal, props, emit)
    const { handleCloseClick } = handleEmitEffect(emit)

    return {
      questionCopy,
      noCondJumpVal,
      afterQuestNameList,

      isSingleOrDropDown,
      conditionJumpChecked,
      noConditionJumpChecked,
      conditionCheckedChange,
      noConditionCheckedChange,
      handleFinishClick,
      handleCloseClick,
      questionTypeList,
    }
  }
}
</script>

<style lang="less" scoped>
.jump-logic-modal {
}
.title-tips {
  word-break: keep-all;
  margin-left: 8px;
  font-weight: normal;
}
.rich-text {
  display: flex;
  margin-bottom: 20px;
  font-weight: bold;
  >p {
    flex: 0 0 100px;
  }
  .rich-title {
    ::v-deep(p) {
      display: inline;
    }
  }
}
.modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  .modal {
    box-sizing: border-box;
    width: 1046px;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.5715;
    list-style: none;
    position: relative;
    top: 150px;
    margin: 0 auto;
    padding-bottom: 24px;
    pointer-events: none;
    .modal-content {
      position: relative;
      border: 16px solid #fff;
      border-radius: 16px;
      background-color: #fff;
      pointer-events: auto;
      .modal-header {
        position: relative;
        padding: 16px 24px;
        color: rgba(0, 0, 0, 0.85);
        background: #fff;
        border-radius: 0.125rem 0.125rem 0 0;
        box-sizing: border-box;
        .modal-header-title {
          width: 90px;
          font-size: 20px;
          font-weight: bold;
          word-wrap: break-word;
          display: inline-block;
        }
        .modal-header-desc {
          width: 120px;
          word-wrap: break-word;
          color: #00A0E9;
        }
        .close {
          position: absolute;
          top: 14px;
          right: 37px;
          font-size: 25px;
          color: #00A0E9;
          .pointer()
        }
      }
      .modal-body {
        .modal-body-condition {
          .condition-top {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .circle {
              display: inline-block;
              margin-left: 10px;
              margin-right: 6px;
              font-size: 18px;
              color: #000;
            }
            .text {
              display: inline-block;
              font-size: 16px;
              color: #2D2D33;
            }
            .switch {
              margin-left: 24px;
              margin-right: 7px;
            }
          }
          .condition-body {
            box-sizing: border-box;
            padding: 0 0 30px 40px;
            .rich-text {
              display: flex;
              margin-bottom: 20px;
              font-weight: bold;
            }
            .form-title {
              color: #888888;
            }
            .form-body {
              max-height: 400px;
              overflow: auto;
              line-height: 41px;
              .form-row {
                margin: 20px 0;
                height: 41px;
                font-weight: 100;
                .jump-to-select {
                  width: 100%;
                  height: 41px;
                  border: 1px solid #E1E1E8;
                  ::v-deep(.ant-select-selector) {
                    height: 41px;
                    border-radius: 0px;
                    .ant-select-selection-item {
                      line-height: 41px;
                    }
                  }
                }
              }
            }
            .center {
              text-align: center;
              .ellipsis()
            }
          }
        }
        .modal-body-noconditio {
          margin-bottom: 20px;
          .condition-top {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .circle {
              display: inline-block;
              margin-left: 10px;
              margin-right: 6px;
              font-size: 18px;
              color: #000;
            }
            .text {
              display: inline-block;
              font-size: 16px;
              color: #2D2D33;
            }
            .switch {
              margin-left: 24px;
              margin-right: 7px;
            }
          }
          .condition-body {
            margin-left: 40px;
            .focus-jump {
              width: 466px;
              height: 41px;
              border: 1px solid #E1E1E8;
              font-weight: 100;
              ::v-deep(.ant-select-selector) {
                border: 1px solid #E1E1E8;
                height: 41px;
                border-radius: 0px;
                .ant-select-selection-item {
                  line-height: 41px;
                }
              }
            }
          }
        }
      }
      .modal-footer {
        margin: 34px 0;
        .bottom-finish {
          margin: 0 auto;
          width: 140px;
          height: 36px;
          font-weight: 400;
          font-size: 18px;
          text-align: center;
          line-height: 36px;
          color: #FFFFFF;
          border-radius: 4px;
          background-color: #00A0E9;
          cursor: pointer;
        }
      }
    }
  }
}
</style>>