<!--
 * @Description: 设置底部配置栏
 * @version: 
 * @Author: HWL
 * @Date: 2021-05-27 17:09:34
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-13 17:41:44
-->
<template>
  <div class="setting-bottom">
    <div v-if="!route.query.isKeepAnswerAndEdit" class="base-setting">
      <span>基础设置：</span>
      <span class="bottom-must" style="font-weight: 100">必填</span>
      <a-switch size="small" class="bottom-switch" v-model:checked="question.ifRequired"/>
      <span v-if="type === 'MATRIX_SCALE'" class="bottom-random">分值显示</span>
      <a-switch v-if="type === 'MATRIX_SCALE'" size="small" class="bottom-switch" v-model:checked="question.ifShowValue"/>
      <a-select 
        v-if="type.indexOf('SCALE') > - 1"
        class="min-selcet"
        style="width: 129px; marginLeft: 10px;"
        placeholder="量表模版"
        v-model:value="scaleTemplate"
        @change="handleScaleTemplateChange(question)"
      >
        <a-select-option v-for="i of scaleTemplateList" :key="i" :value="i">{{i}}级量表</a-select-option>
      </a-select>
      <span v-if="/^(SINGLE_CHOICE|MULTIPLE_CHOICE|DROP_DOWN)/.test(type)" class="bottom-random">选项随机</span>
      <a-switch v-if="/^(SINGLE_CHOICE|MULTIPLE_CHOICE|DROP_DOWN)/.test(type)" size="small" class="bottom-switch" v-model:checked="question.ifMessUp"/>
      <a-select
        v-if="type === 'MULTIPLE_CHOICE'"
        class="min-selcet"
        allowClear
        placeholder="至少选择几项"
        v-model:value="question.minSelect"
        @change="handleMinSelectChange(question)"
      >
        <a-select-option v-for="i in question.choices.length" :key="i" :value="i">
          至少选择{{i}}项
        </a-select-option>
      </a-select>
      <a-select 
        v-if="type === 'MULTIPLE_CHOICE'"
        class="min-selcet"
        style="marginLeft: 10px;"
        allowClear
        placeholder="至多选择几项"
        v-model:value="question.maxSelect"
      >
        <a-select-option 
          v-for="i in question.choices.length" 
          :key="i" 
          :value="i" 
          :disabled=" question.minSelect && i < question.minSelect"
        >
          至多选择{{i}}项
        </a-select-option>
      </a-select>
      <a-select 
        v-if="type !== 'DATE' && type !== 'SHORT' && type !== 'SCALE' && type !== 'DROP_DOWN' && type.indexOf('MATRIX') === -1"
        class="min-selcet"
        style="width: 129px; marginLeft: 10px;"
        placeholder=""
        v-model:value="question.columnSplit"
      >
        <a-select-option :value="1">竖向排列</a-select-option>
        <a-select-option :value="2">每行2列</a-select-option>
        <a-select-option :value="3">每行3列</a-select-option>
      </a-select>
      <a-select
          v-if="type === 'MATRIX_MULTIPLE_CHOICE'"
          class="min-selcet"
          allowClear
          placeholder="每行至少选择几项"
          style="width: 170px; marginLeft: 10px;"
          v-model:value="question.minSelect"
          @change="handleMinSelectChange(question)"
        >
          <a-select-option v-for="i in question.columnItems.length" :key="i" :value="i">
            每行至少选择{{i}}项
          </a-select-option>
        </a-select>
        <a-select 
          v-if="type === 'MATRIX_MULTIPLE_CHOICE'"
          class="min-selcet"
          allowClear
          placeholder="每行至多选择几项"
          style="width: 170px; marginLeft: 10px;"
          v-model:value="question.maxSelect"
          
        >
          <a-select-option
            v-for="i in question.columnItems.length" 
            :key="i" 
            :value="i"
            :disabled=" question.minSelect && i < question.minSelect"
          >
            每行至多选择{{i}}项
          </a-select-option>
        </a-select>
        <a-select
          v-if="type === 'DATE'"
          v-model:value="question.format" 
          class="min-select"
          style="width: 129px; marginLeft: 10px; color: #7F7F88;"
          @change="handleDateTypeChange"
        >
          <a-select-option value="yyyy-MM">年-月</a-select-option>
          <a-select-option value="yyyy-MM-dd">年-月-日</a-select-option>
          <a-select-option value="yyyy-MM-dd HH:mm:ss">年-月-日 时间</a-select-option>
        </a-select>
      <!-- <span class="bottom-vertical">竖向排列</span> -->
    </div>
    <div v-if="!route.query.isKeepAnswerAndEdit" class="logic-setting">
      <span>逻辑设置：</span>
      <span 
        v-if="question.sort !== 0"
        class="show-setting"
        :class="{'isAlreadySet': isAlreadySetShowLogic}"
        @click="handleOpenShowLogicModal"
      >
        显示设置
      </span>
      <span
        class="jump-setting"
        :class="{'isAlreadySet': isAlreadySetJumpLogic}"
        @click="handleOpenJumpLogicModal"
      >
        跳转设置
      </span>
    </div>
    <div class="bottom-finish" @click="finished">完成编辑</div>
  </div>
  <div v-for="(item, index) in question.choices" :key="index" style="display: none">
    <a-switch size="small" v-model:checked="item.ifAllowFillInBlanks" />
    <a-switch size="small" v-model:checked="item.ifRequired" />
    <br />
  </div>
  <JumpLogicModal
    v-if="isOpenJumpLogicModal"
    @finishEdit="fininshJumpLogicEdit"
    :question="question"
  />
  <ShowLogicModal
    v-if="isOpenShowLogicModal"
    @closeShowLogicModal="closeShowLogicModal"
    @finishEdit="fininshShowLogicEdit"
    :question="question"
  />
</template>
<script>
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

import useCommonQuestionnaireEffect from '@/effects/questionnaireEffect'
import scaleTemplateEffect from './effects/scaleTemplateEffect'

import JumpLogicModal from '@/components/logic/JumpLogicModal'
import ShowLogicModal from '@/components/logic/ShowLogicModal'
import { ref } from '@vue/reactivity'

export default {
  name: 'SettingBottom',
  props: [
    'question',
    'type'
  ],
  emits: [
    'finished'
  ],
  components: { 
    JumpLogicModal,
    ShowLogicModal,
  },
  setup(props, { emit }) {
    const route = useRoute()
    const store = useStore()
    const dataSelectVal = ref({key: 'yyyy-MM-dd HH:mm:ss'});
    
    function handleDateTypeChange (data) {
      dataSelectVal.value.key = data.key
    }

    const { jumpLogicEffect, showLogicEffect } = useCommonQuestionnaireEffect()

    const finished = () => {
      emit('finished')
      store.commit('changeCurEditQuestionId', '') 
    }

    function handleMinSelectChange(question) { // 最少选择的项数发生改变
      if ( question.minSelect > question.maxSelect) {
        question.maxSelect = undefined
      }
    }

    const { scaleTemplateList, scaleTemplate, handleScaleTemplateChange } = scaleTemplateEffect()

    return {
      route,
      // isAlreadySetJumpLogic, isOpenJumpLogicModal, handleOpenJumpLogicModal, fininshJumpLogicEdit
      ...jumpLogicEffect(props.question),
      // isAlreadySetShowLogic, isOpenShowLogicModal, handleOpenShowLogicModal, fininshShowLogicEdit, closeShowLogicModal
      ...showLogicEffect(props.question),
      finished,
      handleMinSelectChange,
      handleDateTypeChange,

      scaleTemplateList, scaleTemplate, handleScaleTemplateChange
    }
  }
}
</script>
<style lang="less" scoped>
.setting-bottom {
  margin-top: 2px;
  padding: 22px 32px 16px 32px;
  border-top-right-radius: 10px;
  .ant-select-selector {
    height: 44px;
    .ant-select-selection-item {
      line-height: 44px;
    }
  }
  .bottom-random {
    font-weight: 100;
  }
  .base-setting {
    .bottom-desc {
      text-decoration: underline;
      margin-right: 16px;
      &:hover {
        background-color: @hoverBackground;
        cursor:pointer;
      }
    }
    .bottom-switch {
      margin: 0 12px;
    }
    .bottom-vertical {
      display: inline-block;
      border: 1px solid #d7d7d7;
      border-radius: 16px;
      font-size: 13px;
      width: 82px;
      height: 26px;
      line-height: 26px;
      text-align: center;
      color: #727272;
      margin-left: 10px;
    }
  }
  .logic-setting {
    margin-top: 16px;
    .show-setting {
      font-weight: 100;
      margin-right: 10px;
      text-decoration: underline;
      .pointer()
    }
    .jump-setting {
      font-weight: 100;
      text-decoration: underline;
      .pointer()
    }
  }
  .bottom-finish {
    margin: 34px auto 10px;
    width: 140px;
    height: 36px;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    line-height: 36px;
    color: #FFFFFF;
    border-radius: 4px;
    background-color: #00A0E9;
    cursor: pointer;
  }
}
.isAlreadySet {
  color: #00A0E9;
}
</style>