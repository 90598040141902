/*
 * @Description: 矩阵宽度计算
 * @version: 
 * @Author: HWL
 * @Date: 2021-07-12 14:52:56
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-13 16:03:25
 */

import { ref, watch } from 'vue'

function rowWidthEffect(question, visibileRowRefArea = false) {
  const rowWidthList = ref([])
  const rowTitleWidth = ref(11)
  // const rowKey = visibileRowRefArea ? 'refRowItems' : 'rowItems'
  // question.rowItems.forEach((rowItem) => {
  //   const width = (rowItem.text.length * 16 + 48) / 16
  //   if (width > rowTitleWidth.value) {
  //     rowTitleWidth.value = width
  //   }
  // })
  question.columnItems.forEach((colunmItem) => {
    const width = (colunmItem.text.length * 16 + 48) / 16
    rowWidthList.value.push(width)
  })

  // watch(() => question[rowKey], () => {
  //   rowTitleWidth.value = 0
  //   question[rowKey].forEach((rowItem) => {
  //     const width = (rowItem.text.length * 16 + 48) / 16
  //     if (width > rowTitleWidth.value) {
  //       rowTitleWidth.value = width
  //     }
  //   })
  // }, {
  //   deep: true
  // })

  watch(() => question.columnItems, () => {
    const length = question.columnItems.length
    for (let index = 0; index < length; index++) {
      const colunmItem = question.columnItems[index]
      const width = (colunmItem.text.length * 16 + 48) / 16
      rowWidthList.value[index] = width
    }
  }, {
    deep: true
  })

  return {
    rowWidthList,
    rowTitleWidth
  }
}

export default rowWidthEffect
