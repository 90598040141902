<!--
 * @Description: 多选题
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-18 18:54:03
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-29 11:28:32
-->
<template>
  <!-- 电脑端 -->
  <div class="multiple" v-if="isPc"
    :class="{noborder: !isSettingsActive}" 
  >
    <div class="sort-icon">
      <div class="preview-sort iconfont" v-if="!isOnlyRead">&#xe697;</div>
    </div>
    <div 
      class="preview-del iconfont" 
      @click.stop="handleDelete" 
      v-if="!isOnlyRead && !route.query.isKeepAnswerAndEdit"
    >&#xe609;</div>
    <div 
      class="preview-copy iconfont" 
      @click.stop="handleCopy" 
      v-if="!isOnlyRead && !route.query.isKeepAnswerAndEdit"
    >&#xe62f;</div>
    <div class="preview" @click="edit(question.questionId)">
      <PreviewTitle :isOnlyRead="isOnlyRead" :question="question" :isPc="isPc"/>
      <div class="preview-options column-flex-layout">
        <a-checkbox-group v-model:value="radioValue" :disabled="isDisabled" @change="handleCheckChange">
          <!-- 乱序 -->
          <div v-if="question.ifMessUp">
            <!-- 非选项引用区域 -->
            <template
              v-for="item in randomChoices"
              :key="item.choiceId + '1'"
              v-if="!visibileRefChoiceArea"
            >
              <a-checkbox 
                :value="item.choiceId"
                :class="{
                  oneColumn: question.columnSplit == 1,
                  twoColumn: question.columnSplit == 2,
                  threeColumn: question.columnSplit == 3,
                }"
              >
                <span :title="item.text ? item.text : '选项'">
                  {{item.text ? item.text : "选项"}}
                </span>
                <span v-if="item.showLogicTips && (isOnlyRead || route.name==='QuestionEdit' || route.name==='Preview')" class="preview-options-tips">({{item.showLogicTips}})</span>
                <div class="fill-text">
                  <span v-if="item.ifAllowFillInBlanks && item.ifRequired" style="color: red;">*</span>
                  <a-input 
                    v-model:value="item.fillText" 
                    v-if="item.ifAllowFillInBlanks"
                    @blur="handleCheckChange"
                  />
                </div>
                <div class="preview-options-desc" v-if="item.description">{{item.description}}</div>
              </a-checkbox>
            </template>
            <!-- 选项引用区域 -->
            <template
              v-for="item in question.refChoices"
              :key="item.choiceId"
              v-else
            >
              <a-checkbox 
                :value="item.choiceId"
                :class="{
                  oneColumn: question.columnSplit == 1,
                  twoColumn: question.columnSplit == 2,
                  threeColumn: question.columnSplit == 3,
                }"
              >
                <span :title="item.text ? item.text : '选项'">
                  {{item.text ? item.text : "选项"}}
                </span>
                <span v-if="item.showLogicTips && (isOnlyRead || route.name==='QuestionEdit' || route.name==='Preview')" class="preview-options-tips">({{item.showLogicTips}})</span>
                <div class="fill-text">
                  <span v-if="item.ifAllowFillInBlanks && item.ifRequired" style="color: red;">*</span>
                  <a-input 
                    v-model:value="item.fillText" 
                    v-if="item.ifAllowFillInBlanks" 
                    @blur="handleCheckChange"
                  />
                </div>
                <div class="preview-options-desc" v-if="item.description">{{item.description}}</div>
              </a-checkbox>
            </template>
          </div>
          <!-- 顺序 -->
          <div v-else>
            <template 
              v-for="(item) in question[choiceKey]"
              :key="item.choiceId"
            >
              <a-checkbox 
                :value="item.choiceId"
                :class="{
                  oneColumn: question.columnSplit == 1,
                  twoColumn: question.columnSplit == 2,
                  threeColumn: question.columnSplit == 3,
                }"
                v-show="item.visible || isPreveiw || route.name==='QuestionEdit'"
              >
                <span :title="item.text ? item.text : '选项'">
                  {{item.text ? item.text : "选项"}}
                </span>
                <span v-if="item.showLogicTips  && (route.name==='QuestionEdit' || route.name==='Preview')" class="preview-options-tips">({{item.showLogicTips}})</span>
                <div class="fill-text">
                  <span v-if="item.ifAllowFillInBlanks && item.ifRequired" style="color: red;">*</span>
                  <a-input 
                    v-model:value="item.fillText" 
                    v-if="item.ifAllowFillInBlanks" 
                    @blur="handleCheckChange"
                  />
                </div>
                <div class="preview-options-desc" v-if="item.description">{{item.description}}</div>
              </a-checkbox>
            </template>
          </div>
        </a-checkbox-group>
      </div>
      <div class="preview-tips">
        <div class="tips" v-if="item.isNoCondJump">逻辑设置：{{item.jumpLogicTips}}</div>
        <div class="tips" v-if="item.showLogicTips">逻辑设置：本题设置了题目显示逻辑：{{item.showLogicTips}}</div>
        <div class="tips" v-if="item.refTips && visibleRefTips">{{item.refTips}}</div>
      </div>
      <PreviewBottom v-if="isOnlyRead" />
    </div>
    <PreviewBottom v-if="!isOnlyRead" style="background: #fff"/>
    <setting
      v-if="isSettingsActive"
      type="MULTIPLE_CHOICE"
      :item="item"
      :index="index"
      :isOnlyRead="isOnlyRead"
      :question="question"
      @finished="finished"
    />
  </div>
  <!-- 手机端 -->
  <div class="mobile-multiple" v-else>
    <div class="preview">
      <PreviewTitle :isOnlyRead="isOnlyRead" :question="question" :isPc="isPc"/>
      <div class="preview-options">
        <a-checkbox-group v-model:value="radioValue" @change="handleCheckChange">
          <!-- 乱序 -->
          <div v-if="question.ifMessUp">
            <!-- 非选项引用区域 -->
            <template 
              v-for="(item) in randomChoices"
              :key="item.choiceId + '1'"
              v-if="!visibileRefChoiceArea"
            >
            <a-checkbox 
              :value="item.choiceId"
              :class="{
                oneColumn: question.columnSplit == 1,
                twoColumn: question.columnSplit > 1 
              }"
            >
              <div>{{item.text}}</div>
              <div class="preview-options-desc" v-if="item.description">{{item.description}}</div>
              <a-textarea
                class="preview-options-textarea"
                v-if="item.ifAllowFillInBlanks" 
                v-model:value="item.fillText"
                placeholder="请填写..." 
                @blur="handleCheckChange"
              />
              <span class="preview-options-star" v-if="item.ifRequired&&radioValue.indexOf(item.choiceId)>=0">*</span>
            </a-checkbox>
            </template>
            <!-- 选项引用区域 -->
            <template 
              v-for="item in question.refChoices"
              :key="item.choiceId"
              v-else
            >
            <a-checkbox 
              :value="item.choiceId"
              v-show="item.visible"
              :class="{
                oneColumn: question.columnSplit == 1,
                twoColumn: question.columnSplit > 1 
              }"
            >
              <div>{{item.text}}</div>
              <div class="preview-options-desc" v-if="item.description">{{item.description}}</div>
              <a-textarea
                class="preview-options-textarea"
                v-if="item.ifAllowFillInBlanks" 
                v-model:value="item.fillText"
                placeholder="请填写..." 
                @blur="handleCheckChange"
              />
              <span class="preview-options-star" v-if="item.ifRequired&&radioValue.indexOf(item.choiceId)>=0">*</span>
            </a-checkbox>
            </template>
          </div>
          <!-- 顺序 -->
          <div v-else>
            <template 
              v-for="(item) in question[choiceKey]"
              :key="item.choiceId"
            >
            <a-checkbox 
              :value="item.choiceId"
              v-show="item.visible"
              :class="{
                oneColumn: question.columnSplit == 1,
                twoColumn: question.columnSplit > 1 
              }"
            >
              <div>{{item.text}}</div>
              <div class="preview-options-desc" v-if="item.description">{{item.description}}</div>
              <a-textarea
                class="preview-options-textarea"
                v-if="item.ifAllowFillInBlanks" 
                v-model:value="item.fillText"
                placeholder="请填写..." 
                @blur="handleCheckChange"
              />
              <span class="preview-options-star" v-if="item.ifRequired&&radioValue.indexOf(item.choiceId)>=0">*</span>
            </a-checkbox>
            </template>
          </div>
        </a-checkbox-group>
      </div>
      <div class="preview-tips">
        <div class="tips" v-if="item.refTips && visibleRefTips">{{item.refTips}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { message } from 'ant-design-vue';
import useCommonQuestionnaireEffect from '@/effects/questionnaireEffect'
import logicWatcherEffect from '@/effects/logicWatcherEffect'
import choiceRandomEffect from './components/effects/choiceRandomEffect'
import Setting from './components/setting'
import PreviewTitle from './components/previewTitle'
import PreviewBottom from './components/previewBottom'

/**
 * 答案管理
 */
const answerEffect = () => {
  const route = useRoute()
  // 答案获取
  const getAnswer = (question, checkedArr) => {
    const choices = question.ref ? question.refChoices : question.choices
    const answer = {
      "questionType": question.questionType, 
      "questionId": question.questionId,
      "choices": []
    }
    for (let i = 0; i < checkedArr.length; i++) {
      let curAnswerChoiceId = checkedArr[i]
      for (let j = 0; j < choices.length; j++) {
        if (curAnswerChoiceId === choices[j].choiceId) {
          answer.choices.push({
            "choiceId": curAnswerChoiceId,
            "fillText": choices[j].fillText
          })
          break
        }
      }
    }
    return answer
  }

  // 答案填写
  // isAreadyAnswerFill，只需要初始化组件时执行一次即可
  const fillAnswer = (question, radioValue, isAreadyAnswerFill) => {
    if (route.name === 'RecycleBin') isAreadyAnswerFill.value = false // 这种是特殊情况，当在回收情况、查看问卷、点击上一份、下一份时，是允许重新填写答案并展示出来的
    if (!isAreadyAnswerFill.value) {
      isAreadyAnswerFill.value = true
      radioValue.value = question.answer.choices.map(item => { return item.choiceId })
      for (let i = 0; i < question.answer.choices.length; i++) {
        let curAnswerChoice = question.answer.choices[i]
        for (let j = 0; j < question.choices.length; j++) {
          if (curAnswerChoice.choiceId === question.choices[j].choiceId) {
            question.choices[j].fillText = curAnswerChoice.fillText
            break
          }
        }
      }
    }
  }

  // 实时填写答案
  const fillAnswerIntime = (question, value) => {
    // 这里使用watch的原因是因为，要监听填空题的填写
    watch(() => question.answer, (cur, pre) => {
      watchVisibilityEffect(question)
    })     
    const { watchVisibilityEffect } = logicWatcherEffect()
    question.answer = getAnswer(question, value)
    document.getElementById(question.questionId).classList.remove('error')
  }
  
  return {
    getAnswer,
    fillAnswer,
    fillAnswerIntime
  }
}

export default {
  props: ['item', 'index', 'isOnlyRead', 'isPreveiw', 'isDisabled', 'isPc'],
  components: {
    Setting,
    PreviewTitle,
    PreviewBottom
  },
  name: 'MultipleChoice',
  setup(props) {
    const store = useStore()
    const route = useRoute()
    const routeName = ref(route.name)
    const radioValue = ref([])
    const selectVal = ref({key: 'MULTIPLE_CHOICE'})
    const isAreadyAnswerFill = ref(false)
    const { getAnswer, fillAnswer, fillAnswerIntime } = answerEffect()
    const question = computed({
      get: () => {
        props.item.choices.forEach(choice => {
          if (choice.fillText === undefined) {
            choice.fillText = ''
          }
        })
        if (!props.item.answer?.questionId) {
          props.item.answer = {}
        } else {
          fillAnswer(props.item, radioValue, isAreadyAnswerFill)
        }
        return props.item || {} // props.item 是异步获取的，初始值为undefined
      }
    })
    watch (() => store.state.curEditingQuestonId, (cur, pre) => {
      if (/^(Preview|Exercise)$/.test(route.name)) return
      if (cur === question.value.questionId) {
        isSettingsActive.value = props.isOnlyRead ? false : true
      } else {
        isSettingsActive.value = false
      }
    })

    function handleCheckChange() { // 选择的项发生改变，
      if (radioValue.value.length > 0) { // 控制互斥项
        const choices = question.value.choices
        const last = radioValue.value[radioValue.value.length - 1] // 最后一项
        const lastTwo = radioValue.value[radioValue.value.length - 2] // 倒数第二项
        for (let i = 0; i < choices.length; i++) {
          if (choices[i].choiceId == last) { // 找到最后一项
            if (choices[i].ifMutuallyExclusive) { // 判断该项是否是互斥项
              radioValue.value = [last] // 设置选中的项
              console.log('')
            } else if (lastTwo) { // 是否存在倒数第二项
              for (let j = 0; j < choices.length; j++) {
                if (choices[j].choiceId == lastTwo) { // 找到倒数第二项
                  if (choices[j].ifMutuallyExclusive) { // 判断该项是否是互斥项
                    radioValue.value = [last] // 设置选中的项
                  }
                  break
                }
              }
            }
            break
          }
        }
      }
      fillAnswerIntime(question.value, radioValue.value)
      if (!question.value.maxSelect) return // 当不存在maxSelect时退出
      if (radioValue.value.length > question.value.maxSelect) { // 控制最多只能选几项
        radioValue.value = radioValue.value.slice(0, question.value.maxSelect)
        message.error(`最多选择${question.value.maxSelect}项`)
      }
      // console.log('radioValue.value', radioValue.value)
    }

    function handleMinSelectChange() { // 最少选择的项数发生改变
      if ( question.value.minSelect > question.value.maxSelect) {
        question.value.maxSelect = undefined
      }
    }
    
    const { handleEffect, questionEditStatusChangeEffect, visibileRefChoiceEffect, visibileRefTipsEffect } = useCommonQuestionnaireEffect()
    const { isSettingsActive, edit } = questionEditStatusChangeEffect(props)
    const { visibileRefChoiceArea } = visibileRefChoiceEffect(props.isOnlyRead, question.value)
    const { visibleRefTips } = visibileRefTipsEffect(question)
    const { handleDelete, handleCopy } = handleEffect(props)
    const { randomChoices } = choiceRandomEffect(question.value)
    
    const choiceKey = visibileRefChoiceArea.value? 'refChoices' : 'choices'

    const finished = () => {
      isSettingsActive.value = false
    }

    edit(props.item.questionId)

    return {
      visibleRefTips,
      route,
      routeName,
      radioValue,
      selectVal,
      question,
      randomChoices,
      choiceKey,
      handleCheckChange,
      finished,
      edit,
      isSettingsActive,
      handleMinSelectChange,
      visibileRefChoiceArea,
      handleDelete,
      handleCopy,
    }
  },
}
</script>

<style scoped lang="less">
// 电脑端
.multiple {
  position: relative;
  background-color: @backgroundColor;
  border-bottom: 1px solid #E1E1E8;
  border-radius: 10px;
  
  .preview-del {
    position: absolute;
    top: 47px;
    right: 93px;
    color: #00A0E9;
    font-size: 24px;
    z-index: 1;
  }
  .preview-copy {
    position: absolute;
    top: 47px;
    right: 49px;
    color: #00A0E9;
    font-size: 24px;
    z-index: 1;
  }
  .sort-icon {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 24px;
    margin-top: 10px;
    .preview-sort {
      width: 24px;
      height: 24px;
      font-size: 24px;
      transform: rotate(90deg);
      color: #b7b7b7;
      z-index: 1;
    }
  }
  .iconfont:hover {
    background-color: @hoverBackground;
    cursor:pointer;
  }

  .preview {
    position: relative;
    padding: 40px 32px 0px 32px;
    background-color: #fff;
    .preview-title { 
      font-size: 18px;
      margin-top: 24px;
      .preview-title-star {
        color: red;
      }
      .preview-title-tips {
        color: #33333370;
        font-size: 14px;
        margin-left: 16px;
      }
    }
    .preview-descript{ 
      font-size: 14px;
      color: #aeaeae;
      margin-top: 16px;
      padding-left: 20px;
    }
    .preview-options{ 
      .preview-options-tips {
        display: inline-block;
        margin-left: 20px;
        color: #f59a23;
        font-size: 14px;
      }
      .preview-options-desc {
        margin-top: 10px;
        text-align: justify;
        font-size: 14px;
        color: #33333370;
      }
      .radio {
        display: 'block';
        height: '30px';
        line-height: '30px';
      }
    }
    .preview-tips {
      .tips {
        margin-top: 10px;
        color: #a5a5a5;
        font-size: 14px;
      }
    }
  }
  .settings {
    position: relative;
    margin-top: 10px;
    padding: 16px 32px 16px 32px;
    background-color: #fff;
    border-top-right-radius: 10px;
    .settings-type {
      position: absolute;
      top: 10px;
      right: 20px;
      width: 210px;
    }
    .settings-title {
      width: 500px;
      font-size: 18px;
    }
    .settings-toptitle {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      color: #8b8b8b;
      font-size: 14px;
      .settings-toptitle-desc {
        text-align: center;
      }
      .settings-toptitle-isfill {
        text-align: center;
      }
    }
    .settings-options {
      display: flex;
      position: relative;
      margin-top: 20px;
      .settings-options-sort {
        position: absolute;
        top: 6px;
        left: -20px;
        z-index: 99;
        color: #b7b7b7;
      }
      .settings-options-input {
        width: 400px!important;
        font-size: 16px;
      }
      .settings-options-desc {
        text-align: center;
        font-size: 26px;
      }
      .settings-options-notfill {
        text-align: center;
      }
      .settings-options-isfill {
        display: flex;
        padding-left: 30px;
        p {
          font-size: 12px;
          margin-left: 4px;
          margin-right: 10px;;
        }
        span {
          height: 26px;
          border-right: 1px solid #000;
          margin-top: -6px;
          margin-left: 10px;
        }
      }
      .settings-options-del {
  vertical-align: top;
        margin-left: 40px;
      }
      .iconfont {
        cursor:pointer;
        &:hover {
          background-color: @hoverBackground;
        }
      } 
    }
    .settings-add {
      margin-top: 20px;
      div {
        width: 120px;
        height: 36px;
        border: 1px dashed #d9d9d9;
        border-radius: 4px;
        display: inline-block;
        font-size: 14px;
        color: #666;
        text-align: center;
        line-height: 36px;
        margin-right: 20px;
        .pointer()
      }
      .iconfont {
        font-size: 12px;
        margin-right: 6px;
      }
    }
  }
  .bottom {
    margin-top: 2px;
    padding: 22px 32px 16px 32px;
    background-color: #fff;
    border-top-right-radius: 10px;
    .base-setting {
      .bottom-desc {
        text-decoration: underline;
        margin-right: 16px;
        &:hover {
          background-color: @hoverBackground;
          cursor:pointer;
        }
      }
      .bottom-switch {
        margin: 0 12px;
      }
      .bottom-vertical {
        display: inline-block;
        border: 1px solid #d7d7d7;
        border-radius: 16px;
        font-size: 13px;
        width: 82px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        color: #727272;
        margin-left: 10px;
      }
    }
    .logic-setting {
      margin-top: 16px;
      .show-setting {
        margin-right: 10px;
        text-decoration: underline;
        .pointer()
      }
      .jump-setting {
        text-decoration: underline;
        .pointer()
      }
    }
    .bottom-finish {
      margin: 34px auto  10px;
      width: 100px;
      height: 32px;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      line-height: 32px;
      color: #FFFFFF;
      border-radius: 4px;
      background-color: #f59a23;
      cursor: pointer;
    }
  }
} 
// 手机端
.mobile-multiple {
  border-bottom: 1px solid #E1E1E8;
  .preview {
    padding: 25px 20px;
    background-color: #fff;
    .preview-title { 
      font-size: 18px;
      line-height: 32px;
      font-weight: bold;
      span {
        color: red;
      }
      .preview-title-tips {
        color: #00A0E9;
        font-size: 14px;
        margin-left: 16px;
      }
    }
    .preview-descript{ 
      font-size: 14px;
      line-height: 30px;
      margin-top: 4px;
      color: #aeaeae;
      padding-left: 20px;
    }
    .preview-options{ 
      .preview-options-tips {
        display: inline-block;
        margin-left: 20px;
        color: #f59a23;
        font-size: 14px;
      }
      .preview-options-desc {
        margin: 8px 0 0 0;
        font-size: 14px;
        line-height: 26px;
        color: #33333370;
      }
      .preview-options-textarea {
        width: 90%; 
        margin: 8px 0 0 0;
      }
      .preview-options-star {
        display: inline-block;
        height: 30px;
        font-size: 18px;
        color: red;
        vertical-align: middle;
        margin-left: 10px;
      }
      .radio {
        display: 'block';
        height: '30px';
        line-height: '30px';
      }
    }
    .preview-tips {
      .tips {
        color: #a5a5a5;
        font-size: 14px;
      }
    }
  }
  .oneColumn {
    width: 100%;
  }
  .twoColumn {
    width: 50%;
  }
} 
// 公用部分
.noborder {
  border: none;
}
.hide {
  display: none;
}
.isAlreadySet {
  color: #F59A23;
}
</style>

<style lang="less">
// 电脑端
.multiple {
  .ant-checkbox-wrapper {
    display: block;
    font-size: 16px;
    margin: 0 0 8px 0;
    .ant-radio-inner {
      width: 20px!important;  
      height: 20px!important;
      border-color: #dbdbdb;
      &::after {
        top: 5px;
        left: 5px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        padding: 0;
        border: none;
      }
    }
    .ant-radio-checked {
      border-color: #f59a23;
    }
  }
  .ant-radio {
    font-size: 30px!important;
  }
  input {
    border: none;
    border-bottom: 1px solid #d7d7d7;
  }
}
// 手机端
.mobile-multiple {
  .ant-checkbox-group {
    width: 100%;
    div:nth-child(1) {
      display: flex;
      flex-wrap: wrap;
    }
    .ant-checkbox-wrapper {
      display: flex;
      font-size: 16px;
      margin: 20px 0 10px 0;
      .ant-radio-inner {
        width: 20px!important;  
        height: 20px!important;
        border-color: #dbdbdb;
        &::after {
          top: 6px;
          left: 5px;
          width: 7px;
          height: 7px;
          border-radius: 50%;
          padding: 0;
          border: none;
        }
      }
      .ant-checkbox-checked {
        border-color: #f59a23;
      }
      span:nth-child(2) {
        line-height: 30px;
        color: #2D2D33;
      }
    }
    .ant-checkbox {
      font-size: 30px!important;
      width: 16px;
      height: 16px;
      margin-top: 8px;
    }
    input {
      border: none;
      border-bottom: 1px solid #d7d7d7;
    } 
  }
}
</style>
