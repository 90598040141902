<!--
 * @Description: 简答题
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-18 19:03:12
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-26 10:47:03
-->
<template>
  <!-- 电脑端 -->
  <div class="short" v-if="isPc"
    :class="{noborder: !isSettingsActive}" 
  >
    <div class="sort-icon">
      <div class="preview-sort iconfont" v-if="!isOnlyRead">&#xe697;</div>
    </div>
    <div 
      class="preview-del iconfont" 
      @click.stop="handleDelete" 
      v-if="!isOnlyRead && !route.query.isKeepAnswerAndEdit"
    >&#xe609;</div>
    <div 
      class="preview-copy iconfont" 
      @click.stop="handleCopy" 
      v-if="!isOnlyRead && !route.query.isKeepAnswerAndEdit"
    >&#xe62f;</div>
    <div class="preview" @click="edit(question.questionId)">
      <PreviewTitle :isOnlyRead="isOnlyRead" :question="question" :isPc="isPc"/>
      <div class="preview-options">
        <a-input
          class="preview-input"
          v-model:value="answerVal" 
          placeholder="请输入内容..."
          :disabled="isDisabled" 
          @blur="fillAnswerIntime(question, answerVal)"
        />
      </div>
      <div class="preview-tips">
        <div class="tips" v-if="item.isNoCondJump">逻辑设置：{{item.jumpLogicTips}}</div>
        <div class="tips" v-if="item.showLogicTips">逻辑设置：本题设置了题目显示逻辑：{{item.showLogicTips}}</div>
      </div>
      <PreviewBottom v-if="isOnlyRead" />
    </div>
    <PreviewBottom v-if="!isOnlyRead" style="background: #fff"/>
    <setting
      v-if="isSettingsActive"
      type="SHORT"
      :item="item"
      :index="index"
      :isOnlyRead="isOnlyRead"
      :question="question"
      @finished="finished"
    />
  </div>
  <!-- 手机端 -->
  <div class="mobile-short" v-else>
    <div class="preview">
      <PreviewTitle :isOnlyRead="isOnlyRead" :question="question" :isPc="isPc"/>
      <div class="preview-options">
        <a-textarea
          v-model:value="answerVal"
          @blur="fillAnswerIntime(question, answerVal)"
          placeholder="请填写..."/>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useRoute } from 'vue-router'
import { createUniqueId } from '@/utils/random'
import { useStore } from 'vuex'
import useCommonQuestionnaireEffect from '@/effects/questionnaireEffect'
import logicWatcherEffect from '@/effects/logicWatcherEffect'
import PreviewTitle from './components/previewTitle'
import PreviewBottom from './components/previewBottom'

import Setting from './components/setting'

/**
 * 题目说明
 */
const titleDescModalEffect = (question) => {
  const isShowTitleDescModal = ref(false)
  const handleOpenTitleDescModal = () => {
    isShowTitleDescModal.value = true
  }
  const changeTitleDesc = (description) => {
    isShowTitleDescModal.value = false
    question.description = description
  }
  return { 
    isShowTitleDescModal, 
    handleOpenTitleDescModal, 
    changeTitleDesc 
  }
}
/**
 * 选项说明
 */
const optionDescModalEffect = (question) => {
  const isShowOptionDescModal = ref(false)
  const optionDesc = ref('')
  let curOption = null
  const handleOpenOptionDescModal = (item) => {
    curOption = item // 获取当前选项，以便于changeOptionDesc方法修改选项说明
    optionDesc.value = item.description    
    isShowOptionDescModal.value = true
  }
  const changeOptionDesc = (description) => {
    isShowOptionDescModal.value = false
    curOption.description = description
  }
  return {
    optionDesc,
    isShowOptionDescModal,
    handleOpenOptionDescModal,
    changeOptionDesc
  }
}
/**
 * 选项新增删除
 */
const choicesUpdateEffect = (question) => {
  const isShowBatchAddModal = ref(false)
  const handleChoiceAdd = () => {
    question.choices.push({
      'choiceId': createUniqueId(10), //选项id
      'text': '选项', //选项内容
      'description': '', //选项描述
      'ifAllowFillInBlanks': false, //是否能填空
      'ifRequired': false, //是否必填
      'pic': '' //图片
    })
  }
  const handleChoiceAddBatch = () => {
    console.log('批量新增');
    isShowBatchAddModal.value = true
  }
  const handleChoiceDelete = (choiceId) => {
    question.choices = question.choices.filter( item => item.choiceId != choiceId)
  }

  const handleBatchSettings = (settings) => {
    console.log(settings);
  }
  return {
    isShowBatchAddModal,
    handleChoiceAdd,
    handleChoiceAddBatch,
    handleChoiceDelete,
    handleBatchSettings
  }
}

/**
 * 答案管理
 */
const answerEffect = () => {
  const route = useRoute()
  const { watchVisibilityEffect } = logicWatcherEffect()
  // 答案获取
  const getAnswer = (question, answerVal) => {
    const answer = {
      "questionType": question.questionType, 
      "questionId": question.questionId,
      "fillText": answerVal
    }
    return answer
  }

  // 答案填写
  // isAreadyAnswerFill，只需要初始化组件时执行一次即可
  const fillAnswer = (question, answerVal, isAreadyAnswerFill) => {
    if (route.name === 'RecycleBin') isAreadyAnswerFill.value = false // 这种是特殊情况，当在回收情况、查看问卷、点击上一份、下一份时，是允许重新填写答案并展示出来的
    if (!isAreadyAnswerFill.value) {
      isAreadyAnswerFill.value = true
      answerVal.value = question.answer.fillText || ''
    }
  }

  // 实时填写答案
  const fillAnswerIntime = (question, value) => {
    question.answer = getAnswer(question, value)
    watchVisibilityEffect(question)
    document.getElementById(question.questionId).classList.remove('error')
  }

  return {
    getAnswer,
    fillAnswer,
    fillAnswerIntime
  }
}

export default {
  props: ['item', 'index', 'isOnlyRead', 'isDisabled', 'isPc'],
  components: { 
    PreviewTitle,
    PreviewBottom,
    Setting
  },
  name: 'Short',
  setup(props) {
    const store = useStore()
    const route = useRoute()
    const answerVal = ref('')
    const selectVal = ref({key: 'SHORT'});
    const isAreadyAnswerFill = ref(false)
    const { getAnswer, fillAnswer, fillAnswerIntime } = answerEffect()
    const question = computed({
      get: () => {
        if (!props.item.answer?.questionId) {
          props.item.answer = {}
        } else {
          fillAnswer(props.item, answerVal, isAreadyAnswerFill)
        }
        return props.item
      }
    })
    watch (() => store.state.curEditingQuestonId, (cur, pre) => {
      if (/^(Preview|Exercise)$/.test(route.name)) return
      if (cur === question.value.questionId) {
        isSettingsActive.value = props.isOnlyRead ? false : true
      } else {
        isSettingsActive.value = false
      }
    })
    const { handleEffect, questionEditStatusChangeEffect, jumpLogicEffect, showLogicEffect, } = useCommonQuestionnaireEffect()
    const { isSettingsActive, edit} = questionEditStatusChangeEffect(props)
    const { isShowTitleDescModal, handleOpenTitleDescModal, changeTitleDesc } = titleDescModalEffect(question.value)
    const { optionDesc, isShowOptionDescModal, handleOpenOptionDescModal, changeOptionDesc } = optionDescModalEffect(question.value)
    const { isShowBatchAddModal, handleChoiceAdd, handleChoiceAddBatch, handleChoiceDelete, handleBatchSettings } = choicesUpdateEffect(question.value)
    const { handleDelete, handleCopy, handleChange } = handleEffect(props)
    const { isAlreadySetJumpLogic, isOpenJumpLogicModal, handleOpenJumpLogicModal, fininshJumpLogicEdit } = jumpLogicEffect(question.value)
    const { isAlreadySetShowLogic, isOpenShowLogicModal, handleOpenShowLogicModal, fininshShowLogicEdit, closeShowLogicModal } = showLogicEffect(question.value)

    edit(props.item.questionId)

    const finished = () => {
      isSettingsActive.value = false
    }

    return {
      route,
      answerVal,
      selectVal,
      fillAnswerIntime,
      question,
      finished,
      edit,
      isSettingsActive,
      isShowTitleDescModal,
      handleOpenTitleDescModal,
      changeTitleDesc,
      optionDesc,
      isShowOptionDescModal,
      handleOpenOptionDescModal,
      changeOptionDesc,
      isShowBatchAddModal,
      handleChoiceAdd,
      handleChoiceAddBatch,
      handleChoiceDelete,
      handleBatchSettings,
      handleDelete,
      handleCopy,
      handleChange,
      isAlreadySetJumpLogic,
      isOpenJumpLogicModal, 
      handleOpenJumpLogicModal,
      fininshJumpLogicEdit,
      isAlreadySetShowLogic,
      isOpenShowLogicModal, 
      handleOpenShowLogicModal,
      fininshShowLogicEdit,
      closeShowLogicModal,
    }
  },
}
</script>

<style scoped lang="less">
// 电脑端
.short {
  position: relative;
  background-color: @backgroundColor;
  border-bottom: 1px solid #E1E1E8;
  border-radius: 10px;
  .iconfont:hover {
    background-color: @hoverBackground;
    cursor:pointer;
  }
  .preview-del {
    position: absolute;
    top: 47px;
    right: 93px;
    color: #00A0E9;
    font-size: 24px;
    z-index: 2;
  }
  .preview-copy {
    position: absolute;
    top: 47px;
    right: 49px;
    color: #00A0E9;
    font-size: 24px;
    z-index: 2;
  }
  .sort-icon {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 24px;
    margin-top: 10px;
    z-index: 2;
    .preview-sort {
      width: 24px;
      height: 24px;
      font-size: 24px;
      transform: rotate(90deg);
      color: #b7b7b7;
    }
  }
  .preview {
    position: relative;
    padding: 40px 32px 0px 32px;
    background-color: #fff;
    .preview-title { 
      font-size: 18px;
      margin-top: 24px;
      span {
        color: red;
      }
    }
    .preview-descript{ 
      font-size: 14px;
      color: #aeaeae;
      margin-top: 16px;
    }
    .preview-options{ 
      .preview-input {
        height: 41px;
        line-height: 41px;
        border-radius: 2px;
      }
      .preview-select {
        margin: 20px 0;
        width: 320px;
        border-radius: 20px;
      }
      .preview-options-desc {
        margin: 10px 0 0 10px;
        font-size: 14px;
        color: #33333370;
      }
    }
    .preview-tips {
      .tips {
        margin-top: 10px;
        color: #a5a5a5;
        font-size: 14px;
      }
    }
  }
  .settings {
    position: relative;
    margin-top: 10px;
    padding: 16px 32px 16px 32px;
    background-color: #fff;
    border-top-right-radius: 10px;
    .editor {
      position: absolute;
      top: 18px;
      right: 240px;
      width: 16px;
      margin: 0 auto;
      font-size: 20px;
      color: black;
      font-weight: bold;
      cursor: pointer;
    }
    .settings-type {
      position: absolute;
      top: 10px;
      right: 20px;
      width: 210px;
    }
    .settings-title {
      width: 500px;
      font-size: 18px;
    }
    .settings-desc {
      margin: 20px 0;
      input {
        color: #aeaeae;
      }
    }
    .settings-toptitle {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      color: #8b8b8b;
      font-size: 14px;
      .settings-toptitle-desc {
        text-align: center;
      }
      .settings-toptitle-isfill {
        text-align: center;
      }
    }
    .settings-options {
      display: flex;
      position: relative;
      margin-top: 20px;
      .settings-options-sort {
        position: absolute;
        top: 6px;
        left: -20px;
        z-index: 99;
        color: #b7b7b7;
      }
      .settings-options-input {
        width: 92%!important;
        font-size: 16px;
      }
      .settings-options-desc {
        text-align: center;
        font-size: 26px;
      }
      .settings-options-notfill {
        text-align: center;
      }
      .settings-options-isfill {
        display: flex;
        padding-left: 30px;
        p {
          font-size: 12px;
          margin-left: 4px;
          margin-right: 10px;;
        }
        span {
          height: 26px;
          border-right: 1px solid #000;
          margin-top: -6px;
          margin-left: 10px;
        }
      }
      .settings-options-del {
  vertical-align: top;
        margin-left: 40px;
      }
      .iconfont {
        cursor:pointer;
        &:hover {
          background-color: @hoverBackground;
        }
      } 
    }
    .settings-add {
      margin-top: 20px;
      div {
        width: 120px;
        height: 36px;
        border: 1px dashed #d9d9d9;
        border-radius: 4px;
        display: inline-block;
        font-size: 14px;
        color: #666;
        text-align: center;
        line-height: 36px;
        margin-right: 20px;
        .pointer()
      }
      .iconfont {
        font-size: 12px;
        margin-right: 6px;
      }
    }
  }
  .bottom {
    margin-top: 2px;
    padding: 22px 32px 16px 32px;
    background-color: #fff;
    border-top-right-radius: 10px;
    .base-setting {
      .bottom-desc {
        text-decoration: underline;
        margin-right: 16px;
        &:hover {
          background-color: @hoverBackground;
          cursor:pointer;
        }
      }
      .bottom-switch {
        margin: 0 12px;
      }
    }
    .logic-setting {
      margin-top: 16px;
      .show-setting {
        margin-right: 10px;
        text-decoration: underline;
        .pointer()
      }
      .jump-setting {
        text-decoration: underline;
        .pointer()
      }
    }
    .bottom-finish {
      margin: 34px auto  10px;
      width: 100px;
      height: 32px;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      line-height: 32px;
      color: #FFFFFF;
      border-radius: 4px;
      background-color: #f59a23;
      cursor: pointer;
    }
  }
}
// 手机端
.mobile-short {
  border-bottom: 1px solid #E1E1E8;
  .preview {
    padding: 25px 20px;
    background-color: #fff;
    .preview-title { 
      font-size: 18px;
      line-height: 32px;
      font-weight: bold;
      span {
        color: red;
      }
    }
    .preview-descript{ 
      font-size: 14px;
      line-height: 30px;
      color: #aeaeae;
      margin-top: 16px;
      padding-left: 20px;
    }
    .preview-options{ 
      margin-top: 20px;
      box-sizing: border-box;
      padding-left: 20px;
      input {
        margin-top: 20px;
        margin-bottom: 30px;
      }
      .preview-select {
        margin: 20px 0;
        width: 320px;
        border-radius: 20px;
      }
      .preview-options-desc {
        margin: 10px 0 0 10px;
        font-size: 14px;
        color: #33333370;
      }
    }
    .preview-tips {
      .tips {
        margin-top: 10px;
        color: #a5a5a5;
        font-size: 14px;
      }
    }
  }
} 
// 公用部分
.noborder {
  border: none;
}
.hide {
  display: none;
}
.isAlreadySet {
  color: #00A0E9;
}
</style>

<style lang="less">
// 电脑端
.short {
  .ant-radio-wrapper {
    display: block;
    font-size: 16px;
    margin: 20px 0;
    .ant-radio-inner {
      width: 20px!important;  
      height: 20px!important;
      border-color: #dbdbdb;
      &::after {
        top: 5px;
        left: 5px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        padding: 0;
        border: none;
      }
    }
    .ant-radio-checked {
      border-color: #f59a23;
    }
  }
  .preview-options {
    .ant-select-selector {
      border-radius: 6px!important;
    }
  }
  input {
    height: 32px;
    border: 1px solid #d7d7d7;
  }
}
// 手机端
.mobile-short {
  .ant-radio-wrapper {
    display: block;
    font-size: 16px;
    margin: 20px 0;
    .ant-radio-inner {
      width: 20px!important;  
      height: 20px!important;
      border-color: #dbdbdb;
      &::after {
        top: 5px;
        left: 5px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        padding: 0;
        border: none;
      }
    }
    .ant-radio-checked {
      border-color: #f59a23;
    }
  }
  .preview-options {
    .ant-input {
      width: 94%;
      min-height: 160px;
    }
    .ant-select-selector {
      border-radius: 6px!important;
    }
  }
  input {
    border: none;
    border-bottom: 1px solid #d7d7d7;
  }
}
</style>>