/*
 * @Description: 随机选项
 * @version: 
 * @Author: HWL
 * @Date: 2021-06-01 11:00:03
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-21 17:11:44
 */
import { ref, watch } from 'vue'
import { shuffle } from '@/utils/arrayUtil'

function choiceRandomEffect(question, randomChoices = []) {
  randomChoices = ref([])
  watch(() => question.choices.length, (cur, per) => {
    randomChoices.value = shuffle(question.choices)
    question.randomChoices = randomChoices.value
  }, {
    immediate: true,
    deep: true
  })
  
  return {
    randomChoices
  }
}

export default choiceRandomEffect
