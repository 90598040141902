<!--
 * @Description: 是否继续操作模态窗
 * @version: 
 * @Author: PSG
 * @Date: 2021-06-09 10:53:47
 * @LastEditors: PSG
 * @LastEditTime: 2021-06-09 16:54:44
-->
<template>
  <div class="modal-root" v-if="visible">
    <div class="modal-mask"></div>
    <div class="modal-wrap">
      <div class="modal-content">
        <div class="modal-text">{{title}}</div>
        <div class="modal-btn-group">
          <div class="btn-ok" @click="onConfirm">{{okText}}</div>
          <div class="btn-no" @click="onClose">{{noText}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'this is a title'
    },
    onOk: {
      type: Function,
      default () {
        console.log('you clicked ok')
      }
    },
    onCancel: {
      type: Function,
      default: function () {
        console.log('you clicked cancel')
      }
    },
    okText: {
      type: String,
      default:  '继续作答'
    },
    noText: {
      type: String,
      default:  '重新作答'
    }
  },
  setup (props, context) {
    const onConfirm = () => {
      context.emit('modal-on-confirm')
    }
    const onClose = () => {
      context.emit('modal-on-close')
    }
    return {
      onConfirm,
      onClose,
    }
  }
}
</script>

<style lang='less' scoped>
.modal-mask{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  .modal-content {
    position: relative;
    top: 20%;
    width: 360px;
    margin: 0 auto;
    border-radius: 10px;
    padding: 60px 20px;
    background-color: #fff;
    .modal-text {
      line-height: 26px;
      color: #7F7F88;
      margin-bottom: 30px;
    }
    .modal-btn-group {
      div {
        width: 250px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 18px;
        border-radius: 4px;
        margin: 0 auto;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
      .btn-ok {
        color: #fff;
        background-color: #00A0E9;
        margin-bottom: 20px;
      }
      .btn-no {
        color: #7F7F88;
        background-color: #E1E1E8;
      }
    }
  }
}
</style>