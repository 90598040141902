<!--
 * @Description: 单选题
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-18 18:53:29
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-29 11:28:10
-->
<template>
  <!-- 电脑端 -->
  <div class="single" v-if="isPc" 
    :class="{noborder: !isSettingsActive}" 
  >
    <div class="sort-icon">
      <div class="preview-sort iconfont" v-if="!isOnlyRead">&#xe697;</div>
    </div>
    <div 
      class="preview-del iconfont" 
      @click.stop="handleDelete" 
      v-if="!isOnlyRead && !route.query.isKeepAnswerAndEdit"
    >&#xe609;</div>
    <div 
      class="preview-copy iconfont" 
      @click.stop="handleCopy" 
      v-if="!isOnlyRead && !route.query.isKeepAnswerAndEdit"
    >&#xe62f;</div>
    <div class="preview" @click="edit(question.questionId)">
      <PreviewTitle :isOnlyRead="isOnlyRead" :question="question" :isPc="isPc"/>
      <div class="preview-options column-flex-layout">
        <a-radio-group v-model:value="radioValue" :disabled="isDisabled" @change="fillAnswerIntime(question, radioValue)">
          <!-- 乱序 -->
          <div v-if="question.ifMessUp">
            <!-- 非选项引用区域 -->
            <template 
              v-for="(item) in randomChoices"
              :key="item.choiceId + '1'"
              v-if="!visibileRefChoiceArea"
            >
              <a-radio
                :value="item.choiceId"
                :class="{
                  oneColumn: question.columnSplit == 1,
                  twoColumn: question.columnSplit == 2,
                  threeColumn: question.columnSplit == 3,
                }"
              >
                <span :title="item.text ? item.text : '选项'">
                  {{item.text ? item.text : "选项"}}
                </span>
                <div class="fill-text">
                  <span v-if="item.ifAllowFillInBlanks && item.ifRequired" style="color: red;">*</span>
                  <a-input 
                    v-model:value="item.fillText" 
                    v-if="item.ifAllowFillInBlanks" 
                    @blur="fillAnswerIntime(question, radioValue)"
                  />
                </div>
                <div class="preview-options-desc" v-if="item.description">{{item.description}}</div>
              </a-radio>
            </template>
            <!-- 选项引用区域 -->
            <template 
              v-for="(item) in question.choices"
              :key="item.choiceId + '2'"
              v-else
            >
              <a-radio 
                :value="item.choiceId"
                :class="{
                  oneColumn: question.columnSplit == 1,
                  twoColumn: question.columnSplit == 2,
                  threeColumn: question.columnSplit == 3,
                }"
              >
                <span :title="item.text ? item.text : '选项'">
                  {{item.text ? item.text : "选项"}}
                </span>
                <span v-if="item.jumpLogicTips && (isOnlyRead || route.name==='QuestionEdit' || route.name==='Preview')" class="preview-options-tips">({{item.jumpLogicTips}})</span>
                <span v-if="item.showLogicTips && (isOnlyRead || route.name==='QuestionEdit' || route.name==='Preview')" class="preview-options-tips">({{item.showLogicTips}})</span>
                <div class="fill-text">
                  <span v-if="item.ifAllowFillInBlanks && item.ifRequired" style="color: red;">*</span>
                  <a-input 
                    v-model:value="item.fillText" 
                    v-if="item.ifAllowFillInBlanks" 
                    @blur="fillAnswerIntime(question, radioValue)"
                  />
                </div>
                <div class="preview-options-desc" v-if="item.description">{{item.description}}</div>
              </a-radio>
            </template>
          </div>
          <!-- 顺序 -->
          <div v-else>
            <template 
              v-for="(item) in question[choiceKey]"
              :key="item.choiceId"
            >
              <a-radio
                :value="item.choiceId"
                :class="{
                  oneColumn: question.columnSplit == 1,
                  twoColumn: question.columnSplit == 2,
                  threeColumn: question.columnSplit == 3,
                }"
                v-show="item.visible || isPreveiw || route.name==='QuestionEdit'"
              >
                <span :title="item.text ? item.text : '选项'">
                  {{item.text ? item.text : "选项"}}
                </span>
                <span v-if="item.jumpLogicTips && (isOnlyRead || route.name==='QuestionEdit' || route.name==='Preview')" class="preview-options-tips">({{item.jumpLogicTips}})</span>
                <span v-if="item.showLogicTips && (isOnlyRead || route.name==='QuestionEdit' || route.name==='Preview')" class="preview-options-tips">({{item.showLogicTips}})</span>
                <div class="fill-text">
                  <span v-if="item.ifAllowFillInBlanks && item.ifRequired" style="color: red;">*</span>
                  <a-input 
                    v-model:value="item.fillText" 
                    v-if="item.ifAllowFillInBlanks" 
                    @blur="fillAnswerIntime(question, radioValue)"
                  />
                </div>
                <div class="preview-options-desc" v-if="item.description">{{item.description}}</div>
              </a-radio>
            </template>
          </div>
        </a-radio-group>    
      </div>
      <div class="preview-tips">
        <div class="tips" v-if="item.isCondJump">逻辑设置：本题设置了跳题逻辑：按选项跳题</div>
        <div class="tips" v-if="item.isNoCondJump">逻辑设置：{{item.jumpLogicTips}}</div>
        <div class="tips" v-if="item.showLogicTips">逻辑设置：本题设置了题目显示逻辑：{{item.showLogicTips}}</div>
        <div class="tips" v-if="item.refTips && visibleRefTips">{{item.refTips}}</div>
      </div>
      <PreviewBottom v-if="isOnlyRead" />
    </div>
    <PreviewBottom v-if="!isOnlyRead" style="background: #fff"/>
    <setting
      v-if="isSettingsActive"
      type="SINGLE_CHOICE"
      :item="item"
      :index="index"
      :isOnlyRead="isOnlyRead"
      :question="question"
      @finished="finished"
    />
  </div>
  <!-- 手机端 -->
  <div class="mobile-single" v-else>
    <div class="preview">
      <PreviewTitle :isOnlyRead="isOnlyRead" :question="question" :isPc="isPc"/>
      <div class="preview-options">
        <a-radio-group v-model:value="radioValue" @change="fillAnswerIntime(question, radioValue)">
          <!-- 乱序 -->
          <div v-if="question.ifMessUp">
            <!-- 非选项引用区域 -->
            <template 
              v-for="(item) in randomChoices"
              :key="item.choiceId + '1'"
              v-if="!visibileRefChoiceArea"
            >
            <a-radio 
              :value="item.choiceId"
              :class="{
                oneColumn: question.columnSplit == 1,
                twoColumn: question.columnSplit > 1 
              }"
            >
              <div>{{item.text}}</div>
              <div class="preview-options-desc" v-if="item.description">{{item.description}}</div>
              <a-textarea
                class="preview-options-textarea"
                v-model:value="item.fillText"
                v-if="item.ifAllowFillInBlanks" 
                placeholder="请填写..." 
                @blur="fillAnswerIntime(question, radioValue)"
              />
              <span class="preview-options-star" v-if="item.ifRequired&&radioValue===item.choiceId">*</span>
            </a-radio>
            </template>
            <!-- 选项引用区域 -->
            <template 
              v-for="(item) in question.choices"
              :key="item.choiceId  + '2'"
              v-else
            >
            <a-radio 
              :value="item.choiceId"
              :class="{
                oneColumn: question.columnSplit == 1,
                twoColumn: question.columnSplit > 1 
              }"
            >
              <div>{{item.text}}</div>
              <div class="preview-options-desc" v-if="item.description">{{item.description}}</div>
              <a-textarea
                class="preview-options-textarea"
                v-model:value="item.fillText"
                v-if="item.ifAllowFillInBlanks" 
                placeholder="请填写..." 
                @blur="fillAnswerIntime(question, radioValue)"
              />
              <span class="preview-options-star" v-if="item.ifRequired&&radioValue===item.choiceId">*</span>
            </a-radio>
            </template>
          </div>
          <!-- 顺序 -->
          <div v-else>
            <template 
              v-for="(item) in question[choiceKey]"
              :key="item.choiceId"
            >
            <a-radio 
              :value="item.choiceId"
              v-show="item.visible"
              :class="{
                oneColumn: question.columnSplit == 1,
                twoColumn: question.columnSplit > 1 
              }"
            >
              <div>{{item.text}}</div>
              <div class="preview-options-desc" v-if="item.description">{{item.description}}</div>
              <a-textarea
                class="preview-options-textarea"
                v-model:value="item.fillText"
                v-if="item.ifAllowFillInBlanks" 
                placeholder="请填写..." 
                @blur="fillAnswerIntime(question, radioValue)"
              />
              <span class="preview-options-star" v-if="item.ifRequired&&radioValue===item.choiceId">*</span>
            </a-radio>
            </template>
          </div>
        </a-radio-group>
      </div>
      <div class="preview-tips">
        <div class="tips" v-if="item.refTips && visibleRefTips">{{item.refTips}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import globalQuestionnaire from '@/class/useQuestionnaire' 
import useCommonQuestionnaireEffect from '@/effects/questionnaireEffect'
import logicWatcherEffect from '@/effects/logicWatcherEffect'
import choiceRandomEffect from './components/effects/choiceRandomEffect'
import Setting from './components/setting'
import PreviewTitle from './components/previewTitle'
import PreviewBottom from './components/previewBottom'

/**
 * 答案管理
 */
const answerEffect = () => {
  const route = useRoute()
  const questionnaire = globalQuestionnaire.getQuestionnaire()
  // 答案获取
  const getAnswer = (question, choiceId) => {
    const choices = question.ref ? question.refChoices : question.choices
    const answer = {
      "questionType": question.questionType, 
      "questionId": question.questionId,
      "choices": [{
        "choiceId": choiceId,
        "fillText": choices.filter(item => item.choiceId === choiceId)[0].fillText
      }]
    }
    return answer
  }
  // 答案填写
  // isAreadyAnswerFill，只需要初始化组件时执行一次即可
  const fillAnswer = (question, radioValue, isAreadyAnswerFill) => {
    if (route.name === 'RecycleBin') isAreadyAnswerFill.value = false // 这种是特殊情况，当在回收情况、查看问卷、点击上一份、下一份时，是允许重新填写答案并展示出来的
    if (!isAreadyAnswerFill.value) {
      isAreadyAnswerFill.value = true
      const choiceId = question.answer.choices[0].choiceId
      const fillText = question.answer.choices[0]?.fillText || ''
      radioValue.value = choiceId
      question.choices.forEach(choice => {
        if (choice.choiceId === choiceId) {
          choice.fillText = fillText
        }
      })
    }
  }
  // 实时填写答案
  const fillAnswerIntime = (question, value) => {
    watch(() => question.answer, (cur, pre) => {
      watchVisibilityEffect(question)
    })     
    const { watchVisibilityEffect } = logicWatcherEffect()
    question.answer = getAnswer(question, value)
    document.getElementById(question.questionId).classList.remove('error')  }
  return {
    getAnswer,
    fillAnswer,
    fillAnswerIntime,
  }
}

export default {
  props: ['item', 'index', 'isOnlyRead', 'isPreveiw', 'isDisabled', 'isPc'],
  name: 'SingleChoice',
  components: {
    Setting,
    PreviewTitle,
    PreviewBottom,
  },
  setup(props) {
    const store = useStore()
    const route = useRoute()
    const routeName = ref(route.name)
    const radioValue = ref()
    const selectVal = ref({key: 'SINGLE_CHOICE'});
    const isAreadyAnswerFill = ref(false)
    const { getAnswer, fillAnswer, fillAnswerIntime } = answerEffect()
    const question = computed({
      get: () => {
        props.item.choices.forEach(choice => {
          if (choice.fillText === undefined) {
            choice.fillText = ''
          }
        })
        if (!props.item.answer?.questionId) {
          props.item.answer = {}
        } else {
          fillAnswer(props.item, radioValue, isAreadyAnswerFill)
        }
        return props.item
      }
    })
    watch (() => store.state.curEditingQuestonId, (cur, pre) => {
      if (/^(Preview|Exercise)$/.test(route.name)) return
      if (cur === question.value.questionId) {
        isSettingsActive.value = props.isOnlyRead ? false : true
      } else {
        isSettingsActive.value = false
      }
    })

    const { randomChoices } = choiceRandomEffect(question.value)
    const { handleEffect, questionEditStatusChangeEffect, visibileRefChoiceEffect, visibileRefTipsEffect } = useCommonQuestionnaireEffect()
    const { isSettingsActive, edit } = questionEditStatusChangeEffect(props)
    const { visibileRefChoiceArea } = visibileRefChoiceEffect(props.isOnlyRead, question.value)
    const { visibleRefTips } = visibileRefTipsEffect(question)
    const { handleDelete, handleCopy } = handleEffect(props)

    const choiceKey = visibileRefChoiceArea.value? 'refChoices' : 'choices'

    const finished = () => {
      isSettingsActive.value = false
    }

    edit(props.item.questionId)

    return {
      visibleRefTips,
      route,
      routeName,
      radioValue,
      selectVal,
      fillAnswerIntime,
      question,
      finished,
      edit,
      isSettingsActive,
      randomChoices,
      visibileRefChoiceArea,

      handleDelete,
      handleCopy,
      choiceKey,
    }
  },
}
</script>

<style scoped lang="less">
// 电脑端
.single {
  position: relative;
  background-color: @backgroundColor;
  border-bottom: 1px solid #E1E1E8;
  border-radius: 10px;
  .iconfont:hover {
    background-color: @hoverBackground;
    cursor:pointer;
  }
  .preview-del {
    position: absolute;
    top: 47px;
    right: 93px;
    color: #00A0E9;
    font-size: 24px;
  }
  .preview-copy {
    position: absolute;
    top: 47px;
    right: 49px;
    color: #00A0E9;
    font-size: 24px;
  }
  .sort-icon {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 24px;
    margin-top: 10px;
    .preview-sort {
      width: 24px;
      height: 24px;
      font-size: 24px;
      transform: rotate(90deg);
      color: #b7b7b7;
    }
  }
  
  .preview {
    padding: 40px 32px 0px 32px;
    background-color: #fff;
    .preview-title { 
      font-size: 18px;
      margin-top: 24px;
      span {
        color: red;
      }
    }
    .preview-descript{ 
      font-size: 14px;
      color: #aeaeae;
      margin-top: 16px;
      padding-left: 20px;
    }
    .preview-options{ 
      .preview-options-tips{
        display: inline-block;
        margin-left: 20px;
        color: #f59a23;
        font-size: 14px;
      }
      .preview-options-desc {
        margin-top: 10px;
        text-align: justify;
        font-size: 14px;
        color: #33333370;
      }
    }
    .preview-tips {
      .tips {
        margin-top: 10px;
        color: #a5a5a5;
        font-size: 14px;
      }
    }
  }
} 
// 手机端
.mobile-single {
  border-bottom: 1px solid #E1E1E8;
  .preview {
    padding: 25px 20px;
    background-color: #fff;
    .preview-title {
      font-size: 18px;
      line-height: 32px;
      font-weight: bold;
      color: #323336;
      span {
        color: red;
      }
    }
    .preview-descript{ 
      font-size: 14px;
      line-height: 30px;
      margin-top: 4px;
      color: #aeaeae;
      padding-left: 20px;
    }
    .preview-options{ 
      box-sizing: border-box;
      .preview-options-tips{
        display: inline-block;
        margin-left: 20px;
        color: #f59a23;
        font-size: 14px;
      }
      .preview-options-desc {
        margin: 8px 0 0 0;
        font-size: 14px;
        line-height: 26px;
        color: #33333370;
      }
      .preview-options-textarea {
        width: 90%; 
        margin: 8px 0 0 0;
      }
      .preview-options-star {
        display: inline-block;
        height: 30px;
        font-size: 18px;
        color: red;
        vertical-align: middle;
        margin-left: 10px;
      }
      .radio {
        display: 'block';
        height: '30px';
        line-height: '30px';
      }
    }
    .preview-tips {
      .tips {
        color: #a5a5a5;
        font-size: 14px;
      }
    }
  }
  .oneColumn {
    width: 100%;
  }
  .twoColumn {
    width: 50%;
  }
} 
// 公用部分
.noborder {
  border: none;
}
.hide {
  display: none;
}
.isAlreadySet {
  color: #F59A23;
}
</style>

<style lang="less">
// 电脑端
.single {
  .ant-radio-wrapper {
    display: block;
    font-size: 16px;
    margin: 0 0 8px 0;
    .ant-radio-inner {
      border-color: #dbdbdb;
      width: 16px;
      height: 16px;
    }
    .ant-radio-checked {
      .ant-radio-inner {
        background-color: #00A0E9;
        &::after {
          width: 8px;
          height: 8px;
          left: 50%; 
          top: 50%;
          transform: translate(-50%, -50%); /* 50%为自身尺寸的一半 */
          -webkit-transform: translate(-50%, -50%);
          border-radius: 50%;
          padding: 0;
          margin: 0;
          border: none;
          background-color: #fff;
        }
      }
    }
  }
  .ant-radio {
    font-size: 30px!important;
    margin-top: 3px;
  }
  input {
    border: none;
    border-bottom: 1px solid #d7d7d7;
  }
}
// 手机端
.mobile-single {
  .ant-radio-group {
    width: 100%;
    div:nth-child(1) {
      display: flex;
      flex-wrap: wrap;
    }
    .ant-radio-wrapper {
      display: flex;
      font-size: 16px;
      margin: 20px 0 0 0;
      word-break: break-all; //只对英文起作用，以字母作为换行依据
      word-wrap: break-word; // 只对英文起作用，以单词作为换行依据
      white-space:pre-wrap; // 只对中文起作用，强制换行
      .ant-radio-inner {
        width: 20px!important;  
        height: 20px!important;
        border-color: #dbdbdb;
      }
      .ant-radio-checked {
        .ant-radio-inner {
          background-color: #00A0E9;
          &::after {
            width: 8px;
            height: 8px;
            left: 50%; 
            top: 50%;
            transform: translate(-50%, -50%); /* 50%为自身尺寸的一半 */
            -webkit-transform: translate(-50%, -50%);
            border-radius: 50%;
            padding: 0;
            margin: 0;
            border: none;
            background-color: #fff;
          }
        }
      }
      span:nth-child(2) {
        line-height: 30px;
        color: #2D2D33;
      }
    }
    .ant-radio {
      font-size: 30px!important;
      width: 18px;
      height: 20px;
      margin-top: 4px;
    }
    input {
      border: none;
      border-bottom: 1px solid #d7d7d7;
    } 
  }
  
}
</style>