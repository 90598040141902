<!--
 * @Description: 批量添加弹出框
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-24 14:37:35
 * @LastEditors: HWL
 * @LastEditTime: 2021-06-25 16:10:56
-->
<template>
  <div>
    <a-modal 
      v-model:visible="visible" 
      :title="title" 
      @ok="handleOk"
      class="BatchAddModal"
      :closable="false"
    >
      <span class="tips">（一行即一个选项内容）</span>
      <span class="iconfont close" @click="handleClose">&#xe61f;</span>
      <a-textarea v-model:value="text" allow-clear/>
    </a-modal>
  </div>
</template>
<script>
import { reactive, ref, watchEffect, toRefs } from 'vue'

const watchPropsEffect = (props) => {
  const modalInfo = reactive({
    visible: props.visible,
    title: props.title,
    text: props.text
  })
  watchEffect(() => {
    modalInfo.visible = props.visible
    modalInfo.title = props.title
    modalInfo.text = props.text
  })
  const { visible, title, text } = toRefs(modalInfo)
  return { visible, title, text }
}

export default({
  name: 'DescModal',
  props: ['visible',  // 控制显示隐藏
          'title', // 模态窗标题
          'text', // 模态窗标题
  ],
  /**
   * 程序入口
   */
  setup(props, { emit }) {
    const { visible, title, text } = watchPropsEffect(props)
    const handleOk = () => {
      let textarea = text.value.trim() ? text.value.trim().replace(/\n(\n)*( )*(\n)*\n/g,"\n").split(/\n/) : ""
      emit('finishEditText', textarea)
    }
    const handleClose = () => {
      emit('finishEditText')
    }

    return {
      visible,
      title,
      text,
      handleOk,
      handleClose,
    }
  },
})
</script>

<style lang='less'>
.BatchAddModal {
  ::selection {
    background: #fff!important;
  }
  .ant-modal-content {
    position: relative;
    border: 16px solid #fff;
    border-radius: 16px;
    .ant-modal-header {
      border: none;
      .ant-modal-title {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .ant-modal-body {
      .close {
        position: absolute;
        top: 8px;
        right: 24px;
        font-size: 20px;
        color: #00A0E9;
        .pointer()
      }
      .tips {
        position: absolute;
        top: 17px;
        left: 112px;
        font-size: 14px;
        color: #33333370;
      }
      .ant-input-affix-wrapper {
        .ant-input {
          min-height: 100px;
        }
        svg {
          font-size: 18px;
        }
      }
      input {
        border: none;
        border-bottom: 1px solid #d7d7d7;
      }
    }
    .ant-modal-footer {
      border: none;
      div {
        text-align: center;
        button {
          background-color: #00A0E9;
          border: none;
          color: #fff;
          &:nth-child(1) {
            display: none;
          }
        }
      }
    }
  }
  .ant-modal-footer {
    border: none;
    div {
      text-align: center;
      button {
        background-color: #00A0E9;
        border: none;
        color: #fff;
        &:nth-child(1) {
          display: none;
        }
    }
    }
  }
}
.ant-input-textarea-clear-icon {
  margin-right: 40px;
}
</style>