/*
 * @Description: 对话框监视
 * @version: 
 * @Author: HWL
 * @Date: 2021-06-03 17:30:32
 * @LastEditors: HWL
 * @LastEditTime: 2021-06-03 17:36:49
 */
import { reactive, watchEffect, toRefs } from 'vue'

/**
 * 对话框监听
 * @param {object} props 参数
 */
function watchPropsEffect(props) {
  const modalInfo = reactive({
    visible: props.visible,
    title: props.title,
    text: props.text
  })
  watchEffect(() => {
    modalInfo.visible = props.visible
    modalInfo.title = props.title
    modalInfo.text = props.text
  })
  const { visible, title, text } = toRefs(modalInfo)
  return { visible, title, text }
}

export default watchPropsEffect
