<!--
 * @Description: 选项显示设置
 * @version: 
 * @Author: PSG
 * @Date: 2021-04-21 14:03:59
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-29 14:36:49
-->
<template>
  <div class="choice-show-tab">
    <div class="rich-text">
      <p style="width: 85px;">当前题目为：</p>
      <RichTitle class="rich-title" :title="question.title">
        <span class="title-tips">({{questionTypeList[question.questionType]}})</span>
      </RichTitle>
    </div>
    <div class="choices">
      <div class="choices-top">
        <a-switch 
          class="switch" 
          v-model:checked="choiceChecked" 
          size="small"
          @change="choiceCheckedChange"
        />
        <span class="text">题目选项</span>
      </div>
      <div class="choice-body" v-show="choiceChecked">
        <a-row class="form-title">
          <a-col :span="6" class="center padding">当前选项</a-col>
          <a-col :span="18" class="center padding">条件</a-col>
        </a-row>
        <div class="form-body">
          <a-row 
            class="form-row" 
            v-for="(item, index) in list"
            :key="index"
          >
            <a-col :span="6" class="padding">{{item.choiceText}}</a-col>
            <a-col :span="18">
              <div class="desc" v-show="item.condQuestList.length > 0">
                <span>符合以下</span>
                <a-select 
                  label-in-value 
                  class="desc-select"
                  :bordered="false"
                  v-model:value="item.condition"
                >
                  <a-select-option :value="1" >全部</a-select-option>
                  <a-select-option :value="2">任一</a-select-option>
                </a-select>
                <span>条件（题目）时，显示当前选项。</span>
                
              </div>
              <Condition
                v-for="(condQuestion, cindex) in item.condQuestList"
                :key="condQuestion.questionId.key"
                :index="cindex"
                :item="condQuestion"
                :choiceId="item.choiceId"
                :alreadySelectQuestionList="item.condQuestList"
                :beforeQuestList="beforeQuestList"
                @delete="handleDelete"
                @condQuestSelectChange="handleCondQuestSelectChange"
              />
              <!-- <Condition/> -->
              <div class="add">
                <div class="select-box" v-show="item.isShowSelect" >
                  <span class="condition-sort">{{item.condQuestList.length + 1}}.&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <a-select 
                    label-in-value 
                    style="width: 300px;border-bottom: 1px solid #d7d7d7;"
                    @change="handleAddConditionChange"
                    :bordered="false"
                    v-model:value="item.selectRefVal"
                  >
                    <a-select-option :value="-1" >请选择题目</a-select-option>
                    <a-select-option 
                      v-for="(enableChooseQuestion, index) in item.enableChooseQuestionList"
                      :key="enableChooseQuestion.questionId"
                      :value="enableChooseQuestion.questionId"
                      :choiceData = item
                      :curSelectQuestion="enableChooseQuestion"
                      :index="index"
                    >
                      <div class="item" style="display: flex">
                        {{enableChooseQuestion.sort + 1}}、<RichTitle :title="enableChooseQuestion.title"/>
                      </div>
                      </a-select-option>
                    <!-- <a-select-option :value="2">1、标题题目（单选）</a-select-option>
                    <a-select-option :value="3">5、标题题目（多选）</a-select-option>
                    <a-select-option :value="4">7、标题题目（矩阵量表）</a-select-option>
                    <a-select-option :value="5">8、标题题目（矩阵单选）</a-select-option> -->
                  </a-select>
                  <span class="iconfont close"  @click="item.isShowSelect=false">&#xe61c;</span>
                </div>
                <div class="add-btn"  @click="handleAddClick(item)">+</div>
              </div>
            </a-col>
          </a-row>

        </div>
      </div>
    </div>
    <div class="ref">
      <div class="ref-top">
        <a-switch 
          class="switch" 
          v-model:checked="refChecked" 
          size="small"
          @change="refCheckedChange"
        />
        <span class="text">选项引用</span>
      </div>
      <div class="ref-body" v-show="refChecked">
        <span>选项来源题目：</span>
        <a-select 
          label-in-value 
          style="width: 541px;border-bottom: 1px solid #d7d7d7;font-weight: 100" 
          :bordered="false"
          v-model:value="choiceRefVal"
        >
          <a-select-option :value="-1" >请选择下列多选题目</a-select-option>
          <a-select-option
            v-for="(mQuestion, index) in beforeMultipleQuestList"
            :value="mQuestion.questionId"
            :key="index"
          >
            <div class="item" style="display: flex">
              {{mQuestion.sort + 1}}、<RichTitle :title="mQuestion.title"/>
            </div>
          </a-select-option>
          <!-- <a-select-option :value="2">1、题目标题（多选）</a-select-option>
          <a-select-option :value="3">5、题目标题（多选）</a-select-option>
          <a-select-option :value="4">7、题目标题（多选）</a-select-option>
          <a-select-option :value="5">8、题目标题（多选）</a-select-option> -->
        </a-select>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect, inject } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { message } from 'ant-design-vue'
import useParseExpEffect from '@/effects/parseExpEffect'
import globalQuestionnaire from '@/class/useQuestionnaire'
import Condition from './componnets/Condition'
import RichTitle from '../../question/components/richTitle'
/**
 * Switch按钮逻辑控制
 */
const switchEffect = (question) => {
  const choiceChecked= ref(question.choices.filter(choice => choice.showLogic !== null).length > 0 ? true : false)
  const refChecked= ref(question.ref !== null ? true : false)
  
  const choiceCheckedChange = (checked) => {
    if (checked) {
      if (refChecked.value) {
        refChecked.value = false
      }
    }
  }
  const refCheckedChange = (checked) => {
    if (checked) {
      if (choiceChecked.value) {
        choiceChecked.value = false
      }
    }
  }
  return {
    choiceChecked,
    refChecked,
    choiceCheckedChange,
    refCheckedChange,
  }
}

/**
 * 获取本题前面的条件题目 （只获取单选、多选）
 */
const getBeforeQuestList = (question) => {
  const store = useStore()
  const curSort = question.sort
  const questionnaire = globalQuestionnaire.getQuestionnaire()
  const questions = JSON.parse(JSON.stringify(questionnaire.questions))
  const beforeQuestList = questions
    .filter(item => /^(SINGLE_CHOICE|MULTIPLE_CHOICE)$/.test(item.questionType)
                    && item.sort < curSort)
  return {
    beforeQuestList
  }
}

/**
 * 获取本题前面的条件题目（只获取多选）
 */
const getBeforeMultipleQuestList = (question) => {
  const store = useStore()
  const curSort = question.sort
  const questionnaire = globalQuestionnaire.getQuestionnaire()
  const questions = JSON.parse(JSON.stringify(questionnaire.questions))
  const beforeMultipleQuestList = questions
    .filter(item => /^MULTIPLE_CHOICE$/.test(item.questionType)
                    && item.sort < curSort)
  return {
    beforeMultipleQuestList
  }
}

/**
 * 根据原题数据结构 构建 条件题目数据结构
 */
const buildCondQuestFromOrigQuest = (origQuestion) => {
  const { buildSingleOrMultiple } = useParseExpEffect()
  let condQuestion  = buildSingleOrMultiple(origQuestion, [], 2)
  return condQuestion
}

/**
 * 选项中添加条件事件
 */
const conditionAddEffect = () => {
  const handleAddClick = (choiceData) => {
    // 判断是否还存在条件题目可选范围
    if (choiceData.enableChooseQuestionList.length === 0) {
      message.warning('本选项前置条件已选完。')
      return
    }
    // 重置
    choiceData.selectRefVal.value = -1
    choiceData.selectRefVal.key = -1
    if (choiceData.selectRefVal.value.label) { // 这里是为了让下拉选项重置“到请选择题目”
      delete choiceData.selectRefVal.value.label
    }
    
    // 显示
    choiceData.isShowSelect = true
  }
  const handleAddConditionChange = (value, selectedObj) => {
    // 1、数据的含义
    //    curSelectQuestion  当前选中的条件问题（新增）
    //    choiceData 当前题目的选项 的所有数据
    //    index  代表 curSelectQuestion 在 choiceData.enableChooseQuestionList 中的位置
    // 2、逻辑
    //    2.1、将要新增的条件问题curSelectQuestion，构建成 条件题目数据结构，然后push进choiceData.list中
    //    2.2、重新构建choiceData.enableChooseQuestionList
    //    2.3、让 添加条件问题组件 消失
    // const sort = selectedObj.value
    const targetQuestionId = selectedObj.value
    if (targetQuestionId === -1) return
    const choiceData = selectedObj.choiceData
    const index = selectedObj.index
    const logicQuestion = buildCondQuestFromOrigQuest(selectedObj.curSelectQuestion)
    choiceData.enableChooseQuestionList.splice(index, 1)
    choiceData.condQuestList.push(logicQuestion)
    choiceData.isShowSelect = false
  }
  return {
    handleAddClick,
    handleAddConditionChange,
  }
}

/**
 * 处理订阅相关事件
 */
const handleSubscriptionEffect = (list, beforeQuestList) => {
  // 删除题目关联
  // 1、将condQuestList 条件题目列表删除第index条
  // 2、更新enableChooseQuestionList（可供选择的题目列表）
  //    2.1、添加删除的题目（注意排序问题）
  const handleDelete = (index, choiceId) => {
    const choiceData = list.filter(choiceData => choiceData.choiceId === choiceId)[0]
    const { questionId: afterDelQuestId } = choiceData.condQuestList.splice(index, 1)[0] // 删除条件题目
    const enableChocieQuestion = beforeQuestList.filter(question => question.questionId === afterDelQuestId)[0] // 获取删除的题目
    choiceData.enableChooseQuestionList.push(enableChocieQuestion)
    choiceData.enableChooseQuestionList.sort(function(a, b) { return a.sort - b.sort })
  }
  // 更换题目关联
  // 1、根据choiceId，可以找到本题目的第几个选项
  // 2、根据curQuestIndex（旧题目下标）、targeQuestSort（待更换的题目序号），进行更换
  // 3、更新condQuestList
  // 4、更新enableChooseQuestionList（可供选择的题目列表）
  //    4.1、删除切换后的题目
  //    4.2、添加切换前的题目（注意排序问题）
  const handleCondQuestSelectChange = (curQuestIndex, targeQuestId, choiceId) => {
    const targeQuestion = beforeQuestList.filter(question => question.questionId === targeQuestId)[0]
    for (let i = 0; i < list.length; i++) {
      let choiceData = list[i]
      if (choiceData.choiceId === choiceId) { // 一维是选项
        const logicQuestion = buildCondQuestFromOrigQuest(targeQuestion)
        //  删除切换钱的条件题目，插入切换后的条件题目，且获取删除的题目序号   
        // const { questionId: afterDelQuestId } = choiceData.condQuestList.splice(curQuestIndex, 1, logicQuestion)[0] // 二维是 条件题目下标
        const afterDeleCondQuest = choiceData.condQuestList.splice(curQuestIndex, 1, logicQuestion)[0] // 二维是 条件题目下标
        const enableChocieQuestion = beforeQuestList.filter(question => question.sort === afterDeleCondQuest.sort.key)[0] // 获取删除的题目
        // 更新enableChooseQuestionList
        choiceData.enableChooseQuestionList = choiceData.enableChooseQuestionList.filter(question => question.questionId !== targeQuestId)
        choiceData.enableChooseQuestionList.push(enableChocieQuestion)
        choiceData.enableChooseQuestionList.sort(function(a, b) { return a.sort - b.sort })
        break
      }
    }
  }
  return {
    handleDelete,
    handleCondQuestSelectChange,
  }
}

const questionTypeList = {
  SINGLE_CHOICE: '单选题',
  MULTIPLE_CHOICE: '多选题',
  DROP_DOWN: '下拉题',
  SCALE: '量表题',
  SHORT: '简答题',
  DATE: '日期题',
  MATRIX_SINGLE_CHOICE: '矩阵单选题',
  MATRIX_MULTIPLE_CHOICE: '矩阵多选题',
  MATRIX_SCALE: '矩阵量表题',
}

export default {
  name: 'ChoiceShowTab',
  components: {
    Condition,
    RichTitle,
  },
  setup () {
    const question = inject('question')
    const { parseChoiceLogicExp } = useParseExpEffect()
    const { choiceRefVal, list } = parseChoiceLogicExp(question)
    const { beforeQuestList } = getBeforeQuestList(question)
    const { beforeMultipleQuestList } = getBeforeMultipleQuestList(question)
    const { choiceChecked, refChecked, choiceCheckedChange, refCheckedChange, } = switchEffect(question)
    const { handleAddClick, handleAddConditionChange } = conditionAddEffect()
    const { handleDelete, handleCondQuestSelectChange } = handleSubscriptionEffect(list, beforeQuestList)
   return {
      question,
      choiceRefVal,
      list,
      beforeQuestList,
      beforeMultipleQuestList,
      choiceChecked, 
      refChecked, 
      choiceCheckedChange, 
      refCheckedChange,
      handleAddClick,
      handleAddConditionChange,
      handleDelete,
      handleCondQuestSelectChange,
      questionTypeList
    }
  }
}
</script>

<style lang="less" scope>
.title-tips {
  word-break: keep-all;
  margin-left: 8px;
  font-weight: normal;
}
.choice-show-tab {
  box-sizing: border-box;
  padding: 0 20px!important;
  .rich-text {
    display: flex;
    margin-top: 20px;
    margin-bottom: 24px;
    font-weight: bold;
    >p {
      flex: 0 0 100px;
    }
    .rich-title {
      p {
        display: inline;
      }
    }
  }
  .choices {
    margin-bottom: 20px;
    font-weight: 100;
    .choices-top {
      display: flex;
      align-items: center;
      .circle {
        display: inline-block;
        margin-right: 6px;
        font-size: 18px;
        color: #000;
      }
      .text {
        display: inline-block;
        font-size: 18px;
      }
      .switch {
        margin-right: 7px;
        vertical-align: inherit;
      }
    }
    .choice-body {
      margin-top: 16px;
      max-height: 420px;
      overflow: auto;
      .form-title { 
        color: #8a8a8a;
        border-top: 1px solid #d7d7d7;
        div:nth-child(1) {
          border-right: 1px solid #d7d7d7;
        }
      }
      .form-body {
        border-bottom: 1px solid #d7d7d7;
        font-weight: 100;
        .form-row {
          border-top: 1px solid #d7d7d7;
          .ant-col {
            padding: 10px 10px 10px 40px;
            line-height: 38px;
            &:nth-child(1) {
              border-right: 1px solid #d7d7d7;
            }
          }
          .desc {
            margin-bottom: 16px;
            font-weight: 100;
            line-height: 41px;
            .desc-select {
              width: 100px;
              height: 41px;
              text-align: center;
              border: 1px solid #E1E1E8;
              margin: 0 8px;
              .ant-select-selector {
                height: 41px;
                border-radius: 0px;
                .ant-select-selection-item {
                  line-height: 41px;
                }
              }
            }
          }
          .add {
            margin-bottom: 8px;
            .select-box {
              position: relative;;
              margin-bottom: 16px;
              .condition-sort {
                font-size: 16px;
                font-weight: bold;
                display: inline-block;
                // vertical-align:bottom;
              }
              .close {
                position: absolute;
                top: 6px;
                right: 16px;
                font-size: 16px;
                cursor: pointer;
              }

            }
            .add-btn {
              margin: 0 auto;
              width: 30px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              border: 1px dashed transparent;
              background: linear-gradient(white,white) padding-box,
              repeating-linear-gradient(-45deg,#ccc 0, #ccc 0.25em,white 0,white 0.55em);
              .pointer();
            }
          }
        }
        
      }
      .center {
        text-align: center;
        .ellipsis()
      }
      .padding {
        padding: 10px;
      }
    }
  }
  .ref {
    margin-bottom: 20px;
    font-weight: 100;
    .ref-top {
      display: flex;
      align-items: center;
      .circle {
        display: inline-block;
        margin-right: 6px;
        font-size: 18px;
        color: #000;
      }
      .text {
        display: inline-block;
        font-size: 18px;
      }
      .switch {
        margin-right: 7px;
        vertical-align: inherit;
      }
    }
    .ref-body {
      margin-top: 20px;
      padding-left:20px;
    }
  }
}
</style>