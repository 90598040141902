/*
 * @Description: 逻辑监听相关操作
 * @version: 
 * @Author: PSG
 * @Date: 2021-07-26 10:18:02
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-27 14:26:45
 */
import globalQuestionnaire from '@/class/useQuestionnaire'

const logicWatcherEffect = () => {
  const questionnaire = globalQuestionnaire.getQuestionnaire()
  const questions = questionnaire.questions && questionnaire.questions.filter(question => !/^(PAGE|PART)$/.test(question.questionType))
  /**
   * 将逻辑表达式转化为条件表达式
   * @param {*} logicExpress 
   * @returns 
   */
  const logic2Condition = (logicExpress) => {
    // logicExpress = "0;0,1&1;0,1&4;0^0,1,2,3,4&5;0^0,1,2,3,4"
    if (!logicExpress) return true
    const outCondition = logicExpress.includes('&') ? ' && ' : ' || '
    const expressGruop = logicExpress.split(/&|\|/)
    return expressGruop.reduce((resultExpressArr, express) => {
      const inExpStrArr = []
      const inCondition = express.includes('.') ? ' && ' : ' || '
      const expInfo = express.split(/[^\w]/)
      const refQuestionId = expInfo[0]
      const refQuestion = questions.filter(quesiton => quesiton.questionId === refQuestionId)[0]
      const refQuestionSort = refQuestion.sort
      let resultExpress = `(questions[${refQuestionSort}].answer.questionId && ({0}))`
      if (refQuestion.visible === false) { // 如果被关联的题目可见性是false，则本题目也是不可见的
        inExpStrArr.push('false')
      }
      else if (refQuestion.answer.questionId) {
        if (/\^/.test(express)) { // 将矩阵量表、矩阵单选的逻辑表达式 转化成 条件表达式  e.g.  5;0^0,1,2,3,4
          const rowTitleIndex = expInfo[1]
          expInfo.forEach((item, index) => {
            if (index > 1) {
              inExpStrArr.push(`questions[${refQuestionSort}].answer.tableCheck[${rowTitleIndex}][${item}]`)
            }
          })
        } else { // 将单选、多选的逻辑表达式 转化成 条件表达式 e.g. 0;0,1|1;0,1
          expInfo.forEach((expChoiceId, index) => {
            if (index > 0) {
              inExpStrArr.push(`questions[${refQuestionSort}].answer.choices.findIndex((item) => item.choiceId === '${expChoiceId}') >= 0`)
            }
          })
        }
      } else { // 关联的题目答案为空，直接设为false
        inExpStrArr.push('false')
      }
      
      resultExpress = resultExpress.replace(/{\d+}/g, () => inExpStrArr.join(inCondition))
      resultExpressArr.push(resultExpress)
      return resultExpressArr
    }, []).join(outCondition)
  }
  /**
   * 判断题目是否已作答
   * @param {*} question 
   * @returns 
   */
  const isAlreadyAnswer = (question) => {
    let bool = false
    if (/^(SINGLE_CHOICE|MULTIPLE_CHOICE|DROP_DOWN|SCALE)$/.test(question.questionType)) {
      bool = (question.answer && question.answer.choices && question.answer.choices.length > 0) ? true : false
    } else if (/^(MATRIX_SCALE|MATRIX_SINGLE_CHOICE|MATRIX_MULTIPLE_CHOICE)$/.test(question.questionType)) {
      if (question.answer && question.answer.tableCheck) {
         // 是否存在有一行的某一列存在true
        bool = Object.values(question.answer.tableCheck).some(rowAnswerObj => 
          Object.values(rowAnswerObj).some(colAnswer => colAnswer === true)
        ) ? true : false
      }
    } else if (/^(SHORT|DATE)$/.test(question.questionType)) {
      bool = (question.answer && question.answer.fillText && question.answer.fillText.length > 0) ? true : false
    }
    return bool
  }
  /**
   * 根据当前编辑题目的页码，查看本页后还是否存在可显示的数据，判断是否最后一页
   * @param {*} curQuestion 
   * @returns 
   */
  const isLastPage = (curQuestion) => {
    const curPage = curQuestion.page
    const list = questionnaire.questions.filter(question => question.questionType !== 'PART' && question.page > curPage && question.visible)
    return (list.length === 0 ? true : false)
  }
  /**
   * 逻辑监听
   * @param {*} question 
   * @returns 
   */
  const watchVisibilityEffect = (question) => {
    // 首先复原全部题目的visibile属性（原因是选项跳题的时候，用户会在选项之前来回勾选或取消）
    questions.forEach(question => {
      question.visible = question.showLogic ? false : true
    })
    if (!questions) return
    const condJumpQuestoin = questions.filter(question => /^{/.test(question.jumpLogic))
    const noCondJumpQuestion = questions.filter(question => question.jumpLogic && /^-?\w+$/.test(JSON.parse(question.jumpLogic)))
    const questShowQuestion =  questions.filter(question => question.showLogic)
    const choiceShowQuestion = questions.filter(question => question.choices && question.choices.some(choice => { return (choice.showLogic ? true : false) }))
    const choiceRefQuestion = questions.filter(question => /^(SINGLE_CHOICE|MULTIPLE_CHOICE)$/.test(question.questionType) && question.ref)
    const rowTitleRefQuestion = questions.filter(question => /^(MATRIX_SINGLE_CHOICE|MATRIX_MULTIPLE_CHOICE|MATRIX_SCALE)$/.test(question.questionType) && question.ref)
    // 1、题目显示监听
    if (questShowQuestion) {
      questShowQuestion.forEach(question => {
        const conditionExp = logic2Condition(question.showLogic)
        question.visible = eval(conditionExp) ? true : false
      })
    }
    // 2、选项显示监听
    if (choiceShowQuestion) {
      choiceShowQuestion.forEach(question => {
        question.choices.forEach(choice => {
          const conditionExp = logic2Condition(choice.showLogic)
          choice.visible = eval(conditionExp) ? true : false
        })
      })
    }
    // 3、选项引用监听
    if (choiceRefQuestion) {
      choiceRefQuestion.forEach(question => {
        const refQuestion = questions.filter(q => q.questionId === question.ref)[0]
        const refChoices= refQuestion.answer.questionId && refQuestion.choices.filter((choice, index) => { 
          // 什么样的选项才是被引用的选项？
          // 首先是要被refQuestion勾选了的选项（存在答案里面）
          // 因此，只需要循环refQuestion.choices里面的每一个选项，根据choiceId，只要能在refQuestion.answer找到，就返回该选项
          return refQuestion.answer.choices.findIndex(answerChoice => answerChoice.choiceId === choice.choiceId) >= 0
        })
        if (refChoices) question.refChoices = JSON.parse(JSON.stringify(refChoices))
      })
    }
    // 4、行标题引用监听
    if (rowTitleRefQuestion) {
      rowTitleRefQuestion.forEach(question => {
        // 首先把行标题重置成不可见visibile=false
        question.rowItems.forEach(row => row.visible = false)
        // 存在这样一种情况，多选题可以引用多选题，造成多层引用，最后矩阵题能够行标题引用多选题，那么此时矩阵题的行标题，
        // 保存的时候，前端不需要处理，后端在保存的时候，矩阵的行标题rowItems的数据等于嵌套引用的根节点(多选题)的选项
        // 这样做的原因是，在后端统计的时候，就能够根据下标进行数据统计、导出
        const refQuestion = questions.find(item => item.questionId === question.ref)
        refQuestion.answer.questionId && refQuestion.answer.choices.forEach(answerChoice => {
          const selectedIndex = refQuestion.choices.findIndex(choice => choice.choiceId === answerChoice.choiceId)
          question.rowItems[selectedIndex].visible = true
        })
        // const refRowItems= refQuestion.answer.questionId && refQuestion.choices.filter((choice, index) => { 
        //   return refQuestion.answer.choices.findIndex(answerChoice => answerChoice.choiceId === choice.choiceId) >= 0
        // }).map(choice => ({ 
        //   'description': choice.description,
        //   'text': choice.text
        // }))
        // if (refRowItems)  question.refRowItems = JSON.parse(JSON.stringify(refRowItems))
      })
    }
    // 5、跳题监听
    if (condJumpQuestoin.length > 0) {
      condJumpQuestoin.forEach(curQuestion => {
        if (curQuestion.visible && curQuestion.answer?.choices && curQuestion.answer.choices.length === 1) { // 存在答案才进行下一步
          const jumpLogic = JSON.parse(curQuestion.jumpLogic)
          const answerChoiceId = curQuestion.answer.choices[0].choiceId
          const targetQuestionId = jumpLogic[answerChoiceId]
          if (targetQuestionId) {
            if (targetQuestionId == -1) return
            if (targetQuestionId == -2) {
              questions.forEach(question => { 
                question.sort > curQuestion.sort ? (question.visible = false) : null
              }) 
            } else {
              const targetQuestion = questions.filter(question => question.questionId === targetQuestionId)[0] 
              questions.forEach(question => {
                if (question.sort > curQuestion.sort && question.sort < targetQuestion.sort)  question.visible = false // 隐藏排序在curQuestion.sort与targetSort之间的题目
                else if (question.sort === targetQuestion.sort) question.visible = true  // 显示目标题目（且跳题显示优先）
                else if (question.page === targetQuestion.page && !question.showLogic) question.visible = true // 显示跟targetPage同一页的题目（除了设置显示逻辑的）
              }) 
            }
          }
        }
      })
    }
    // 6、无条件跳题监听
    if (noCondJumpQuestion.length > 0) {
      noCondJumpQuestion.forEach(curQuestion => {
        if (curQuestion.visible && isAlreadyAnswer(curQuestion)) { // 存在答案才进行下一步
          const targetQuestionId = JSON.parse(curQuestion.jumpLogic)
          if (targetQuestionId == -1) return
          if (targetQuestionId == -2) {
            questions
              .filter(question => question.sort > curQuestion.sort)
              .forEach(question => question.visible = false)
          } else {
            const targetQuestion = questions.filter(question => question.questionId === targetQuestionId)[0] 
            questions.forEach(question => {
              if (question.sort > curQuestion.sort && question.sort < targetQuestion.sort)  question.visible = false // 隐藏排序在curQuestion.sort与targetSort之间的题目
              else if (question.sort === targetQuestion.sort) question.visible = true  // 显示目标题目（且跳题显示优先）
              else if (question.sort > targetQuestion.sort 
                && question.page === targetQuestion.page 
                && !question.showLogic) 
                question.visible = true // 显示跟targetPage同一页且序号大于targetQuestion的题目（除了设置显示逻辑的）
            })
          } 
        }
      })
    }
    globalQuestionnaire.setIsLastPage(isLastPage(question))
  }

  return {
    watchVisibilityEffect,
  }
}

export default logicWatcherEffect