<!--
 * @Description: 章节
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-19 09:58:51
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-09 17:18:38
-->
<template>
  <!-- 电脑端 -->
  <div class="part" v-if="isPc">
    <div class="preview" v-if="isOnlyRead">   
      <div class="preview-read">
        <div class="preview-edit-title">{{atitle}}</div>
        <div v-if="!item.descriptionCSS" class="preview-edit-description">{{adescription}}</div>
        <RichTitle v-else style="width: 100%;" :title="item.description"/>
      </div>
      <div class="border-bottom"></div>
    </div>
    <div class="preview-edit" v-else @click="handleEditorModel(questionnaire.partMap[item.part])">
      <div class="sort-icon">
        <div 
          class="preview-sort iconfont" 
          v-if="!isOnlyRead && !route.query.isKeepAnswerAndEdit"
        >&#xe697;</div>
      </div>
      
      <div 
        class="preview-del iconfont" 
        @click="handleDelete" 
        v-if="!isOnlyRead && !route.query.isKeepAnswerAndEdit"
      >&#xe609;</div>
      <div class="editor-icon iconfont" @click="handleEditorModel(questionnaire.partMap[item.part])">T</div>
      <div class="title-input">
        <a-input v-model:value="atitle" placeholder="章节标题"/>
      </div>
      <div class="description">
        <RichInput
          class="rich-title"
          ref="inputRef"
          :text="questionnaire.partMap[item.part].description"
          @finishEditText="updateEditor"
          placeholder="添加章节说明"
        />
      </div>
    </div>
  </div>
  <!-- 手机端 -->
  <div class="mobile-part" v-else>
    <div class="preview">   
      <div class="preview-read">
        <div class="preview-edit-title">{{atitle}}</div>
        <div v-if="!item.descriptionCSS" class="preview-edit-description">{{adescription}}</div>
        <RichTitle v-else style="width: 100%;" :title="item.description"/>
      </div>
    </div>
  </div>
  <TitleModal
    ref="editorRef"
    modalTitle="章节标题"
    modalSubTitle="章节说明"
    :title="atitle"
    :visible="isShowEditorModal" 
    :text="text"
    @finishEditText="updateInput"
  />
</template>

<script>
import { reactive, ref, computed, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import { groupBy } from '@/utils/arrayUtil'
import globalQuestionnaire from '@/class/useQuestionnaire'
import TitleModal from '@/components/modal/TitleModal'
import RichTitle from '../components/richTitle'
import editorEffect from '../components/effects/editorEffect'
import RichInput from '../components/richInput'

/**
 * 删除章节组件
 */
const deleteEffect = (props) => {
  const handleDelete = () => {
    const questionnaire = globalQuestionnaire.getQuestionnaire()
    const startIndex = props.index 
    const curPart = props.item
    // 1、查找上一章节组件
    let prePart = null
    for (let i = startIndex - 1; i >= 0; i--) {
      if (questionnaire.questions[i].questionType === 'PART') {
        prePart = questionnaire.questions[i]
        break
      }
    }
    // 2、将题目按章节分组，将当前章节的题目归并到上一章节
    let questions = questionnaire.questions.filter(question => !/^(PART|PAGE)/.test(question.questionType))
    if (questions.length > 0) {
      let questionGroup = groupBy(questions, 'part')
      questionGroup[curPart.part] && 
      questionGroup[curPart.part].forEach(question => {
        question.part = prePart ? prePart.part : null
      })
    }
    // 3、删除当前章节数据
    delete questionnaire.partMap[curPart.part]
    questionnaire.questions.splice(startIndex, 1)
  }
  return { handleDelete }
}

export default {
  name: 'Part',
  props: ['item', 'index', 'isOnlyRead', 'isPc'],
  components: {
    TitleModal,
    RichTitle,
    RichInput
  },
  setup(props) {
    const route = useRoute()
    const questionnaire = globalQuestionnaire.getQuestionnaire()
    const atitle = computed({
      get: () => props.item.title,
      set: (newVal) => {
        questionnaire.questions[props.index].title = newVal
        questionnaire.partMap[props.item.part].title = newVal 
      }
      
    })
    const adescription = computed({
      get: () => props.item.description,
      set: (newVal) => { 
        questionnaire.questions[props.index].description = newVal
        questionnaire.partMap[props.item.part].description = newVal 
      }
    })
    const { handleDelete } = deleteEffect(props)
    const editorRef = ref(null)
    const inputRef = ref(null)
    const { isShowEditorModal, text, changeOption, handleEditorModel, changeEditor, changeTitle } = editorEffect('description')


    changeOption(questionnaire.partMap[props.item.part])
    const updateInput = (data) => {
      if (data) {
        changeEditor(data)
        questionnaire.questions[props.index].title = data.title
        questionnaire.partMap[props.item.part].title = data.title
        if (inputRef.value) {
          inputRef.value.updateText(data.text)
        }
      } else {
        isShowEditorModal.value = false
      }

    }

    const updateEditor = (title) => {
    }

    return {
      route,
      atitle,
      adescription,
      handleDelete,
      questionnaire,
      editorRef,
      inputRef,
      isShowEditorModal, text, handleEditorModel, changeEditor,
      updateEditor,
      updateInput
    }
  },
}
</script>

<style scoped lang="less">
// 电脑端
.part {
  .border-bottom {
    margin-top: 24px;
    border-bottom: 1px solid #E1E1E8;
  }
  .preview-edit {
    position: relative;
    min-height: 147px;
    font-size: 16px;
    text-align: center;
    background-color: #fff;
    border-top: 4px solid #00A0E9;
    border-bottom: 1px solid #E1E1E8;
    .iconfont:hover {
      background-color: @hoverBackground;
      cursor:pointer;
    }
    .sort-icon {
      position: relative;
      width: 24px;
      height: 24px;
      margin: 8px auto 0 auto;
      .preview-sort{
        position: absolute;
        width: 24px;
        height: 24px;
        font-size: 24px;
        transform: rotate(90deg);
        color: #b7b7b7;
      }
    }
    .editor-icon {
      position: absolute;
      width: 24px;
      height: 24px;
      top: 38px;
      right: 49px;
      text-align: center;
      margin: 0 auto;
      font-size: 20px;
      color: #00A0E9;
      font-weight: bold;
      cursor: pointer;
      border: 2px solid #00A0E9;
      border-radius: 2px;
    }
    .preview-del {
      position: absolute;
      top: 38px;
      right: 93px;
      color: #00A0E9;
      font-size: 24px;
      z-index: 99;
    }
    .title-input {
      width: 800px;
      margin-left: 50px;
      input {
        display: block;
        text-align: left;
        font-size: 26px;
        font-weight: bold;
        color: #00A0E9;
        border: none;
        padding: 0;
      }
    }
    .description {
      display: flex;
      text-align: initial;
      margin-top: 10px;
      .rich-title {
        flex: 1;
        margin-left: 40px;
      }
    }
  }
  .preview {
    position: relative;
    padding: 24px 20px 0 20px;
    background-color: #fff;
    border-top: 4px solid #00A0E9;
    .preview-read { 
      font-size: 16px;
      text-align: center;
      div {
        display: block;
        border: none;
        text-align: center;
        font-size: 26px;
        font-weight: bold;
        line-height: 40px;
        padding: 0;
        color: #00A0E9;
        &:nth-child(2) {
          text-align: left;
          font-size: 16px;
          font-weight: normal;
          line-height: 26px;
          margin-top: 20px;
          color: #7F7F88;
        }
      }
    }
  }
} 
// 手机端
.mobile-part {
  padding: 25px 20px;
  background-color: #fff;
  border-bottom: 1px solid #E1E1E8;
  .preview {
    .iconfont:hover {
      background-color: @hoverBackground;
      cursor:pointer;
    }
    .preview-sort{ 
      margin: 0 auto;
      font-size: 14px;
      width: 16px;
      transform: rotate(90deg);
      color: #b7b7b7;
    }
    .preview-del {
      position: absolute;
      top: 20px;
      right: 50px;
      color: #000;
      font-size: 26px;
      z-index: 99;
    }
    .preview-edit { 
      font-size: 16px;
      text-align: center;
      margin-top: 10px;
      input {
        display: block;
        border: none;
        border-bottom: 1px solid #d7d7d7;
        text-align: left;
        font-size: 26px;
        padding: 0;
        &:nth-child(1) {
          width: 90%;
        }
        &:nth-child(2) {
          text-align: left;
          font-size: 16px;
          margin-top: 32px;
        }
      }
      .description {
        display: flex;
        text-align: initial;
        margin-top: 10px;
        .editor {
          width: 16px;
          margin: 0 auto;
          font-size: 20px;
          color: black;
          font-weight: bold;
          cursor: pointer;
        }
        .rich-title {
          flex: 1;
          margin-right: 10px;
        }
      }
    }
    .preview-read { 
      font-size: 16px;
      text-align: center;
      div {
        display: block;
        border: none;
        text-align: left;
        font-size: 26px;
        font-weight: bold;
        line-height: 40px;
        padding: 0;
        color: #00A0E9;
        &:nth-child(2) {
          text-align: left;
          font-size: 16px;
          font-weight: normal;
          line-height: 26px;
          margin-top: 20px;
          color: #7F7F88;
        }
      }
    }
  }
} 
// 公用部分
.noborder {
  border: none;
}
.hide {
  display: none;
}

.isAlreadySet {
  color: #F59A23;
}
</style>
