<!--
 * @Description: 题目/选项 说明弹出框
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-23 11:45:35
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-06 14:51:43
-->
<template>
  <div>
    <a-modal 
      v-model:visible="visible" 
      :title="title" 
      @ok="handleOk"
      class="titleDescModals"
      :closable="false"
      width="1046px"
      @cancel="handleClose"
    >
      <span class="iconfont close" @click="handleClose">&#xe61c;</span>
      <div class="main">
        <div>选项说明：</div>
        <a-textarea v-model:value="text" allow-clear auto-size/>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { reactive, ref, watchEffect, toRefs } from 'vue'

const watchPropsEffect = (props) => {
  const modalInfo = reactive({
    visible: props.visible,
    title: props.title,
    text: props.text
  })
  watchEffect(() => {
    modalInfo.visible = props.visible
    modalInfo.title = props.title
    modalInfo.text = props.text
  })
  const { visible, title, text } = toRefs(modalInfo)
  return { visible, title, text }
}

export default({
  name: 'DescModal',
  props: ['visible',  // 控制显示隐藏
          'title', // 模态窗标题
          'text', // 输入框内容
  ],
  /**
   * 程序入口
   */
  setup(props, { emit }) {
    const { visible, title, text } = watchPropsEffect(props)
    const handleOk = () => {
      emit('finishEditText', text.value)
    }
    const handleClose = () => {
      emit('finishEditText', text.value)
    }

    return {
      visible,
      title,
      text,
      handleOk,
      handleClose,
    }
  },
})
</script>

<style lang='less'>
.titleDescModals {
  .ant-input-textarea-clear-icon {
    margin-right: 5px;
  }
  .main {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 16px;
    line-height: 41px;
    font-weight: 100;
    > div:nth-child(1) {
      width: 130px;
      text-align: right;
      white-space: nowrap;
    }
  }
  .ant-modal-content {
    position: relative;
    border: 16px solid #fff;
    border-radius: 16px;
    .ant-modal-header {
      border: none;
      .ant-modal-title {
        font-size: 20px;
        font-weight: bold;
      }
    }
    .ant-modal-body {
      .close {
        position: absolute;
        top: 8px;
        right: 24px;
        font-size: 25px;
        color: #00A0E9;
        .pointer()
      }
      .ant-input-affix-wrapper {
        min-height: 41px;
        border: 1px solid #d7d7d7;
        .ant-input {
          resize:none;
          line-height: 31px;
        }
        span {
          line-height: 31px;
        }
        svg {
          font-size: 18px;
        }
      }
    }
    .ant-modal-footer {
      border: none;
      div {
        text-align: center;
        button {
          height: 36px;
          width: 108px;
          font-weight: 100;
          color: #D3D3DD;
        }
        .ant-btn-primary {
          margin-left: 32px;
          color: #fff;
        }
        // button {
        //   background-color: #f59a23;
        //   border: none;
        //   color: #fff;
        //   &:nth-child(1) {
        //     // display: none;
        //   }
        // }
      }
    }
  }
  .ant-modal-footer {
    border: none;
    div {
      text-align: center;
      // button {
      //   background-color: #f59a23;
      //   border: none;
      //   color: #fff;
      //   &:nth-child(1) {
      //     // display: none;
      //   }
      // }
    }
  }
}
</style>