<!--
 * @Description: 单选题设置选项
 * @version: 
 * @Author: HWL
 * @Date: 2021-05-27 18:35:55
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-22 10:46:58
-->
<template>
  <div class="single-choice-option" v-show="!question.ref">
    <a-row type="flex" class="settings-toptitle">
      <a-col :span="15">选项内容</a-col>
      <a-col :span="3" class="settings-toptitle-desc">选项说明</a-col>
      <a-col :span="5" class="settings-toptitle-isfill">允许填空</a-col>
    </a-row>
    <draggable
      :list="question.choices"
      handle=".settings-options-sort"
      item-key="choiceId"
    >
      <template #item="{ element }">
      <a-row
        type="flex" 
        class="settings-options"
      >
        <span class="iconfont settings-options-sort">&#xe697;</span>
        <a-col :span="15">
          <a-textarea
            :id="'text-area_' + element.choiceId"
            class="settings-options-input" 
            v-model:value="element.text"
            placeholder="选项"
            @click.stop=""
            @keyup.enter.stop="handleStopInputEnter($event, element), handleChoiceAdd(item)"
            :disabled="!!route.query.isKeepAnswerAndEdit && !element.isNewAdd"
            auto-size
          />
          <span
            v-if="!route.query.isKeepAnswerAndEdit || element.isNewAdd"
            class="settings-options-del iconfont" 
            @click.stop="handleChoiceDelete(question, element.choiceId)"
          >&#xe6ce;</span>
        </a-col>
        <a-col :span="3" style="text-algin:center">
          <div 
            class="settings-options-desc iconfont" 
            :class="{isAlreadySet: element.description.length !== 0}" 
            @click="showOptionDescModal(element)">
            &#xe637;
          </div>
        </a-col>
        <a-col :span="5">
          <div class="settings-options-isfill" v-if="element.ifAllowFillInBlanks">
            <a-switch 
              size="small" 
              v-model:checked="element.ifAllowFillInBlanks" 
              :disabled="!!route.query.isKeepAnswerAndEdit && !element.isNewAdd" 
            />
            <p>必填</p>
            <a-switch 
              size="small" 
              v-model:checked="element.ifRequired" 
              :disabled="!!route.query.isKeepAnswerAndEdit && !element.isNewAdd" 
            />
          </div>
          <div class="settings-options-notfill" v-else>
            <a-switch 
              size="small" 
              v-model:checked="element.ifAllowFillInBlanks" 
              :disabled="!!route.query.isKeepAnswerAndEdit && !element.isNewAdd" 
            />
          </div>
        </a-col>
      </a-row>
      </template>
    </draggable>
    <div class="settings-add">
      <div @click="handleChoiceAdd(item)">
        <span class="iconfont">&#xe633;</span>
        <span class="text">添加</span>
      </div>
      <div v-if="!route.query.isKeepAnswerAndEdit" @click="showBatchAddModal(item)" >
        <span class="iconfont">&#xe633;</span>
        <span class="text">批量添加</span>
      </div>
      <div v-if="!route.query.isKeepAnswerAndEdit" @click="showChoiceLibraryModal(item)" >
        <span class="iconfont">&#xe633;</span>
        <span class="text">选项库</span>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import { useRoute } from 'vue-router'
import { handleChoiceAdd, handleStopInputEnter, handleChoiceDelete } from '../effects/choicesUpdateEffect'

export default {
  name: 'SingleChoiceOption',
  components: {
    draggable
  },
  props: [
    'item',
    'question',
  ],
  emits: [
    'showOption',
    'showBatch',
    'showChoiceLibrary',
  ],
  setup(props, { emit }) {
    const route = useRoute()

    const showOptionDescModal = (item) => {
      emit('showOption', item)
    }
    
    const showBatchAddModal = (item) => {
      emit('showBatch', item)
    }

    const showChoiceLibraryModal = (item) => {
      emit('showChoiceLibrary', item)
    }

    return {
      route,

      handleStopInputEnter,
      handleChoiceAdd,
      handleChoiceDelete,

      showBatchAddModal,
      showOptionDescModal,
      showChoiceLibraryModal,
    }
  }
}
</script>
<style lang="less" scoped>
.settings-toptitle {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  color: #8b8b8b;
  font-size: 14px;
  font-weight: 100;
  .settings-toptitle-desc {
    text-align: center;
  }
  .settings-toptitle-isfill {
    text-align: center;
  }
}
.settings-options {
  display: flex;
  position: relative;
  height: 100%;
  line-height: 41px;
  margin-top: 20px;

  .settings-options-sort {
    z-index: 99;
    font-size: 24px;
    color: #b7b7b7;
  }
  .settings-options-input {
    width: 400px!important;
    height: 100%;
    font-size: 16px;
    margin-left: 6px;
  }
  .settings-options-desc {
    text-align: center;
    font-size: 26px;
  }
  .settings-options-notfill {
    text-align: center;
    padding-left: 40px;
  }
  .settings-options-isfill {
    display: flex;
    padding-left: 30px;
    justify-content: flex-end;
    align-items: center;
    p {
      font-size: 12px;
      margin-left: 4px;
      margin-right: 10px;;
    }
    span {
      height: 26px;
      border-right: 1px solid #000;
      margin-top: -6px;
      margin-left: 10px;
    }
  }
  .settings-options-del {
  vertical-align: top;
    margin-left: 40px;
  }
  .iconfont {
    cursor:pointer;
    &:hover {
      background-color: @hoverBackground;
    }
  } 
}
.settings-add {
  margin-top: 20px;
  margin-left: 30px;
  div {
    display: inline-block;
    font-size: 14px;
    color: #00A0E9;
    text-align: center;
    margin-right: 24px;
    .pointer()
  }
  .iconfont {
    font-size: 12px;
    margin-right: 6px;
    border: 1px solid #00A0E9;
  }
  .text {
    text-decoration: underline;
  }
}
.isAlreadySet {
  color: #00A0E9;
}
</style>