<!--
 * @Description: 题目底部栏
 * @version: 
 * @Author: HWL
 * @Date: 2021-06-21 11:31:07
 * @LastEditors: HWL
 * @LastEditTime: 2021-06-21 12:01:50
-->
<template>
<div class="preivew-bottom">
  <div class="space"></div>
  <div class="border-bottom"></div>
</div>
</template>
<script>
export default {
  name: 'PreviewBottom'
}
</script>
<style lang="less" scoped>
  .preivew-bottom {
    .space {
      width: 100%;
      height: 40px;
    }
    .border-bottom {
      border-bottom: 1px solid #E1E1E8;
    }
  }
</style>