/*
 * @Description: 富文本编辑
 * @version: 
 * @Author: HWL
 * @Date: 2021-06-03 18:17:24
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-12 09:55:24
 */
import { ref } from 'vue'

function editorEffect(type = 'title') {
  const isShowEditorModal = ref(false)
  const text = ref('')
  let curOption
  
  function changeOption(item) {
    curOption = item
  }

  /**
   * 处理选项说明
   */
  function handleEditorModel(item) {
    if (!item) {
      return
    }
    curOption = item
    if (type === 'title') {
      text.value = curOption.title
    } else {
      text.value = curOption.description
    }
    
    isShowEditorModal.value = true
  }

  function changeTitle(data) {
    const { title, txt } = data
    if (type === 'title') {
      curOption.title = title
      curOption.titleCSS = 'true'
      curOption.titleContent = txt
    } else {
      curOption.description = title
      curOption.descriptionCSS = 'true'
      curOption.descriptionContent = txt
    }
  }

  function changeEditor(data) {
    const { text, txt } = data
    if (type === 'title') {
      curOption.title = text
      curOption.titleCSS = 'true'
      curOption.titleContent = txt
    } else {
      curOption.description = text
      curOption.descriptionCSS = 'true'
      curOption.descriptionContent = txt
    }
    isShowEditorModal.value = false
  }

  return {
    isShowEditorModal,
    text,
    title: text,
    changeOption,
    changeTitle,
    handleEditorModel,
    changeEditor
  }
}

export default editorEffect
