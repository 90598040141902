/*
 * @Description: 选项库
 * @version: 
 * @Author: PSG
 * @Date: 2021-07-09 10:32:16
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-12 15:17:26
 */
import { ref } from 'vue'
import { createUniqueId } from '@/utils/random'
/**
 * 选项库弹出框
 * @param {question} question 问题
 * @returns object
 */
const choiceLibraryEffect = (question) => {
  const isShowChoiceLibraryModal = ref(false)
  
  /**
   * 打开选项库弹出框
   */
  const openChoiceLibraryModal = () => {
    isShowChoiceLibraryModal.value = true
  }

  /**
   * 关闭选线库弹出框
   */
  const closeChoiceLibraryModal = () => {
    isShowChoiceLibraryModal.value = false
  }

  /**
   * 完成选项库选择回调处理
   */
  const handleChoiceLibraryChoose = (chooseList) => {
    chooseList.forEach(choiceText => {
      question.choices.push({
        'choiceId': createUniqueId(10), //选项id
        'text': choiceText, //选项内容
        'description': '', //选项描述
        'ifAllowFillInBlanks': false, //是否能填空
        'ifRequired': false, //是否必填
        'pic': '', //图片
        'isNewAdd': true, // 是否是新添加的项
      })
    })
    isShowChoiceLibraryModal.value = false
  }
  return {
    isShowChoiceLibraryModal,
    openChoiceLibraryModal,
    closeChoiceLibraryModal,
    handleChoiceLibraryChoose,
  }
}

export default choiceLibraryEffect