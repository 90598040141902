<!--
 * @Description: 简答题设置选项
 * @version: 
 * @Author: HWL
 * @Date: 2021-06-28 14:38:35
 * @LastEditors: HWL
 * @LastEditTime: 2021-06-28 15:43:29
-->
<template>
  <div class="short-option">
     <a-row 
        type="flex" 
        class="settings-options"
        v-for="item in question.choices"
        :key="item.choiceId"
      >
        <span class="iconfont settings-options-sort">&#xe697;</span>
        <a-col :span="24">
          <a-input 
            class="settings-options-input" 
            v-model:value="item.text" 
            @click.stop=""
            :disabled="!!route.query.isKeepAnswerAndEdit && !item.isNewAdd"
          />
          <span 
            v-if="!route.query.isKeepAnswerAndEdit || item.isNewAdd"
            class="settings-options-del iconfont" 
            @click.stop="handleChoiceDelete(item.choiceId)"
          >&#xe6ce;</span>
        </a-col>
      </a-row>
  </div>
</template>
<script>
import { useRoute } from 'vue-router'
import { handleChoiceAdd, handleChoiceDelete } from '../effects/choicesUpdateEffect'

export default {
  name: 'ShortOption',
  props: [
    'item',
    'question'
  ],
  setup(props, { emit }) {
    const route = useRoute()

    return {
      route,

      handleChoiceAdd,
      handleChoiceDelete
    }
  }
}
</script>
<style lang="less" scoped>
.short-option {
  .settings-options {
    display: flex;
    position: relative;
    margin-top: 20px;
    .settings-options-sort {
      position: absolute;
      top: 6px;
      left: -20px;
      z-index: 99;
      color: #b7b7b7;
    }
    .settings-options-input {
      width: 92%!important;
      font-size: 16px;
    }
    .settings-options-desc {
      text-align: center;
      font-size: 26px;
    }
    .settings-options-notfill {
      text-align: center;
    }
    .settings-options-isfill {
      display: flex;
      padding-left: 30px;
      p {
        font-size: 12px;
        margin-left: 4px;
        margin-right: 10px;;
      }
      span {
        height: 26px;
        border-right: 1px solid #000;
        margin-top: -6px;
        margin-left: 10px;
      }
    }
    .settings-options-del {
  vertical-align: top;
      margin-left: 40px;
    }
    .iconfont {
      cursor:pointer;
      &:hover {
        background-color: @hoverBackground;
      }
    } 
  }
}
</style>