<!--
 * @Description: 题目标题
 * @version: 
 * @Author: HWL
 * @Date: 2021-06-07 11:09:05
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-08 16:40:50
-->
<template>
  <div class="preview-title-warpper" :class="isPc ? 'pc' : 'mobile'" :style="{lineHeight: `${titleHeight}px`}">
    <div class="title" ref="titleRef" :class="{setting: !isOnlyRead}">
      <span class="requried" v-if="question.ifRequired" :style="{lineHeight: `${titleHeight}px`}">*</span>
      <p class="sort" :style="{lineHeight: `${titleHeight}px`}">{{question.sort + 1}}.&nbsp;</p>
      <RichTitle class="rich-title" :title="question.title">
        <span class="preview-title-tips" v-if="question.minSelect || question.maxSelect">
          (
            {{question.minSelect ? `至少选${question.minSelect}项，` : ''}}
            {{question.maxSelect ? `至多选${question.maxSelect}项` : ''}}
          )
        </span>
      </RichTitle>
    </div>
    <div class="preview-descript">{{question.description}}</div>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import RichTitle from './richTitle'

export default {
  name: 'PreviewTitle',
  components: {
    RichTitle
  },
  props: [
    'question',
    'isPc',
    'isOnlyRead'
  ],
  setup(props) {
    const titleRef = ref(null)
    const titleHeight = ref(40)

    return {
      titleRef,
      titleHeight
    }
  }
}
</script>
<style lang="less" scoped>
.preview-title-warpper {
  .title {
    display: flex;
    width: 100%;
    .rich-title {
      ::v-deep(p) {
        display: inline;
      }
    }
  }
  .setting {
    width: 800px !important;
  }
  .preview-title-star {
    color: red;
  }
  .preview-title-tips {
    color: #00A0E9;
    font-size: 14px;
    margin-left: 16px;
    white-space: nowrap;
  }
  &.pc {
    font-size: 20px;
    font-weight: bold;
    color: #323336;
    .preview-descript{ 
      font-size: 14px;
      font-weight: 100;
      line-height: 20px;
      color: #aeaeae;
      margin: 16px 0;
    }
  }
  &.mobile {
    font-size: 20px;
    line-height: 32px;
    font-weight: bold;
    color: #323336;
    .preview-descript{ 
      font-size: 14px;
      font-weight: 100;
      line-height: 30px;
      margin-top: 4px;
      color: #aeaeae;
      padding-left: 20px;
    }
  }
  span {
    color: red;
  }
  .requried {
    margin-right: 2px;
  }
}
</style>