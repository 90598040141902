/*
 * @Description: 选项说明
 * @version: 
 * @Author: HWL
 * @Date: 2021-05-26 16:19:09
 * @LastEditors: HWL
 * @LastEditTime: 2021-06-02 14:38:27
 */
import { ref } from 'vue'

/**
 * 选项说明
 * @returns object
 */
function optionDescModalEffect() {
  const isShowOptionDescModal = ref(false)
  const optionDesc = ref('')
  let curOption
  /**
   * 处理选项说明
   */
  function handleOpenOptionDescModal(item) {
    curOption = item
    optionDesc.value = curOption.description
    isShowOptionDescModal.value = true
  }

  /**
   * 更改选项说明
   * @param {string} description 描述
   */
  function changeOptionDesc(description) {
    isShowOptionDescModal.value = false
    curOption.description = description
  }

  return {
    isShowOptionDescModal,
    optionDesc,
    handleOpenOptionDescModal,
    changeOptionDesc
  }
}

export default optionDescModalEffect

/**
 * 处理选项说明
 * @param {question} item 问题
 * @param {boolean} isShowOptionDescModal 是否显示选项说明
 * @param {string} optionDesc 选项说明
 */
export function handleOpenOptionDescModal(item, isShowOptionDescModal, optionDesc) {
  optionDesc.value = item.description
  isShowOptionDescModal.value = true
}


