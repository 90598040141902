<!--
 * @Description: 题库题目
 * @version: 
 * @Author: PSG
 * @Date: 2021-07-07 15:13:19
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-14 17:56:02
-->
<template>
  <div class="option-wrap">
    <div class="question-top">
      <div class="left" @click="handleTitleClick(question.id)">{{question.info}}</div>
      <div class="right">
        <div
          v-if="question.choices && question.choices.length > 0" 
          :class="{'choice-library': true, 
                   'choice-library-active': checkedList.length > 0
          }"
        >
          <!-- <span class="choice-library-icon iconfont">&#xe624;</span> -->
          <!-- <span class="choice-library-text" @click="handleTitleClick">选项库</span> -->
        </div>
        <a-checkbox
          v-model:checked="choiceTitileChecked"
          @change="onQuestionCheckChange"
        />
      </div>
    </div>
    <div class="question-bottom" v-show="choicesVisible">
      <!-- <div class="choice-desc">选项描述</div> -->
      <div class="choices-wrap">
        <a-checkbox-group v-model:value="checkedList">
          <a-checkbox 
            v-for="(choice, index) in choiceList"
            :value="choice"
          >{{choice}}</a-checkbox>
        </a-checkbox-group>
      </div>
      <div class="choice-all">
        <a-checkbox 
          v-model:checked="checkAll"
          :indeterminate="indeterminate"
          @change="onCheckAllChange"
        >全选</a-checkbox>
      </div>
    </div>
  </div>
</template>

<script>

import globalQuestionnaire from '@/class/useQuestionnaire'


import { computed, reactive, ref, toRefs, watch } from 'vue'
import { createUniqueId } from '@/utils/random'
import { deepCopy } from '@/utils/deepCopy'
import { questionType2Component } from '@/utils/enum.js'
import { getChoiceListById } from '@/api/library'

export default {
  name: 'Question',
  props: {
    item: Object
  },
  setup (props, ctx) {
    const categoryId = props.item.id
    const choiceTitileChecked = ref(false)
    const choicesVisible = ref(false) // 是否显示选项内容
    const plainOptions = [] // 所有选项的choiceId
    const choiceList = ref([])
    const question = computed({ get: () => props.item})
    const state = reactive({
      indeterminate: false,
      checkAll: false, // 是否全选
      checkedList: [], //已选中的选项id
    })
    // 展开、隐藏选项列表
    const handleTitleClick = async (id) => {
      if (!choicesVisible.value) {
        const result = await getChoiceListById(id)
        if (result.status === 200 && result.data?.code === '00000' && result?.data?.data) {
          const options = result.data.data.options 
          Object.assign(plainOptions, result.data.data.options)
          choiceList.value = options
        } else {
          message.error(' 请求选项内容失败，失败原因：' + result.data.msg)
        }
      }
      choicesVisible.value = !choicesVisible.value
    }
    // 第二次进来
    const choiceLibrary = globalQuestionnaire.getChoiceLibrary()
    if (Object.keys(choiceLibrary.value).length > 0 && choiceLibrary.value[categoryId]) {
      choiceTitileChecked.value = true
      state.checkedList = choiceLibrary.value[categoryId]
      state.indeterminate = true
      handleTitleClick(categoryId)
    }
    // 全选事件
    const onCheckAllChange = e => {
      Object.assign(state, {
        checkedList: e.target.checked ? plainOptions : [],
        indeterminate: false,
      })
      if (e.target.checked) {
        choiceLibrary.value[categoryId] = state.checkedList
      }
    }

    // 题目选择事件
    const onQuestionCheckChange = e => {
      if (!e.target.checked) {
        state.checkedList = []
      }
    }
    // 选项选择监听
    watch(
      () => state.checkedList,
      val => {
        // 全选状态更新
        state.indeterminate = !!val.length && val.length < plainOptions.length
        state.checkAll = val.length === plainOptions.length
        choiceTitileChecked.value = !!val.length
        if (val.length > 0) {
          choiceLibrary.value[categoryId] = state.checkedList
        } else {
          delete choiceLibrary.value[categoryId]
        }
      },
    )
    return {
      choiceTitileChecked,
      choiceLibrary,
      question,
      plainOptions,
      choiceList,
      choicesVisible,
      ...toRefs(state), 
      onCheckAllChange,
      onQuestionCheckChange,
      handleTitleClick
    }
  }
}
</script>

<style lang="less">
.option-wrap {
  color: #2D2D33;
  margin-bottom: 8px;
  .question-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 18px;
    font-size: 16px;
    font-weight: lighter;
    color: #2D2D33;
    background-color: #f7f7f7;
    &:hover{
      background-color: #f0f0f0!important;
    }
    .left {
      flex: 0 0 95%;
      line-height: 22px;
      cursor: pointer;
    }
    .right {
      display: flex;
      align-items: center;
      .choice-library {
        margin-right: 60px;
        cursor: pointer;
        &:hover {
          .choice-library-icon {
            cursor: pointer;
            color: #00A0E9
          }
          .choice-library-text {
            cursor: pointer;
            color: #00A0E9;
            text-decoration-color: #00A0E9;
          }
        }
        .choice-library-icon {
          color: #2D2D334A;
        }
        .choice-library-text {
          text-decoration: underline;
          text-decoration-color: #2d2d334a;
        }
        .active {
          color: #00A0E9
        }
      }
      .choice-library-active {
        .choice-library-icon {
          cursor: pointer;
          color: #00A0E9
        }
        .choice-library-text {
          cursor: pointer;
          color: #00A0E9;
          text-decoration-color: #00A0E9;
        }
      }
      .ant-checkbox-wrapper {
        font-weight: lighter;
        .ant-checkbox {
          // .ant-checkbox-inner {
          //   width: 24px;
          //   height: 24px;
          //   &::after {
          //     width: 8px;
          //     height: 16px;
          //   }
          // }
        }
        span {
          font-weight: lighter;
        }
      }
      
    }
  }
  .question-bottom {
    margin-top: 6px;
    border: 1px solid #E1E1E8;
    padding: 12px 18px;
    .choice-desc {
      font-size: 16px;
      font-weight: lighter;
      padding-bottom: 12px;
      border-bottom: 1px solid #E1E1E8;
    }
    .choices-wrap {
      .ant-checkbox-group {
        padding: 12px 0;
        display: flex;
        flex-wrap: wrap;
        .ant-checkbox-wrapper {
          font-weight: lighter;
          margin-left: 0;
          margin-bottom: 8px;
          flex: 0 0 33.33%;
          display: flex;
          .ant-checkbox {
            top: 3px;
          }
          .ant-checkbox-checked {
            height: auto;
            &::after {
              height: auto;
            }
          }
        }
      }
    }
    .choice-all {
      .ant-checkbox-wrapper>span {
        color: #00A0E9;
        text-decoration: underline;
        text-decoration-color: #00A0E9;
      }
    }
  }
}
</style>