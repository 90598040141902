/*
 * @Description: 逻辑表达式字符串化
 * @version: 
 * @Author: PSG
 * @Date: 2021-04-25 11:12:11
 * @LastEditors: PSG
 * @LastEditTime: 2021-05-18 13:41:07
 */
const useStringifyExpEffect = () => {
  // 显示逻辑表达式字符串化
  const stringifyShowLogicExp = (condition, showCondList) => {
    const groupExpression = []
    const conditionSymbol = condition.key === 1 ? '&' : '|'
    for (let i = 0; i < showCondList.length; i++) {
      let questShowCond = showCondList[i] // 当前条件题目
      let groupConditionSymbol  // 当前选项的关系符号（全部/任一）
      let expressStr // 当前条件题目 转化后的 表达式
      // 条件题目（单选、多选）表达式设置
      if (/^(SINGLE_CHOICE|MULTIPLE_CHOICE)$/.test(questShowCond.questionType)) {
        groupConditionSymbol = questShowCond.condition.key === 1 ? '.' : ','
        // 将chosenIds转化为选项所在位置的index
        expressStr = `${questShowCond.questionId.key};${questShowCond.chosenIds.join(groupConditionSymbol)}` 
      } else {
      // 条件题目（矩阵量表、矩阵单选）表达式设置
        groupConditionSymbol = ','
        let rowIndex = questShowCond.rowChosenId.key // 注意，矩阵量表、矩阵单选的
        expressStr = `${questShowCond.questionId.key};${rowIndex}^${questShowCond.chosenIds.join(groupConditionSymbol)}` 
      }
      groupExpression.push(expressStr)
    }
    let s = groupExpression.join(conditionSymbol)
    return groupExpression.join(conditionSymbol)
  }
  // 条件跳题表达式字符串化
  const stringifyCondJumpLogicExp = (question) => {
    // 现在默认是map形式存储
    // e.g. {0: 5, 1:8}   本题第1个选项，跳到第6题，本题第2个选项，跳到第8题
  }
  
  return {
    stringifyShowLogicExp,
    stringifyCondJumpLogicExp,
  }
}

export default useStringifyExpEffect