/*
 * @Description: 批量更新
 * @version: 
 * @Author: HWL
 * @Date: 2021-05-26 16:38:37
 * @LastEditors: HWL
 * @LastEditTime: 2021-05-27 16:26:41
 */
import { createUniqueId } from '@/utils/random'
import { ref } from 'vue'

/**
 * 批量更新
 * @param {question} question 问题
 * @returns object
 */
function batchUpdateEffect(question) {
  const isShowBatchAddModal = ref(false)
  const allOptionsText = ref('')
  /**
   * 打开批量新增弹出框
   * @param {question} question 问题
   */
  function openBatchAddModal(question) {
    // 获取所有选项
    allOptionsText.value = question.choices.map(item => { return item.text}).join('\n')
    isShowBatchAddModal.value = true
  }
  /**
   * 批量更新
   * @param {array} textarea 选项列表
   */
  function handleBatchUpdate(textarea) {
    const choices = question.choices
    isShowBatchAddModal.value = false
    // 关闭按钮
    if (textarea === undefined) {
      return 
    // 有文本，更新或新增或删除选项
    } else if (textarea) {
      for (let i = 0; i < textarea.length; i++) {
        if (choices[i]) {
          choices[i].text = textarea[i].trim()
        } else {
          question.choices.push({
            'choiceId': createUniqueId(10),
            'text': textarea[i].trim(),
            'description': '', 
            'ifAllowFillInBlanks': false, 
            'ifRequired': false, 
            'pic': ''
          })
        }
      }
      question.choices = question.choices.slice(0, textarea.length) // 删除批量里没有的项
    // 无文本，清空选项
    } else {
      question.choices = []
    }
  }

  return {
    isShowBatchAddModal,
    allOptionsText,
    openBatchAddModal,
    handleBatchUpdate
  }
}

export default batchUpdateEffect
