<!--
 * @Description: 选项库
 * @version: 
 * @Author: PSG
 * @Date: 2021-04-20 16:35:45
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-13 15:51:33
-->

<template>
  <div class="choice-library-modal">
    <div class="modal-mask"></div>
    <div class="modal-wrap">
      <div class="modal">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-header-title">选项库</div>
            <span 
              class="iconfont close"
              @click="onClose"
            >
              &#xe61c;
            </span>
          </div>
          <div class="modal-category">
            <span class="category-title">选项类型:</span>
            <a-select 
              class="category-select"
              v-model:value="selectedVal"
              @change="handleSelectedChange(selectedVal)"
            >
              <a-select-option 
                v-for="item in firstCategory"
                :value="item.id"
              >{{item.info}}</a-select-option>
            </a-select>
          </div>
          <div class="modal-body">
            <Category
              v-for="item in subCategory"
              :item="item"
              :key="item.id"
            />
          </div>
          <div class="modal-footer">
            <div class="bottom-cancel" @click="onClose">取消</div>
            <div class="bottom-finish" @click="onConfirm">添加{{totalNum}}个选项到当前题目中</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Category from './components/Category.vue'

import globalQuestionnaire from '@/class/useQuestionnaire'

import { useStore } from 'vuex'
import { ref, onUnmounted, computed} from 'vue'

import { getCLibraryCategory } from '@/api/library'

const FIRST_LEVEL = 1

/**
 * 选项库信息
 */
const libraryEffect = () => {
  const store = useStore()
  const selectedVal = ref('')
  const firstCategory = ref([])
  const subCategory = ref([])
  // 选项类别change事件
  const handleSelectedChange = (categoryId) => {
    let curSelectedCategory = firstCategory.value.find(item => item.id === categoryId)
    subCategory.value = curSelectedCategory.subs ? curSelectedCategory.subs : [].concat(curSelectedCategory)
  }
  // 为层级树设置层级信息
  const setLevelInfo = (list, level) => {
    const curLevel = level
    list.forEach(item => {
      item.level = curLevel
      if (item.subs && item.subs.length > 0) {
        item.isLeaf = false
        const nextLevel = curLevel + 1
        setLevelInfo(item.subs, nextLevel)
      } else {
        item.isLeaf = true
      }
    })
    return list
  }
  // 获取选项库类别信息
  const getCatetoryInfo = async () => {
    const result = await getCLibraryCategory()
    if (result.status === 200 && result.data?.code === '00000' && result?.data?.data) {
      firstCategory.value = setLevelInfo(result.data.data.subs, FIRST_LEVEL)
      subCategory.value = firstCategory.value[0].subs
      selectedVal.value = firstCategory.value[0].id
    } else {
      message.error(' 请求题库失败，失败原因：' + result.data.msg)
    }
    store.commit('changeCancelSpinning', true)
  }
  getCatetoryInfo()
  return {
    selectedVal,
    firstCategory,
    subCategory,
    handleSelectedChange,
  }
}

/**
 * 模态窗按钮相关操作
 */
const buttonEffect = (ctx) => {
  const onConfirm = () => {
    const choiceLibrary = globalQuestionnaire.getChoiceLibrary()
    const chooseList = Object.values(choiceLibrary.value).flat()
    ctx.emit('modal-on-confirm', chooseList)
  }
  const onClose = () => {
    ctx.emit('modal-on-close')
  }
  return {
    onConfirm,
    onClose,
  }
}


export default {
  name: 'ChoiceLibrary',
  components: {
    Category,
  },
  props: {
    type: String,
  },
  emits: [
    'modal-on-confirm',
    'modal-on-close',
  ],
  setup (props, ctx) {
    const store = useStore()
    const choiceLibrary = globalQuestionnaire.getChoiceLibrary()
    const { selectedVal, firstCategory, subCategory, handleSelectedChange } = libraryEffect()
    const { onConfirm, onClose } = buttonEffect(ctx)
    const totalNum = computed({
      get: () => {
        let list = Object.values(choiceLibrary.value).flat()
        return list.length || 0
      }
    })
    onUnmounted(() => {
      choiceLibrary.value = {} // 清空全局已选列表
      store.commit('changeCancelSpinning', false)
    })
    return {
      selectedVal,
      firstCategory,
      subCategory,
      handleSelectedChange,
      choiceLibrary,
      totalNum,
      onConfirm,
      onClose,
    }
  }
}
</script>

<style lang="less">
.choice-library-modal {
  .modal-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
  }
  .modal-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: auto;
    outline: 0;
    .modal {
      box-sizing: border-box;
      width: 900px;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.85);
      position: relative;
      left: 50%; 
      top: 50%;
      transform: translate(-50%, -50%); /* 50%为自身尺寸的一半 */
      -webkit-transform: translate(-50%, -50%);
      pointer-events: none;
      .modal-content {
        position: relative;
        border-radius: 6px;
        background-color: #fff;
        padding: 30px 20px;
        pointer-events: auto;
        .modal-header {
          position: relative;
          margin: 16px 24px;
          padding-bottom: 28px;
          color: rgba(0, 0, 0, 0.85);
          background: #fff;
          border-bottom: 1px solid #F1F1F1;
          box-sizing: border-box;
          .modal-header-title {
            width: auto;
            font-size: 20px;
            color: #323336;
            font-weight: 500;
            word-wrap: break-word;
            display: inline-block;
          }
          .close {
            position: absolute;
            top: 0px;
            right: 0px;
            font-size: 20px;
            color: #1BABEC;
            .pointer()
          }
        }
        .modal-category {
          font-size: 16px;
          padding: 16px 24px;
          .category-title {
            
          }
          .category-select {
            width: 242px;
            // height: 42px;
            margin-left: 16px;
          }
        }
        .modal-body {
          padding: 20px 24px 40px 24px;
          min-height: 500px;
          max-height: 600px;
          overflow: auto;
          .modal-body-row {
            margin-bottom: 24px;
            .row-title {
              padding-left: 4px;
              margin-bottom: 20px;
              font-size: 16px;
              font-weight: normal;
              color: #2D2D33
            }
            .question-wrap {
              color: #2D2D33;
              margin-bottom: 8px;
              .question-top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 12px 18px;
                font-size: 16px;
                font-weight: lighter;
                color: #2D2D33;
                background-color: #F5F5F5;
                .left {

                }
                .right {
                  display: flex;
                  align-items: center;
                  .choice-library {
                    margin-right: 60px;
                    &:hover {
                      .choice-library-icon {
                        cursor: pointer;
                        color: #00A0E9
                      }
                      .choice-library-text {
                        cursor: pointer;
                        color: #00A0E9;
                        text-decoration-color: #00A0E9;
                      }
                    }
                    .choice-library-icon {
                      color: #2D2D334A;
                    }
                    .choice-library-text {
                      text-decoration: underline;
                      text-decoration-color: #2d2d334a;
                    }
                    .active {
                      color: #00A0E9
                    }
                  }
                  .ant-checkbox-wrapper {
                    font-weight: lighter;
                    .ant-checkbox {
                      background-color: red;
                      // .ant-checkbox-inner {
                      //   width: 24px;
                      //   height: 24px;
                      //   &::after {
                      //     width: 8px;
                      //     height: 16px;
                      //   }
                      // }
                    }
                    span {
                      font-weight: lighter;
                    }
                  }
                  
                }
              }
              .question-bottom {
                margin-top: 6px;
                border: 1px solid #E1E1E8;
                padding: 12px 18px;
                .choice-desc {
                  font-size: 16px;
                  font-weight: lighter;
                  padding-bottom: 12px;
                  border-bottom: 1px solid #E1E1E8;
                }
                .choices-wrap {
                  padding: 12px 0;
                  display: flex;
                  flex-wrap: wrap;
                  .ant-checkbox-wrapper {
                    font-weight: lighter;
                    margin-left: 0;
                    margin-bottom: 8px;
                    flex: 0 0 33.33%;
                    display: flex;
                    .ant-checkbox {
                      flex: 0 0 auto;
                    }
                    .ant-checkbox-checked {
                      height: auto;
                      &::after {
                        height: auto;
                      }
                    }
                  }
                }
                .choice-all {
                  color: #00A0E9;
                  text-decoration: underline;
                  text-decoration-color: #00A0E9;
                  .pointer()
                }
              }
            }
          }
        }
        .modal-footer {
          display: flex;
          margin: 34px 0;
          justify-content: center;
          div {
            flex: none;
            align-self: center;
            padding: 6px 20px;
            font-weight: 400;
            font-size: 14px;
            text-align: center;
            color: #FFFFFF;
            background-color: #00A0E9;
            cursor: pointer;
          }
          .bottom-cancel, .bottom-pre {
            color: #c8c8c8;
            background-color: #fff;
            border: 1px solid #c8c8c8;
            margin-right: 40px;
          }
          .bottom-finish {
            padding: 10px 20px;
          }
        }
      }
    }
  } 
}
</style>>