<!--
 * @Description: 显示设置模态窗
 * @version: 
 * @Author: PSG
 * @Date: 2021-04-20 16:37:32
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-29 15:43:45
-->
<template>
  <div class="show-logic-modal">
    <div class="modal-mask"></div>
    <div class="modal-wrap">
      <div class="modal">
        <div class="modal-content"> 
          <div class="modal-header">
            <div class="modal-header-title">显示设置</div>
            <span class="iconfont close" @click="handleCloseClick">&#xe61c;</span>
          </div>
          <div class="modal-body">
            <a-tabs 
              v-model:activeKey="activeKey" 
              type="card"
            >
              <!-- 针对所有题型 -->
              <a-tab-pane key="1" tab="题目显示">
                <QuestionShowTab ref="questionShowTab_Ref"/>
              </a-tab-pane>
              <!-- 针对单选、多选 -->
              <a-tab-pane key="2" tab="选项显示" v-if="isVisibleChoiceShowTab">
                <ChoiceShowTab ref="choiceShowTab_Ref"/>
              </a-tab-pane>
              <!-- 针对矩阵单选、矩阵多选 -->
              <a-tab-pane key="3" tab="行标题引用" v-if="isVisibleRowTitleTab">
                <RowTitleTab ref="rowTitleTab_Ref"/>
              </a-tab-pane>
            </a-tabs>
          </div>
          <div class="modal-footer">
            <div class="bottom-finish" @click="handleFinishClick">完成编辑</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { provide, ref, createVNode } from 'vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { Modal } from 'ant-design-vue';

import useCommonConstructEffect from '@/effects/constructEffect'
import globalQuestionnaire from '@/class/useQuestionnaire'

import useStringifyExpEffect from '@/effects/stringifyExpEffect'
import QuestionShowTab from './tab/QuestionShowTab'
import ChoiceShowTab from './tab/ChoiceShowTab'
import RowTitleTab from './tab/RowTitleTab'
/**
 * 标签逻辑控制
 */
const tabControlEffect = (question) => {
  let isVisibleChoiceShowTab = false
  let isVisibleRowTitleTab = false
  // 选项显示（针对单选、多选）
  if (/^(SINGLE_CHOICE|MULTIPLE_CHOICE)$/.test(question.questionType)) {
    isVisibleChoiceShowTab = true
  }
  // 行标题引用（针对矩阵单选、矩阵多选、矩阵量表）
  if (/^(MATRIX_SINGLE_CHOICE|MATRIX_MULTIPLE_CHOICE|MATRIX_SCALE)$/.test(question.questionType)) {
    isVisibleRowTitleTab = true
  }
  return {
    isVisibleChoiceShowTab,
    isVisibleRowTitleTab,
  }
}

/**
 * 完成编辑
 */
const handleFinishEditEffect = (questionCopy, question, emit) => {
  const questionShowTab_Ref = ref(null)
  const choiceShowTab_Ref = ref(null)
  const rowTitleTab_Ref = ref(null)
  const questionnaire = globalQuestionnaire.getQuestionnaire()
  const { insertLogicTips } = useCommonConstructEffect()
  const { stringifyShowLogicExp } = useStringifyExpEffect()
  
  // 逻辑数据校验器（只需要校验题目显示、选项显示）
  const checkData = () => {
    let tips = ""
    let flag = true
    // 1、题目显示校验
    if (questionShowTab_Ref.value) {
      const questShowCondList = questionShowTab_Ref.value.list
      if (questShowCondList.length > 0) {
        for (let i = 0; i < questShowCondList.length; i++) {
          if (questShowCondList[i].rowChosenId && questShowCondList[i].rowChosenId.key === -1) {
            tips = `题目显示页签，第${i+1}个条件请选择行标题`
            flag = false
            break
          } else if (questShowCondList[i].chosenIds.length === 0) {
            tips = `题目显示页签，第${i+1}个条件请勾选选项`
            flag = false
            break
          }
        }
      }
    }
    // 2、选项显示校验
    if (choiceShowTab_Ref.value && choiceShowTab_Ref.value.choiceChecked) {
      const choicesData = choiceShowTab_Ref.value.list
      for (let i = 0; i < choicesData.length; i++) {
        if (choicesData[i].condQuestList.length > 0) {
          for (let j = 0; j < choicesData[i].condQuestList.length; j++) {
            if (choicesData[i].condQuestList[j].chosenIds.length === 0) {
              tips = `选项显示页签，第${i+1}个选项的第${j+1}条件请勾选选项`
              flag = false
              break
            }
          }
          if (!flag) break
        }
      }
    }
    // 3、校验不通过提示
    if (!flag) {
      Modal.confirm({
        title: '请检查',
        icon: createVNode(ExclamationCircleOutlined),
        content: tips,
        okText: '确认',
        cancelText: '取消',
      })
    }
    return flag
  }
  // 逻辑表达式生成器
  const stringifyExp = () => {
    // 1、题目显示表达式生成
    if (questionShowTab_Ref.value) {
      const condition = questionShowTab_Ref.value.condition
      const questShowCondList = questionShowTab_Ref.value.list
      if (questShowCondList.length === 0) {
        question.showLogic = null
      } else {
        question.showLogic = stringifyShowLogicExp(condition, questShowCondList)
      }
    }
    // 2、选项显示表达式生成
    if (choiceShowTab_Ref.value) {
      // 2.1、题目选项
      //    2.1.1、switch打开 循环赋值
      if (choiceShowTab_Ref.value.choiceChecked) {
        const choicesData = choiceShowTab_Ref.value.list
        choicesData.forEach(choiceData => {
          let choiceId = choiceData.choiceId
          let condition = choiceData.condition
          let choicShowCondList = choiceData.condQuestList
          question.choices.forEach(questionChoice => {
            if (questionChoice.choiceId === choiceId) {
              questionChoice.showLogic = choicShowCondList.length === 0 ? null : stringifyShowLogicExp(condition, choicShowCondList)
            }
          })
        })
      } else {
      //    2.1.2、switch关闭 循坏赋值null
        question.choices.forEach(questionChoice => {
          questionChoice.showLogic = null
        })
      }

      // 2.2、选项引用
      //    2.2.1 switch打开 ref赋值
      if (choiceShowTab_Ref.value.refChecked) {
        const ref = choiceShowTab_Ref.value.choiceRefVal.key
        question.ref = ref === -1 ? null : ref
      } else {
      //    2.2.2 switch关闭 ref赋值null
        question.ref = null
      }
    }
    // 3、行标题引用表达式生成
    if (rowTitleTab_Ref.value) {
      const rowRefVal = rowTitleTab_Ref.value.rowRefVal
      question.ref = rowRefVal.key === -1 ? null : rowRefVal.key
    }
  }
  // 完成编辑按钮点击事件
  const handleFinishClick = () => {
    if (checkData()) {
      stringifyExp()
      insertLogicTips(questionnaire)
      emit('closeShowLogicModal')
    }
  }
  return {
    questionShowTab_Ref,
    choiceShowTab_Ref,
    rowTitleTab_Ref,
    handleFinishClick,
  }
}

/**
 * 处理发布相关事件
 */
const handleEmitEffect = (emit) => {
  const handleCloseClick = () => {
    emit('closeShowLogicModal')
  }
  return {
    handleCloseClick,
  }
}
export default {
  name: 'ShowLogicModal',
  props: [
    'question'
  ],
  emits: [
    'closeShowLogicModal',
    'finishShowLogicEdit',
  ],
  components: {
    QuestionShowTab,
    ChoiceShowTab,
    RowTitleTab,
  },
  setup (props, { emit }) {
    const activeKey = ref('1')
    const questionCopy = JSON.parse(JSON.stringify(props.question))
    const { isVisibleChoiceShowTab, isVisibleRowTitleTab, } = tabControlEffect(questionCopy)
    const { handleCloseClick } = handleEmitEffect(emit)
    const { handleFinishClick, questionShowTab_Ref, choiceShowTab_Ref, rowTitleTab_Ref } = handleFinishEditEffect(questionCopy, props.question, emit)
    provide('question', questionCopy)
    
    return {
      activeKey,
      questionCopy,
      isVisibleChoiceShowTab,
      isVisibleRowTitleTab,
      handleCloseClick,
      handleFinishClick,
      questionShowTab_Ref,
      choiceShowTab_Ref,
      rowTitleTab_Ref,
    };
  }
}
</script>

<style lang="less" scoped>
.show-logic-modal {
  
}
.modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  .modal {
    box-sizing: border-box;
    width: 1046px;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.5715;
    list-style: none;
    position: relative;
    top: 150px;
    margin: 0 auto;
    padding-bottom: 24px;
    pointer-events: none;
    .modal-content {
      position: relative;
      border: 16px solid #fff;
      border-radius: 16px;
      background-color: #fff;
      pointer-events: auto;
      .modal-header {
        position: relative;
        padding: 16px 24px;
        color: rgba(0, 0, 0, 0.85);
        background: #fff;
        border-radius: 0.125rem 0.125rem 0 0;
        box-sizing: border-box;
        .modal-header-title {
          width: 90px;
          font-size: 20px;
          font-weight: bold;
          word-wrap: break-word;
          display: inline-block;
        }
        .close {
          position: absolute;
          top: 14px;
          right: 16px;
          font-size: 25px;
          color: #00A0E9;
          .pointer()
        }
      }
      .modal-body {

      }
      .modal-footer {
        margin: 34px 0;
        .bottom-finish {
          margin: 0 auto;
          width: 140px;
          height: 36px;
          font-weight: 400;
          font-size: 18px;
          text-align: center;
          line-height: 36px;
          color: #FFFFFF;
          border-radius: 4px;
          background-color: #00A0E9;
          cursor: pointer;
        }
      }
    }
  }
}
</style>>
<style lang="less">
.show-logic-modal {
  .ant-tabs-nav-scroll {
    display: flex;
    justify-content: center;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #00A0E9!important;
    font-weight: 500;
    border-top: 1px solid #f0f0f0 !important;
    border-left: 1px solid #f0f0f0 !important;
    border-right: 1px solid #f0f0f0 !important;
    border-bottom: none !important;
  }
  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #00A0E9;
  }
  .ant-tabs-ink-bar {
    background-color: #00A0E9;
  }
  .ant-tabs-nav .ant-tabs-tab {
    color: #636363;
    background-color: #fff !important;
    font-size: 16px;
  }
  .ant-tabs.ant-tabs-card {
    .ant-tabs-nav .ant-tabs-tab {
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 2px solid #f0f0f0;
    }
  }
}
</style>