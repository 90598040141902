<!--
 * @Description: 标题编辑器
 * @version: 
 * @Author: HWL
 * @Date: 2021-06-24 09:56:56
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-09 17:19:41
-->
<template>
  <div class="title-modal" v-if="visible">
    <div class="modal-mask"></div>
    <div class="modal-wrap">
      <div class="modal">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-header-title">{{modalTitle}}</div>
            <a-input
              v-model:value="title"
              placeholder="请输入标题"
            />
            <span 
              class="iconfont close"
              @click="handleClose"
            >
              &#xe61c;
            </span>
          </div>
          <div class="modal-header">
            <div class="modal-header-title">{{modalSubTitle}}</div>
            <div class="text" ref="editorRef"></div>
          </div>
          <div class="modal-footer">
            <div class="bottom-finish" @click="handleOk">完成编辑</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import E from 'wangeditor'
import { nextTick, onBeforeUnmount, watch } from '@vue/runtime-core'
import watchPropsEffect from './effect/watchPropsEffect'

export default {
  name: 'TitleModal',
  props: [
    'modalTitle', // 标题
    'modalSubTitle', // 副标题
    'visible',  // 控制显示隐藏
    'title', // 问卷标题
    'text', // 输入框内容
  ],
  setup(props, { emit }) {
    const { title, text } = watchPropsEffect(props)
    const editorRef = ref(null)
    let editor
    let txt

    const handleOk = () => {
      emit('finishEditText', {
        title: title.value,
        text: text.value,
        txt
      })
    }
    const handleClose = () => {
      emit('finishEditText', '')
    }

    const handleCloseClick = () => {
    }

    // 过滤br标签
    const filterChildren = (children) => {
      for (let index = 0; index < children.length; index++) {
        const child = children[index]
        if (typeof child === 'string') {
          if (child.indexOf('<br/>') > -1) {
            children[index] = {
              tag: 'br',
              attrs: [],
              children: []
            }
          }
        } else {
          filterChildren(child.children)
        }
      }
      filterEndBr(children)
    }

    // 过滤最后一个br标签
    const filterEndBr = (children) => {
      if (!children) return
      const length = children.length
      if (length > 0) {
        const lastChildren = children[length - 1]
        if (lastChildren.tag === 'br') {
          children.pop()
        }
      }
    }
    const emptyText = '[{"tag":"p","attrs":[{"name":"data-we-empty-p","value":""}],"children":[]}]'
    const updateText = (text) => {
      if (editor && text !== undefined && text && JSON.stringify(text) !== emptyText) {
        if (typeof text === 'string') {
          editor.txt.html(text)
        } else {
          editor.txt.setJSON(text)
        }
      }
    }

    watch(() => props.visible, (newValue, oldValue) => {
      if (newValue) {
        nextTick(() => {
          editor = new E(editorRef.value)
          // 菜单配置
          editor.config.menus = [
            'bold', // 粗体
            'fontSize', // 字号
            'fontName', // 字体
            'italic', // 斜体
            'foreColor', // 文字颜色
            'justify', // 对齐方式
          ]

          // 配置字体
          editor.config.fontNames = [
            '黑体',
            '仿宋',
            '楷体',
            '标楷体',
            '宋体',
            'Arial',
            'Tahoma',
            'Verdana',
            'Times New Roman',
            'Courier New',
          ]

          editor.config.showFullScreen = false

          editor.config.onchange = function (newHtml) {
            const json = editor.txt.getJSON()
            filterChildren(json)
            text.value = json
            txt = editor.txt.text()
          }

          editor.create()
          updateText(props.text)
        })
      }
    })

    onBeforeUnmount(() => {
      if (editor) {
        editor.destroy()
        editor = null
      }
    })

    return {
      editorRef,
      title,
      text,
      handleOk,
      handleClose,
      updateText,
      handleCloseClick
    }
  }
}
</script>
.<style lang="less" scoped>
.modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
  .modal {
    box-sizing: border-box;
    width: 1046px;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.5715;
    list-style: none;
    position: relative;
    top: 100px;
    margin: 0 auto;
    padding-bottom: 24px;
    pointer-events: none;
    .modal-content {
      position: relative;
      border: 16px solid #fff;
      border-radius: 16px;
      background-color: #fff;
      pointer-events: auto;
      .modal-header {
        position: relative;
        padding: 16px 24px;
        color: rgba(0, 0, 0, 0.85);
        background: #fff;
        border-radius: 0.125rem 0.125rem 0 0;
        box-sizing: border-box;
        input {
          height: 41px;
          margin-top: 10px;
        }
        .modal-header-title {
          width: 90px;
          font-size: 20px;
          font-weight: bold;
          word-wrap: break-word;
          display: inline-block;
        }
        .modal-header-desc {
          width: 120px;
          font-size: 16;
          word-wrap: break-word;
          color: #00A0E9;
        }
        .close {
          position: absolute;
          top: 14px;
          right: 24px;
          font-size: 25px;
          color: #00A0E9;
          .pointer()
        }
      }
      .modal-body {
        .modal-body-condition {
          .condition-top {
            margin-bottom: 20px;
            .circle {
              display: inline-block;
              margin-left: 10px;
              margin-right: 6px;
              font-size: 18px;
              color: #000;
            }
            .text {
              display: inline-block;
              font-size: 16px;
              color: #2D2D33;
            }
            .switch {
              margin-left: 24px;
              margin-right: 7px;
            }
          }
          .condition-body {
            box-sizing: border-box;
            padding: 0 0 30px 40px;
            .rich-text {
              display: flex;
              margin-bottom: 20px;
              font-weight: bold;
            }
            .form-title {
              color: #888888;
            }
            .form-body {
              max-height: 400px;
              overflow: auto;
              .form-row {
                margin: 20px 0;
              }
            }
            .center {
              text-align: center;
              .ellipsis()
            }
          }
        }
        .modal-body-noconditio {
          margin-bottom: 20px;
          .condition-top {
            margin-bottom: 20px;
            .circle {
              display: inline-block;
              margin-left: 10px;
              margin-right: 6px;
              font-size: 18px;
              color: #000;
            }
            .text {
              display: inline-block;
              font-size: 16px;
              color: #2D2D33;
            }
            .switch {
              margin-left: 24px;
              margin-right: 7px;
            }
          }
          .condition-body {
            text-align: center;
          }
        }
      }
      .modal-footer {
        margin: 34px 0;
        .bottom-finish {
          margin: 0 auto;
           width: 140px;
          height: 36px;
          font-weight: 400;
          font-size: 18px;
          text-align: center;
          line-height: 36px;
          color: #FFFFFF;
          border-radius: 4px;
          background-color: #00A0E9;
          cursor: pointer;
        }
      }
    }
  }
}
</style>