/*
 * @Description: 富文本标题
 * @version: 
 * @Author: HWL
 * @Date: 2021-06-04 14:16:15
 * @LastEditors: HWL
 * @LastEditTime: 2021-07-13 16:38:38
 */

import { h } from "@vue/runtime-core";

function richTitle(vm) {
  if (!vm.title) {
    const children = ['标题']
    if (vm.$slots.default) {
      children.push(vm.$slots.default()[0])
    }
    return h('p', {}, children)
  }
  // String 直接返回
  if (typeof vm.title === 'string') {
    const children = [vm.title]
    if (vm.$slots.default) {
      children.push(vm.$slots.default()[0])
    }
    return h('p', {}, children)
  } else {
    const children = buildChidren(vm.title)
    if (vm.$slots.default) {
      children.push(vm.$slots.default()[0])
    }
    return h('div', {}, children)
  }
}

function buildChidren(childrens) {
  const renderList = []
  for (const children of childrens) {
    if (typeof children === 'string') {
      renderList.push(children)
    } else if (children.tag.indexOf('h') > -1
      || children.tag === 'p'
      || children.tag === 'div'
      || children.tag === 'b'
      || children.tag === 'font'
      || children.tag === 'i'
      || children.tag === 'span'
      || children.tag === 'br') {
      if (children.tag === 'i') {
        children.attrs.push({
          name: 'style',
          value: 'font-style: italic;'
        })
      }
      renderList.push(h(children.tag, buildAttrs(children.attrs), buildChidren(children.children)))
    }
  }

  return renderList
}

function buildAttrs(attrs) {
  const attrList = {}
  for (const attr of attrs) {
    // 过滤事件监听
    if (attr.name.indexOf('on') === -1) {
      attrList[attr.name] = attr.value
    }
  }

  return attrList
}

export default {
  props: [
    'title'
  ],
  render() {
    return richTitle(this)
  }
}
